// Widgets.js
import { combineReducers } from 'redux';
// Helpers
import { createModule, uploadFiles } from '../lib/reducer-helpers';
// Modules
import uploadReducer, { getUploadActions } from './upload';
import dataReducer from './data';
// Services
import * as propertiesService from 'services/properties';
import * as auditsService from 'services/audits';

// Selectors
export const documentsSelector = (state) =>
  state.modalProperty.rootInfo.documents.data;
export const documentsIsFetchingSelector = (state) =>
  state.modalProperty.rootInfo.documents.meta.isFetching;

// Actions
const createAction = createModule('propertyInfo');

const REQUEST_INFOS = createAction('REQUEST_INFOS');
const ADD_INFOS = createAction('ADD_INFOS');
const SUCCESS_INFOS = createAction('SUCCESS_INFOS');
const REQUEST_IMAGES = createAction('REQUEST_IMAGES');
const RECEIVE_IMAGES = createAction('RECEIVE_IMAGES');
const RESET = createAction('RESET');

const initialState = {
  infos: {
    data: {
      titleFormated: '',
      addressFormated: '',
      rooms: {},
      characteristics: [],
      condominium: null,
      user: {},
      informations: {},
      areas: {},
      country: {},
      state: {},
      city: {},
      real_estate: {},
      type: {},
      neighborhood: {},
      subtype: {},
      images: [],
      establishments: [],
    },
    meta: {
      isFetching: false,
    },
  },
  images: {
    data: [],
    meta: {
      isFetching: false,
    },
  },
};

export function reducerInfo(state = initialState.infos, action) {
  switch (action.type) {
    case REQUEST_INFOS:
      return { ...state, meta: { isFetching: true } };
    case ADD_INFOS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case SUCCESS_INFOS:
      return {
        ...state,
        meta: { isFetching: false },
      };
    case RESET:
      return initialState.infos;
    default:
      return state;
  }
}

export function reducerImages(state = initialState.images, action) {
  switch (action.type) {
    case REQUEST_IMAGES:
      return { ...state, meta: { isFetching: true } };
    case RECEIVE_IMAGES:
      return {
        ...state,
        data: action.payload,
        meta: { isFetching: false },
      };
    case RESET:
      return initialState.images;
    default:
      return state;
  }
}

export const { reducer: documentsReducer, actionCreators: documentActions } =
  dataReducer('propertyDocuments');

export const {
  reducer: propertyAuditsReducer,
  selectors: propertyAuditsSelector,
  actionCreators: propertyAuditsActions,
} = dataReducer('modalProperty/rootInfo/propertyAudits');

// root reducer
export default combineReducers({
  infos: reducerInfo,
  images: reducerImages,
  propertyDocuments: uploadReducer('propertyDocuments'),
  documents: documentsReducer,
  propertyAudits: propertyAuditsReducer,
});

// Action Creators
export const uploadActions = getUploadActions('propertyDocuments');

export const requestPropertyInfo = () => ({ type: REQUEST_INFOS });
export const addInfos = (data) => {
  let payload = { ...data };
  if (data.condominium) {
    payload = {
      ...data,
      condos_characteristics: data.condominium.condos_characteristics,
    };
  }

  return { type: ADD_INFOS, payload };
};
export const successInfos = () => ({ type: SUCCESS_INFOS });
export const requestPropertyImages = () => ({ type: REQUEST_IMAGES });
export const receivePropertyImages = (payload) => ({
  type: RECEIVE_IMAGES,
  payload,
});
export const resetProperty = () => ({ type: RESET });

// side effects, only as applicable
// e.g. thunks, epics, etc
export const getDocuments = (propertyId, params) => (dispatch) => {
  dispatch(documentActions.request());

  return propertiesService
    .getDocuments(propertyId, {
      ...params,
      order_col: 'created_at',
    })
    .then(({ data }) => {
      dispatch(documentActions.receive(data));
      return data;
    });
};

const handleGetPropertySuccess =
  (dispatch) =>
  ({ data }) => {
    // recebe a imagem dos imóveis
    dispatch(receivePropertyImages(data.images));

    // adiciona dados do imovel
    dispatch(addInfos(data));

    // Marca que deu certo a busca das informações do imóvel
    dispatch(successInfos());
  };

export const getPropertyInfo =
  ({ id, reference, network_property_id }) =>
  (dispatch) => {
    dispatch(resetProperty());
    dispatch(requestPropertyInfo());

    const PARAMS = {
      include:
        'rate,stages,is_under_negotiation,user,condominium,condo_characteristics,characteristics,establishments,people,cover_image,permissions,real_estate.primary_domain,situation,agent,solar_position,exchange_type',
      count:
        'matcheds_sent,matcheds_new,documents,deals_without_pipeline,notes,notes_auth,bookings_active',
    };

    // if (network_property_id) {
    //   return oruloService
    //     .getOne(reference, PARAMS)
    //     .then(handleGetPropertySuccess(dispatch));
    // }

    return propertiesService
      .getOne(id, PARAMS)
      .then(handleGetPropertySuccess(dispatch));
  };

export const uploadProperty =
  ({ documents, property_id }) =>
  (dispatch) => {
    return uploadFiles({
      request: propertiesService.uploadDocument,
      params: (file) => ({
        property_id,
        file,
        name: file.name,
        is_public: false,
      }),
      files: documents,
      onUploadStart: ({ total }) =>
        dispatch(uploadActions.setTotalFiles(total)),
      onUploadSuccess: (document) => {
        dispatch(uploadActions.uploadSuccess());
      },
      onUploadFail: () => dispatch(uploadActions.uploadFailed()),
      onUploadEnd: () => {
        dispatch(uploadActions.resetUpload());
        dispatch(getDocuments(property_id));
      },
    });
  };

export const updateDocument = (document, propertyId) => async (dispatch) => {
  const res = await propertiesService.updateDocument({
    ...document,
    property_id: propertyId,
  });

  dispatch(documentActions.alter(res.data));
  return res;
};

/**
 * Inverte a visibilidade do documento
 * @param document
 * @param propertyId
 * @return {Function}
 */
export const toggleDocumentVisibility =
  (document, propertyId) => (dispatch) => {
    dispatch(documentActions.removing(document.id));

    return propertiesService
      .updateDocument({
        ...document,
        is_public: !document.is_public,
        property_id: propertyId,
      })
      .then((res) => {
        dispatch(documentActions.alter(res.data));
        return res;
      });
  };

export const handleDeleteDocument = (document) => (dispatch) =>
  propertiesService.removeDocument(document).then(() => {
    dispatch(documentActions.remove(document.id));
  });

export const fetchPropertyAudits = (property, params) => (dispatch) => {
  dispatch(propertyAuditsActions.request());

  return auditsService
    .getListPropertyAudits(property, params)
    .then(({ data }) => {
      dispatch(propertyAuditsActions.receive(data));
      return data;
    });
};

//export const handleTogglePrivate = document => dispatch => {
//  return propertiesService.alterDocument({ documen })
//}
