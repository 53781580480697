// import * as S from './styles'

import { Field } from 'redux-form';
import { Select } from 'components/Form';

export const STATUS_KEY = {
  Single: 'Solteiro(a)',
  Married: 'Casado(a)',
  Divorced: 'Divorciado(a)',
  Widowed: 'Viúvo(a)',
  Separated: 'Separado(a)',
};

const OPTIONS = Object.entries(STATUS_KEY).map(([value, label]) => ({
  label,
  value,
}));

export const getStatus = (value) => {
  try {
    return STATUS_KEY[value];
  } catch {
    return '--';
  }
};

export function FieldCivilStatus(props) {
  return (
    <Field
      label="Estado civil"
      name="civil_status"
      options={OPTIONS}
      component={Select}
      {...props}
    />
  );
}
