import { useForm } from 'containers/ModalRoot/ModalPayment/contexts/form';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { Form } from 'features/Payments';

const Pix = () => {
  const { values, plan, prices } = useModalPayment();
  const { setPage } = useForm();

  return (
    <Form.Pix
      hasConsultantArea={true}
      values={values}
      prices={prices}
      plan={plan}
      onBack={() => setPage('plan')}
      onFinishPayment={() => {
        setPage('pix');
      }}
    />
  );
};

export default Pix;
