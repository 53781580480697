import { TableData } from 'containers/ModalRoot/ModalPayment/styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import { useMemo } from 'react';

const Package = () => {
  const { prices } = useModalPayment();

  const text = useMemo(() => {
    if (prices.is_financial_required) {
      return 'CRM + Aplicativo + Plugin WhatsApp + Financeiro';
    }

    return 'CRM + Aplicativo + Plugin WhatsApp';
  }, [prices]);

  return (
    <tr>
      <td className="item">
        <h3>Pacote Tecimob</h3>
        <p>Site completo com hospedagem</p>
        <p>{text}</p>
      </td>
      <td colSpan={2} />
      <TableData align="right">
        R${' '}
        {formatter.format(
          prices.is_financial_required
            ? prices.subscription_price + prices.financial_price
            : prices.subscription_price
        )}
      </TableData>
    </tr>
  );
};

export default Package;
