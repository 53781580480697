import React, { useCallback } from 'react';
import { Table, Title } from '../../styles';
import { useRemoveContract } from 'hooks/useRemoveContract';
import { changeDate, ITEM_TYPES } from 'services/financialv2/contracts';
import Alert from 'react-s-alert';

function TableServices({ contract, fetchData }) {
  const { handleOpenModal } = useRemoveContract({
    item: ITEM_TYPES.FINANCIAL,
  });

  const handleClickCancel = useCallback(() => {
    handleOpenModal({
      isCancel: true,
      onSuccess: async () => {
        await changeDate({ item: ITEM_TYPES.FINANCIAL, quantity: 0 });
        Alert.success('Financeiro cancelado');
        fetchData();
      },
    });
  }, []);

  return (
    <>
      <Title>Serviços contratados</Title>
      <Table>
        <thead>
          <tr>
            <th>Itens</th>
            <th>Qtd</th>
            <th className="Table__cell--money">Preço</th>
            <th className="Table__cell--money">Total</th>
          </tr>
        </thead>
        <tbody>
          {contract?.items?.list?.map((item) => (
            <tr key={item.item}>
              <td>
                {item.item}{' '}
                {contract.is_current && item.item === 'Financeiro' ? (
                  <button onClick={handleClickCancel}>
                    (<span className="h-link">Cancelar</span>)
                  </button>
                ) : null}
              </td>
              <td>{item?.quantity?.toString()?.padStart('2', '0')}</td>
              <td className="Table__cell--money">{item.unity_price}</td>
              <td className="Table__cell--money">{item.total_price}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <tfoot>
          {contract?.values?.monthly_price && (
            <tr>
              <td colSpan={2} />
              <td width={130}>
                <strong>Total mensal</strong>
              </td>
              <td width={90} className="Table__cell--money h-text-nowrap">
                <strong>{contract?.values?.monthly_price}</strong>
              </td>
            </tr>
          )}
          {contract?.values?.total_price && (
            <tr>
              <td colSpan={2} />
              <td width={130}>
                <strong>Total {contract?.plan?.toLowerCase?.()}</strong>
              </td>
              <td width={90} className="Table__cell--money h-text-nowrap">
                <strong>{contract?.values?.total_price}</strong>
              </td>
            </tr>
          )}
          {!!contract?.values?.discount_price &&
            contract?.values?.discount_price !== 'R$ 0,00' && (
              <tr>
                <td colSpan={2} />
                <td width={130}>
                  Desconto de {contract?.values?.discount_percent}
                </td>
                <td width={90} className="Table__cell--money h-text-nowrap">
                  {contract?.values?.discount_price}
                </td>
              </tr>
            )}
          {contract?.type !== 'Modelo anterior' &&
            contract?.values?.final_price && (
              <tr>
                <td colSpan={2} />
                <td width={130}>
                  <strong>Total</strong>
                </td>
                <td width={90} className="Table__cell--money h-text-nowrap">
                  <strong>{contract?.values?.final_price}</strong>
                </td>
              </tr>
            )}
        </tfoot>
      </Table>

      {contract?.deployment?.with_deployment && (
        <>
          <Title>Serviços adicionais</Title>
          <Table>
            <thead>
              <tr>
                <th>Itens</th>
                <th>Qtd</th>
                <th className="Table__cell--money">Preço</th>
                <th className="Table__cell--money">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Implantação</td>
                <td></td>
                <td></td>
                <td className="Table__cell--money">
                  {contract.deployment.paid_deployment ? (
                    'R$ 499,90'
                  ) : (
                    <del>R$ 499,90</del>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export default TableServices;
