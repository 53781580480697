import * as S from './styles';
import { useMemo } from 'react';
import { Item } from './components/Item';
import { plural } from 'lib/text';

export function Profiles({ data, filter, onEdit, onDelete, onArchive }) {
  const title = useMemo(() => {
    if (filter === 'active') {
      return plural(
        data.length,
        'Perfil de busca ativo',
        'Perfis de busca ativos'
      );
    }

    if (filter === 'inactive') {
      return plural(
        data.length,
        'Perfil de busca arquivado',
        'Perfis de busca arquivados'
      );
    }

    return plural(data.length, 'perfil de busca', 'perfis de busca');
  }, [filter, data]);

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.List>
        {data.map((item) => (
          <Item
            data={item}
            onEdit={onEdit}
            onArchive={onArchive}
            onDelete={onDelete}
          />
        ))}
      </S.List>
    </S.Wrapper>
  );
}
