import React from 'react';
import { Row } from 'react-flexbox-grid';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import validate from './validate';
import FieldStages from 'containers/FieldStages';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function MoveDealsForm({
  funnelId,
  userId,
  handleSubmit,
  handleClose,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <FieldStages
          xs={12}
          funnelId={funnelId}
          userId={userId}
          label="Mover para a etapa:"
          name="to_stage_id"
        />
      </Row>
      <ModalFooter>
        <Button color="white" colorText="primary" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

MoveDealsForm.defaultProps = defaultProps;
MoveDealsForm.propTypes = propTypes;

export default reduxForm({
  form: 'MoveDealsForm',
  validate,
  enableReinitialize: true,
})(MoveDealsForm);
