import React from 'react';
import Container, { Header } from 'components/Container';
import Button from 'components/Button';
import { closeModal, openModalTestimonialsStore } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import * as testimonialsService from 'services/sites/testimonials';
import TableTestimonials from '../TableTestimonials';
import Nodata from 'components/Nodata';

function TestimonialsList({
  testimonials,
  fetchTestimonials,
  handleChangeStatus,
  handleChangeOrder,
}) {
  const dispatch = useDispatch();

  const openModal = (id = null) => {
    dispatch(
      openModalTestimonialsStore({
        id,
        onSubmitSuccess: () => {
          dispatch(closeModal());
          fetchTestimonials();
        },
      })
    );
  };

  const update =
    ({ id }) =>
    () => {
      openModal(id);
    };

  const handleRemove =
    ({ id }) =>
    () => {
      dispatch(
        openConfirmation({
          title: 'Remover depoimento?',
          text: `Você irá remover o depoimento `,
          cancelButtonText: 'Cancelar',
          submitButtonText: 'Remover depoimento',
          request: () => testimonialsService.remove({ id }),
          onSuccess: () => fetchTestimonials(),
        })
      );
    };

  if (testimonials?.length <= 0) {
    return (
      <Container padding style={{ marginTop: '25px' }}>
        <Nodata
          image={null}
          title="Nenhum depoimento"
          text="Adicione o primeiro depoimento abaixo"
        >
          <Button onClick={() => openModal()} className="h-margin-top--15">
            Cadastrar depoimento
          </Button>
        </Nodata>
      </Container>
    );
  }

  return (
    <Container padding style={{ marginTop: '25px' }}>
      <Header>
        <h4>Depoimentos</h4>
        <Button onClick={() => openModal()}>Cadastrar depoimento</Button>
      </Header>
      <TableTestimonials
        data={testimonials}
        handleChangeStatus={handleChangeStatus}
        handleRemove={handleRemove}
        update={update}
        handleChangeOrder={handleChangeOrder}
      />
    </Container>
  );
}

export default TestimonialsList;
