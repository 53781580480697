import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';
// Services
import * as locationService from 'services/location';
import { useRequest } from 'hooks/useRequest';

export default function ModalNeighborhoodDelete({
  id,
  onSubmitSuccess,

  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  const { data: neighborhoods } = useRequest({
    request: () =>
      locationService.getNeighborhoods({
        include: 'city',
        count: 'properties,people,search_profiles,offices',
        with_defaults: false,
      }),
  });

  const neighborhood = useMemo(() => {
    return neighborhoods.find((neighborhood) => {
      return neighborhood.id === id;
    });
  }, [neighborhoods]);

  const options = useMemo(() => {
    return neighborhoods.filter((neighborhood) => {
      return neighborhood.id !== id;
    });
  }, [neighborhoods]);

  const handleSubmit = useCallback(
    ({ recipient_id }) => {
      return locationService.transferAndRemoveNeighborhood(id, recipient_id);
    },
    [id]
  );

  const handleSubmitSuccess = useCallback((...rest) => {
    if (onSubmitSuccess) onSubmitSuccess(rest);
    handleClose();
  }, []);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Excluir bairro" handleClose={handleClose}>
        <Form
          neighborhood={neighborhood}
          options={options}
          id={id}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}
