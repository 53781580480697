import {
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from 'components/Modal';
import Modal from 'react-modal';
import ReactConfetti from 'react-confetti';
import { Wrapper } from './styles';
import { PAYMENT_METHODS } from 'constants/constants';
import { useWindowSize } from './hooks/useWindowSize';
import Button from 'components/Button';
import { ITEM_TYPES } from 'services/financialv2/contracts';
import {
  openModalAddEmailAccount,
  openModalAddUserOption,
  openModalDwv,
  openModalOrulo,
} from 'modules/modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMemo } from 'react';
import { Screens } from './screens';

const ModalPaymentSuccess = ({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  receivingMethod,
  item,
  onSuccess,
  installments,
  contractResponse,
  bankSlipUrl,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();

  const method = receivingMethod.toString();

  const screen = useMemo(() => {
    const props = {
      receivingMethod: method,
      installments,
      contractResponse,
      bankSlipUrl,
    };

    switch (method) {
      case PAYMENT_METHODS.PIX:
        return <Screens.Pix {...props} />;
      case PAYMENT_METHODS.BOLETO:
        return <Screens.Boleto {...props} />;
      default:
        return <Screens.CreditCard {...props} />;
    }
  }, [receivingMethod]);

  function handleClickClose() {
    if (onSuccess) return onSuccess({ handleClose });

    switch (item) {
      case ITEM_TYPES.USERS: {
        dispatch(openModalAddUserOption());
        break;
      }

      case ITEM_TYPES.EMAIL: {
        dispatch(
          openModalAddEmailAccount({
            onSubmitSuccess: () => {
              history.go(0);
            },
          })
        );
        break;
      }

      case ITEM_TYPES.DWV: {
        dispatch(
          openModalDwv({
            onSubmitSuccess: () => {
              history.go(0);
            },
            onSubmitFail: () => {
              history.go(0);
            },
          })
        );
        break;
      }

      case ITEM_TYPES.ORULO: {
        dispatch(
          openModalOrulo({
            onSubmitSuccess: () => {
              history.go(0);
            },
            onSubmitFail: () => {
              history.go(0);
            },
          })
        );
        break;
      }

      default:
        window.location = '/dashboard';
        handleClose();
        break;
    }
  }

  return (
    <>
      <ReactConfetti
        width={width}
        height={height}
        recycle={false}
        friction={1}
        numberOfPieces={800}
        style={{ zIndex: 9999 }}
      />

      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClickClose}
      >
        <div className="Modal__wrapper">
          <ModalClose handleClose={handleClickClose} />
          <Wrapper>
            <ModalTitle text="Sucesso">Pagamento</ModalTitle>

            <ModalContent>
              {screen}

              <ModalFooter>
                <div className="h-flex__cell--grow" />
                <Button
                  type="submit"
                  color="success"
                  onClick={handleClickClose}
                >
                  Concluir
                </Button>
              </ModalFooter>
            </ModalContent>
          </Wrapper>
        </div>
      </Modal>
    </>
  );
};

export default ModalPaymentSuccess;
