// import * as S from './styles'

import { Col, Row } from 'react-flexbox-grid';
import { FieldImage } from 'components/Form';
import Container from 'pages/Site/components/ContainerSite';
import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  color: inherit;
`;

export function Images() {
  return (
    <Container padding={false} style={{ marginBottom: 20 }}>
      <Row>
        <Col xs={6}>
          <FieldImage
            hasRemove
            label="Popup Desktop"
            text={
              <>
                <Text>Recomendado:</Text>
                <Text className="h-text-nowrap">
                  No máximo: 1200px de largura
                </Text>
                <Text>No máximo: 600px de altura</Text>
              </>
            }
            name="image"
            fileUrlField="file_url"
            className="h-margin-bottom--15"
          />
        </Col>
        <Col xs={6}>
          <FieldImage
            hasRemove
            label="Popup Mobile"
            name="image_mobile"
            fileUrlField="mobile_file_url"
            text={
              <>
                <Text>Recomendado:</Text>
                <Text className="h-text-nowrap">
                  No máximo: 600px de largura
                </Text>
              </>
            }
            className="h-margin-bottom--15"
          />
        </Col>
      </Row>
    </Container>
  );
}
