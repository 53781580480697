import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Col, Row } from 'react-flexbox-grid';
// Componentes Gerais
import Nodata from 'components/Nodata';
// Components específicos
import Card from 'containers/Card'; //
// Assets
import searchEmpty from 'assets/img/noData/search-empty.svg';

const PropertiesList = ({
  properties,
  hasFilter,
  filter,
  toggleSelectedProperty,
  handleDeleteProperty,
  onClickProperty,
  type = 'vertical',
  selectedKey = 'properties_search',
}) => {
  let styleLength = {};

  let xs, sm, md, lg;

  if (type === 'horizontal') {
    xs = 12;
    sm = 6;
    md = 6;
    lg = 6;
  } else {
    xs = 12;
    sm = 6;
    md = 6;
  }

  const className = classnames('propertyList__item', {
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
  });

  let noDataProps = {
    title: 'Nenhum imóvel encontrado.',
    text: "Clique em 'Refazer pesquisa' ao lado para iniciar uma nova pesquisa",
  };

  return (
    <Row
      className={`propertyList propertyList--${type}`}
      style={{ ...styleLength, position: 'relative' }}
    >
      {!isEmpty(properties) ? (
        properties.map((property) => (
          <Col key={`properties-${property.id}`} className={className}>
            <Card
              filter={filter}
              type={type}
              property={property}
              onClick={onClickProperty}
              handleOnDelete={handleDeleteProperty}
              toggleSelectedProperty={
                property.is_published ? toggleSelectedProperty : null
              }
              selectedKey={selectedKey}
            />
          </Col>
        ))
      ) : (
        <Nodata
          position="left"
          image={searchEmpty}
          style={{ maxWidth: '480px' }}
          {...noDataProps}
        />
      )}
    </Row>
  );
};

export default PropertiesList;
