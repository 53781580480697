import styled from 'styled-components';

export const Btn = styled.button`
  vertical-align: middle;
  margin-left: 5px;
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
