import { ModalContent, ModalFooter, ModalTitle } from 'components/Modal';
import * as S from './styles';
import { QRCodeSVG } from 'qrcode.react';
import { useRef, useState } from 'react';
import pix from './assets/pix.svg';
import Alert from 'react-s-alert';

import * as creditsService from 'services/financial/credits';
import { openModalPaymentSuccess } from 'modules/modal';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { formatter } from 'hooks/usePlan';
import Loading from 'components/Loading';
import * as openAiService from 'services/openai';

const PaymentPix = ({
  gatewayName,
  isLoading,
  pixCode,
  error,
  creditId,
  openAiId,
  netValue,
  onVerifyPayment,
  onVerifyPaymentSuccess,
  onBack,
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const dispatch = useDispatch();

  async function handleVerifyPaymentOpenAi() {
    setIsVerifying(true);

    const verification = await openAiService.getCredits(openAiId);

    if (verification.data.received_at) {
      dispatch(
        openModalPaymentSuccess({
          receivingMethod: 4,
          contractResponse: verification,
          onSuccess: onVerifyPaymentSuccess,
        })
      );

      if (onVerifyPayment) onVerifyPayment(verification);
    } else {
      setVerificationFailed(true);
      Alert.success('Pagamento não encontrado');
    }

    setIsVerifying(false);
  }

  async function handleVerifyPayment() {
    if (openAiId) return handleVerifyPaymentOpenAi();

    setIsVerifying(true);

    const verification = await creditsService.getOne(creditId);

    if (verification.data.received_at) {
      dispatch(
        openModalPaymentSuccess({
          receivingMethod: 4,
          contractResponse: verification,
          onSuccess: onVerifyPaymentSuccess,
        })
      );

      if (onVerifyPayment) onVerifyPayment(verification);
    } else {
      setVerificationFailed(true);
      Alert.success('Pagamento não encontrado');
    }

    setIsVerifying(false);
  }

  const inputRef = useRef(null);

  const handleCopy = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(pixCode);
      Alert.success('Código do pix copiado');
    }
  };

  if (isLoading)
    return (
      <>
        <ModalTitle text="Pagamento">
          Finalização de pagamento via PIX
        </ModalTitle>
        <ModalContent>
          <Loading isVisible={isLoading} isBlock />

          <ModalFooter>
            {onBack ? (
              <Button disabled color="white" colorText="primary">
                Voltar
              </Button>
            ) : null}
            <div className="h-flex__cell--grow" />
            <Button disabled type="submit" color="success">
              Verificar pagamento
            </Button>
          </ModalFooter>
        </ModalContent>
      </>
    );

  if (!pixCode && error)
    return (
      <>
        <ModalTitle text="Pagamento">
          Finalização de pagamento via PIX
        </ModalTitle>
        <ModalContent>
          <p>{error.alerts[0]}</p>
        </ModalContent>
      </>
    );

  return (
    <>
      <ModalTitle text="Pagamento">Finalização de pagamento via PIX</ModalTitle>

      <ModalContent>
        <S.Content>
          <S.Price>R$ {formatter.format(netValue)}</S.Price>

          <S.ImgPix src={pix} />

          <S.BoxQrcode>
            <QRCodeSVG size={140} value={pixCode} />
            <p>{gatewayName}</p>
          </S.BoxQrcode>

          <S.QrcodeHint>
            Escaneie o QR Code ou copie o código abaixo
          </S.QrcodeHint>

          <S.InputGroup>
            <S.Input ref={inputRef} value={pixCode} onChange={() => {}} />
            <S.Button onClick={handleCopy}>Copiar</S.Button>
          </S.InputGroup>
        </S.Content>

        <ModalFooter>
          {onBack ? (
            <Button color="white" colorText="primary" onClick={onBack}>
              Voltar
            </Button>
          ) : null}
          <div className="h-flex__cell--grow" />
          <Button
            disabled={isVerifying}
            type="submit"
            color="success"
            onClick={handleVerifyPayment}
          >
            Verificar pagamento
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default PaymentPix;
