import FormPix from './components/FormPix';
import { ConsultantForm } from './components/ConsultantForm';
import PaymentPix from './screens/PaymentPix';

export { usePixValues } from './hooks/usePixValues';

export const Screens = {
  PaymentPix,
};

export const Form = {
  Pix: FormPix,
  Consultant: ConsultantForm,
};

export { DEFAULT_TRANSACTION } from './constants';
