// import { Toggle } from "components/Form";
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import {
  TableData,
  ToggleableTd,
} from 'containers/ModalRoot/ModalPayment/styles';
import { formatter } from 'containers/ModalRoot/ModalPayment/utils/moneyFormatter';
import { Field } from 'redux-form';
import { Toggle } from 'components/Form';
// import { Field } from "redux-form";

const Financial = () => {
  const { prices, values } = useModalPayment();

  const price = `R$ ${formatter.format(prices.financial_price)}`;

  if (prices.is_financial_required) return false;

  return (
    <tr>
      <td className="item">
        <h3>Módulo Financeiro</h3>
        <p>Organização e planejamento financeiro</p>
      </td>
      {prices.is_financial_required ? (
        <>
          <td></td>
          <td></td>
          <TableData align="right">{price}</TableData>
        </>
      ) : (
        <>
          <ToggleableTd>
            <Field name="with_financial" component={Toggle} />
          </ToggleableTd>
          <TableData align="right">{price}</TableData>
          <TableData align="right">
            {values.with_financial ? price : 'R$ 0,00'}
          </TableData>
        </>
      )}
    </tr>
  );
};

export default Financial;
