import LastProperties from 'lib/LastProperties';
import LastSearches from 'lib/LastSearches';
import { createModule } from 'lib/reducer-helpers';
import { Actions as SelectedsActions } from 'modules/selecteds';
import { initialize } from 'redux-form';
import { createSelector } from 'reselect';
import * as propertiesService from 'services/properties';

// Selectors
export const propertySearchSelector = (state) => state.propertySearch;
export const networkSelector = (state) => state.propertySearch.network;
export const propertiesSelector = (state) => state.propertySearch.properties;
export const typeListSelector = (state) => state.propertySearch.type_list;
export const selectedPropertiesSelector = (state) =>
  state?.selecteds?.properties_search;
export const lastSearchesSelector = (state) =>
  state.propertySearch.last_searches;
export const lastPropertiesVisitedSelector = (state) =>
  state.propertySearch.last_properties_visited;

export const getPropertiesSelecteds = createSelector(
  [propertiesSelector, selectedPropertiesSelector],
  (properties, selecteds) => {
    if (!properties.data || !selecteds) return properties.data;

    return properties.data.map((property) => {
      if (selecteds.indexOf(property.id) !== -1) {
        return { ...property, isChecked: true };
      }
      return property;
    });
  }
);

export const currentSearchSelector = (state) =>
  state.propertySearch.current_search;

const createAction = createModule('propertySearch');

const SET_CURRENT_SEARCH = createAction('SET_CURRENT_SEARCH');
const SET_TYPE_LIST = createAction('SET_TYPE_LIST');
const CLEAR_CURRENT_SEARCH = createAction('CLEAR_CURRENT_SEARCH');
const ADD_LAST_SEARCH = createAction('ADD_LAST_SEARCH');
const ADD_LAST_PROPERTY_VISITED = createAction('ADD_LAST_PROPERTY_VISITED');

const REQUEST_PROPERTIES = createAction('REQUEST_PROPERTIES');
const RECEIVE_PROPERTIES = createAction('RECEIVE_PROPERTIES');
const REMOVE_PROPERTIES = createAction('REMOVE_PROPERTIES');
const CLEAR_PROPERTIES = createAction('CLEAR_PROPERTIES');
const SET_NETWORK = createAction('SET_NETWORK');
const RECEIVE_LAST_SEARCH = createAction('RECEIVE_LAST_SEARCH');
const RECEIVE_LAST_PROPERTY_VISITED = createAction(
  'RECEIVE_LAST_PROPERTY_VISITED'
);

const currentUser = localStorage.getItem('current-user');

const initialState = {
  network: 'self',
  properties: {
    data: [],
    meta: {
      isFetching: false,
      isNetwork: false,
    },
  },
  type_list: 'card',
  current_search: null,
  last_searches: [],
  last_properties_visited: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PROPERTIES: {
      return {
        ...state,
        network: initialState.network,
        properties: initialState.properties,
      };
    }
    case SET_NETWORK: {
      return {
        ...state,
        network: action.network,
      };
    }
    case REQUEST_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          meta: {
            ...state.meta,
            isFetching: true,
          },
        },
      };
    case RECEIVE_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          data: action.properties.map((property) => ({
            ...property,
            isLoading: false,
          })),
          meta: {
            ...state.properties.meta,
            ...action.meta,
            isFetching: false,
          },
        },
      };
    case REMOVE_PROPERTIES:
      return {
        ...state,
        properties: {
          ...state.properties,
          data: state.properties.data.filter(
            (property) => property.id !== action.id
          ),
        },
      };

    case SET_CURRENT_SEARCH: {
      return { ...state, current_search: action.data };
    }
    case CLEAR_CURRENT_SEARCH: {
      return { ...state, current_search: initialState.current_search };
    }

    case SET_TYPE_LIST: {
      localStorage.setItem('@tecimob/type-list', action.typeList);

      return { ...state, type_list: action.typeList };
    }

    case RECEIVE_LAST_SEARCH: {
      return { ...state, last_searches: action.data };
    }

    case RECEIVE_LAST_PROPERTY_VISITED: {
      return { ...state, last_properties_visited: action.data };
    }

    case ADD_LAST_SEARCH: {
      const currentUser = localStorage.getItem('current-user');
      const lastSearches = new LastSearches(state.last_searches, 10);
      lastSearches.add(action.data);

      localStorage.setItem(
        `@tecimob/${currentUser?.id}/last_searches`,
        lastSearches.data
      );

      return { ...state, last_searches: lastSearches.data };
    }

    case ADD_LAST_PROPERTY_VISITED: {
      const currentUser = localStorage.getItem('current-user');
      const lastProperties = new LastProperties(
        state.last_properties_visited,
        10
      );
      lastProperties.add(action.data);

      localStorage.setItem(
        `@tecimob/${currentUser?.id}/last_properties_visited`,
        lastProperties.data
      );

      return { ...state, last_properties_visited: lastProperties.data };
    }

    default:
      return state;
  }
}

// Action Creators
export const clearProperties = () => ({ type: CLEAR_PROPERTIES });
export const requestProperties = () => ({ type: REQUEST_PROPERTIES });

export const receiveProperties = (properties, meta) => ({
  type: RECEIVE_PROPERTIES,
  properties,
  meta,
});

export const addLastSearch = (data) => ({
  type: ADD_LAST_SEARCH,
  data,
});

export const setNetwork = (network) => ({
  type: SET_NETWORK,
  network,
});

export const addLastPropertyVisited = (data) => ({
  type: ADD_LAST_PROPERTY_VISITED,
  data,
});

export const setTypeList = (typeList) => ({
  type: SET_TYPE_LIST,
  typeList,
});

// side effects, only as applicable
// e.g. thunks, epics, etc
export function initializePropertySearch() {
  return (dispatch) => {
    const currentUser = localStorage.getItem('current-user');

    dispatch({
      type: RECEIVE_LAST_SEARCH,
      data:
        localStorage.getItem(`@tecimob/${currentUser.id}/last_searches`) || [],
    });

    dispatch({
      type: RECEIVE_LAST_PROPERTY_VISITED,
      data:
        localStorage.getItem(
          `@tecimob/${currentUser.id}/last_properties_visited`
        ) || [],
    });
  };
}

export function getProperties(params) {
  return async (dispatch) => {
    // Marca que está buscando os imóveis
    dispatch(requestProperties());

    try {
      const { data: properties, meta } =
        await propertiesService.searchProperties(params);

      // Recebe todas as propriedades
      dispatch(receiveProperties(properties, meta));

      return properties;
    } catch {
      dispatch(receiveProperties([]));
    }
  };
}

export const toggleSelected =
  (id, key = 'properties_search') =>
  (dispatch) => {
    dispatch(SelectedsActions.toggleSelected(key, id));
  };

export const clearSearch = (history) => (dispatch) => {
  history.replace({
    pathname: history.location.pathname,
    search: '',
  });

  dispatch(clearProperties());
  dispatch(initialize('AsideFilterSearch', {}));
};
