import { openModalPaymentSuccess } from 'modules/modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as bankSlipService from 'services/financial/bankSlip';
import * as creditCardsService from 'services/financial/creditCards';
import * as transactionService from 'services/financial/transactions';
import { SubmissionError } from 'redux-form';
import { PAYMENT_METHODS } from 'constants/constants';

export const useContract = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [contractResponse, setContractResponse] = useState(null);
  const [hasError, setHasError] = useState(false);

  const dispatch = useDispatch();

  const contractBankSlip = async (values) => {
    try {
      await bankSlipService.create(values);

      const res = await transactionService.contractWithBankSlip(values);

      const boleto_url = res?.data?.boleto_url;

      if (res?.data?.boleto_url) {
        window.open(boleto_url, '_blank');

        openModalPaymentSuccess({
          receivingMethod: PAYMENT_METHODS.BOLETO,
          bankSlipUrl: boleto_url,
          installments: res?.data?.installments,
          contractResponse: res,
        })(dispatch);
      }

      setIsSuccess(true);
      setContractResponse(res);
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      }
      setHasError(err);
      setContractResponse(null);
      console.log('Erro ao contratar plano com boleto');
    }
  };

  const contractWithCreditCard = async (values) => {
    try {
      if (!values.card) await creditCardsService.create(values);

      const res = await transactionService.contractWithCreditCard(values);

      if (!!res.data.alerts) {
        throw new Error('Erro ao pagar com cartão de crédito');
      }

      setIsSuccess(true);
      setContractResponse(res);
      return res;
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      }
      setContractResponse(null);
      console.log('Erro ao contratar plano com cartão de crédito');
      setHasError(err);
    }
  };

  return {
    contractResponse,
    hasError,
    setHasError,
    isSuccess,
    contractWithCreditCard,
    contractBankSlip,
  };
};

export default useContract;
