// import * as S from './styles'

import Dropdown, { Item, List } from 'components/Dropdown';
import { MdMoreVert } from 'react-icons/md';
import React from 'react';
import * as S from './styles';

export function CollumnActions({
  stage,
  onSortInteraction,
  onMoveAll,
  onLossAllDeals,
}) {
  return (
    <Dropdown>
      {({ close }) => (
        <>
          <S.Btn>
            <MdMoreVert />
          </S.Btn>
          <List>
            <Item>
              <button
                onClick={() => {
                  close();
                  if (onSortInteraction) onSortInteraction(stage);
                }}
              >
                Ordenar por data de interação
              </button>
            </Item>
            <Item>
              <button
                onClick={() => {
                  close();

                  if (onMoveAll) onMoveAll(stage);
                }}
              >
                Mover todos para
              </button>
            </Item>
            <Item>
              <button
                onClick={() => {
                  close();

                  if (onLossAllDeals) onLossAllDeals(stage);
                }}
              >
                Marcar todos como perdido
              </button>
            </Item>
          </List>
        </>
      )}
    </Dropdown>
  );
}
