import PropTypes from 'prop-types';
import qs from 'qs';
import { useState } from 'react';
// Components
// Services
import { searchPropertyFilter } from 'services/profileSearch';
import { MdSearch } from 'react-icons/md';
import { Btn } from './styles';

function Search({ data, cityId, children, onSuccess, ...props }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const personId = data?.people_id;
  const profileId = data?.id;

  const [isFetching, setIsFetching] = useState(false);

  const fetchProfile = async () => {
    setIsFetching(true);
    const response = await searchPropertyFilter({
      personId,
      profileId,
      cityId,
    });
    setIsFetching(false);
    return response;
  };

  const onClick = async () => {
    if (data?.addresses?.length > 1) {
      setIsModalOpen(true);

      return null;
    }

    const { data: _data } = await fetchProfile();

    const searchString = qs.stringify(
      { filter: _data, limit: 50, offset: 1, sort: '-calculated_price' },
      {
        addQueryPrefix: true,
      }
    );

    window.open(`/properties/search${searchString}`);

    if (onSuccess) onSuccess();
  };

  return (
    <Btn disabled={isFetching} onClick={onClick} {...props}>
      <MdSearch /> Buscar
    </Btn>
  );
}

Search.defaultProps = {
  profileId: null,
  personId: null,
  children: 'Buscar',
};

Search.propTypes = {
  profileId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Search;
