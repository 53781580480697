import api from 'services/index';

/**
 * Gera a descrição de um imóvel em específico usando ChatGPT
 * @param property_id
 * @param tone
 * @return {Promise<AxiosResponse<never>|{data: {role: string, content: string}}>}
 */
export const generateDescription = async ({
  property_id,
  tone = 'profissional',
}) => {
  /*if (process.env.NODE_ENV === 'development') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: {
            title: `Título ${tone}`,
            content: `Texto ${tone}`,
          },
        });
      }, 3000);
    });
  }*/

  return api.getOne(`properties/${property_id}/generate-description`, '', {
    tone,
  });
};

/**
 * Gerar pix
 */
export const generatePix = ({ plan }) => {
  return api.create('real-estates/credits-openai', {
    plan,
  });
};

/**
 * Retorna os créditos disponíveis do ChatGPT
 * @return {Promise<AxiosResponse<never>>}
 */
export const getCredits = async (id) => {
  return api.getOne('real-estates/credits-openai', id || '');
};

/**
 * Retorna o histórico das transações do OpenIA
 * @return {Promise<AxiosResponse<never>>}
 */
export const getHistory = () => {
  return api.getOne('real-estates/history-openai');
};
