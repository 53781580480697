import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';
import { useEffect, useMemo } from 'react';

import { useForm } from 'containers/ModalRoot/ModalPayment/contexts/form';
import { usePix } from 'hooks/usePix';
import { Screens } from 'features/Payments';
import { removeCurrency } from 'lib/money-helpers';

const PaymentPixComponent = ({
  values: {
    months,
    users,
    mailboxes_number,
    redirect_mails_number,
    is_dwv,
    is_orulo,
    type_plan,
    installments,
  },
}) => {
  const { plan, plansPreview, values } = useModalPayment();

  const { setPage } = useForm();

  const currentPlanPreview = useMemo(() => {
    try {
      return plansPreview[values.type_plan];
    } catch {
      return null;
    }
  }, [values, plansPreview]);

  const netValue = useMemo(() => {
    if (currentPlanPreview) {
      return currentPlanPreview.final_price;
    }

    return plan.netValue;
  }, [plan, currentPlanPreview]);

  const {
    code,
    contractResponse,
    error,
    isLoading,
    imageUrl,
    contract,
    creditId,
  } = usePix({
    type_plan,
    users,
    months,
    mailboxes_number,
    redirect_mails_number,
    is_dwv,
    is_orulo,
    installments,
    consultant_id: values.consultant_id,
    consultant_should_not_sent: values.consultant_should_not_sent,
    consultant_due_date: values.consultant_due_date,
  });

  useEffect(() => {
    contract();
  }, []);

  return (
    <Screens.PaymentPix
      isLoading={isLoading}
      error={error}
      pixCode={code}
      creditId={creditId}
      netValue={removeCurrency(contractResponse?.data?.price)}
      gatewayName={contractResponse?.data?.gateway_name}
    />
  );
};

export default PaymentPixComponent;
