import { openModalRecommendation } from 'modules/modal';
import { useDispatch } from 'react-redux';
import { Container } from './styles';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

function RecommendationTag() {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenModal = () => {
    dispatch(openModalRecommendation());
  };

  useEffect(() => {
    if (location.pathname === '/plan/history') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [location]);

  if (!isVisible) return false;

  return (
    <Container className="js-recomende" onClick={handleOpenModal}>
      Recomende e ganhe
    </Container>
  );
}

export default RecommendationTag;
