import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Services
import * as locationService from 'services/location';
// Components
import TableNeighborhoods from './components/TableNeighborhoods';
import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
// Modules
import {
  openModalAddNeighborhood,
  openModalNeighborhoodDelete,
  openModalNeighborhoodStore,
} from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import Button from 'components/Button';

export default function Neighborhoods() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const fetchNeighborhoods = useCallback(async () => {
    setIsFetching(true);
    const { data } = await locationService.getNeighborhoods({
      include: 'city',
      count: 'properties,people,search_profiles,offices',
      with_defaults: false,
    });
    setData(data);
    setIsFetching(false);
  }, []);

  const openModalDelete = useCallback(
    (id) => () => {
      dispatch(
        openModalNeighborhoodDelete({
          id,
          onSubmitSuccess: () => {
            fetchNeighborhoods();
          },
        })
      );
    },
    []
  );

  const handleDelete =
    ({
      id,
      properties_count,
      people_count,
      offices_count,
      search_profiles_count,
    }) =>
    async () => {
      const total =
        properties_count + people_count + offices_count + search_profiles_count;

      if (total > 0) {
        return openModalDelete(id)();
      }

      dispatch(
        openConfirmation({
          title: 'Remover bairro',
          text: 'Deseja realmente remover o bairro?',
          request: () => locationService.removeNeighborhood(id),
          onSuccess: () => {
            // Remove da lista
            const neighborhoods = data.filter(
              (characteristic) => characteristic.id !== id
            );

            setData(neighborhoods);
          },
        })
      );
    };

  const handleEdit =
    ({ id }) =>
    () => {
      openModalNeighborhoodStore({
        id,
        onSubmitSuccess: ({ data: currentNeighborhood }) => {
          const characteristics = data.map((characteristic) => {
            if (currentNeighborhood?.id === characteristic?.id) {
              return {
                ...characteristic,
                ...currentNeighborhood,
              };
            }
            return characteristic;
          });

          setData(characteristics);
        },
      })(dispatch);
    };

  const handleAddNeighborhood = () => {
    dispatch(
      openModalAddNeighborhood({
        initialValues: {},
        onAddNeighborhood: () => {
          fetchNeighborhoods();
        },
      })
    );
  };

  useEffect(() => {
    fetchNeighborhoods();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Bairros"
        text="Gerencie aqui todos os bairros que você cadastrou no sistema."
      >
        <Button color="secondary" onClick={handleAddNeighborhood}>
          Adicionar bairro
        </Button>
      </MainTitle>
      <TableNeighborhoods
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}
