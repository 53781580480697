// import * as S from './styles'
import { Reception } from './components/Reception';
import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import useFormValue from 'hooks/useFormValue';
import { Row } from 'react-flexbox-grid';
import { FieldBool, Input, InputDate, Select } from 'components/Form';
import Container from 'pages/Site/components/ContainerSite';
import ListOptions from 'components/ListOptions';
import { parseValueBool } from 'lib/formHelpers';
import FieldSelectUser from 'containers/FieldSelectUser';
import GroupControl, { GroupItem } from 'components/GroupControl';
import FixedBar from 'components/FixedBar';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { useRequest } from 'hooks/useRequest';
import * as popupService from 'services/sites/popups';
import styled from 'styled-components';
import { Images } from './components/Images';
import MainTitle from 'components/MainTitle';

const CustomLabel = styled.label`
  font-weight: 400;
  color: ${(p) => p.theme?.colors?.primary};
`;

function Popup({ handleSubmit, initialize, change, submitting }) {
  const { data, isFetching } = useRequest({
    request: popupService.getOne,
    initialState: {},
  });

  const [isActive, setIsActive] = useState(false);

  const trigger = useFormValue('trigger');
  const form_should_redirect = useFormValue('form_should_redirect');
  const is_form_shown = useFormValue('is_form_shown');
  const has_expiration = useFormValue('has_expiration');
  const isTimeout = trigger === 1 || trigger === '1';

  const handleClickDisable = async () => {
    try {
      await popupService.remove({ data });

      setIsActive(false);
    } catch {
      setIsActive(true);
    }
  };

  useEffect(() => {
    if (data.active) {
      setIsActive(true);
    }

    initialize(data);
  }, [data]);

  if (isFetching) return <Loading isVisible isFullScreen />;

  if (!isActive) {
    return (
      <Reception
        onClick={() => {
          setIsActive(true);
          initialize(data);
        }}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MainTitle
        title="Pop-up"
        text="Adicione uma pop-up em seu site para divulgar promoções, avisos, ou qualquer informação que desejar."
      >
        <Button onClick={handleClickDisable} color="danger">
          Desativar pop-up
        </Button>
      </MainTitle>
      <Images />
      <Container>
        <ListOptions>
          <ListOptions.item
            title="Gatilho para ativar pop-up"
            text="Escolha em que momento a pop-up deve ser ativada."
            renderOptions={() => (
              <>
                <Field
                  name="trigger"
                  options={[
                    { label: 'Depois de "X" segundos', value: 1 },
                    { label: 'Ao rolar a página', value: 2 },
                    { label: 'Ao sair do site', value: 3 },
                  ]}
                  placeholder=""
                  labelKey="label"
                  valueKey="value"
                  component={Select}
                />
                {isTimeout && (
                  <GroupControl
                    required={false}
                    style={{ width: '155px', marginLeft: '5px' }}
                  >
                    <GroupItem>
                      <Field type="number" name="timeout" component={Input} />
                    </GroupItem>
                    <GroupItem label="segundos" size="small" />
                  </GroupControl>
                )}
              </>
            )}
          />
          <ListOptions.item
            title="Pop-up com validade?"
            text="Sua pop-up será desativada automaticamente na data desejada."
            renderOptions={() => (
              <>
                <FieldBool label="&nbsp;" name="has_expiration" />
                <Field
                  disabled={!has_expiration}
                  label="Data de expiração da pop-up:"
                  name="expiry_date"
                  component={InputDate}
                  minDate={new Date()}
                />
              </>
            )}
          />
          <ListOptions.item title="Link da Imagem">
            <Row style={{ marginTop: '5px' }}>
              <Field
                xs={8}
                label={
                  <CustomLabel>
                    Diga para qual página redirecionar ao clicar na imagem.
                  </CustomLabel>
                }
                name="link"
                component={Input}
              />
              <Field
                xs={4}
                label="Abrir link na:"
                name="opens_link_on_page"
                component={Select}
                placeholder=""
                options={[
                  { label: 'Mesma página', value: true },
                  { label: 'Outra página', value: false },
                ]}
                parse={parseValueBool}
              />
            </Row>
          </ListOptions.item>
          <ListOptions.item
            title="Formulário de coleta de dados"
            text="Ativar um formulário que coleta telefone, e-mail e nome do cliente."
            renderOptions={() => (
              <>
                {is_form_shown && (
                  <FieldSelectUser
                    label={null}
                    name="contact_form_user_id"
                    style={{ width: 200 }}
                    clearable={false}
                    placeholder="Corretor responsável"
                    params={{
                      filter: {
                        realtor: true,
                      },
                      sort: 'by_name',
                    }}
                  />
                )}
                <FieldBool name="is_form_shown" />
              </>
            )}
          />
          {is_form_shown ? (
            <>
              <ListOptions.item title="Título da pop-up">
                <Field
                  className="h-margin-top--5"
                  name="title"
                  placeholder="Digite o título"
                  component={Input}
                />
              </ListOptions.item>
              <ListOptions.item title="Subtítulo da pop-up">
                <Field
                  className="h-margin-top--5"
                  name="subtitle"
                  placeholder="Digite o subtítulo"
                  component={Input}
                />
              </ListOptions.item>

              <ListOptions.item
                title="Redirecionar clientes após enviar dados?"
                text="Defina para qual página o cliente será direcionado ao clicar no botão 'Enviar' no formulário."
                renderOptions={() => <FieldBool name="form_should_redirect" />}
              >
                {form_should_redirect ? (
                  <Row style={{ marginTop: '5px' }}>
                    <Field
                      xs={8}
                      label="Link para redirecionamento"
                      name="form_link"
                      component={Input}
                    />
                    <Field
                      xs={4}
                      label="Abrir link na:"
                      name="form_opens_link_on_page"
                      component={Select}
                      placeholder=""
                      options={[
                        { label: 'Mesma página', value: true },
                        { label: 'Outra página', value: false },
                      ]}
                      parse={parseValueBool}
                    />
                  </Row>
                ) : null}
              </ListOptions.item>
            </>
          ) : null}
        </ListOptions>
      </Container>
      <FixedBar style={{ left: 240 }}>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success" disabled={submitting}>
          Salvar
        </Button>
      </FixedBar>
    </form>
  );
}

export default reduxForm({
  form: 'FormPopup',
  onSubmit: (values) => {
    return popupService.update(values);
  },
  onSubmitSuccess: (res, dispatch, props) => {
    props.initialize(res.data);
  },
})(Popup);
