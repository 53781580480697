import api from './index';
// Helpers
import { responseMessage } from 'lib/service-helpers';
// Services
import { filterProfiles, filterSituations } from 'services/properties';

export const PROFILE_SEARCH_RANGE_SELL = 1;
export const PROFILE_SEARCH_RANGE_RENT = 2;

const normalize = (values) => {
  let newValues = values;

  if (newValues.subtype_id) {
    newValues = { ...newValues, subtype_id: newValues.subtype_id.value };
  }

  return newValues;
};

/**
 * Normaliza a informação de um imóvel
 */
const normalizeProfile = (profile) => {
  if (!profile) return { id: '', name: '' };
  let newProfile = { ...profile };

  if (newProfile.situations) {
    newProfile = {
      ...newProfile,
      situations: filterSituations(newProfile.situations),
    };
  }

  if (newProfile.profiles) {
    newProfile = {
      ...newProfile,
      profiles: filterProfiles(newProfile.profiles),
    };
  }

  return newProfile;
};

/**
 * Normaliza uma lista de perfil
 * @param res
 */
const normalizeProfiles = (res) => ({
  ...res,
  data: res.data.map(normalizeProfile),
});

export const getList = (personId, params) =>
  api
    .getList(`people/${personId}/search-profiles`, {
      ...params,
      include: 'addresses',
    })
    .then(normalizeProfiles);
export const getOne = (personId, profileId, params) =>
  api.getOne(`people/${personId}/search-profiles`, profileId, params);
export const create = (personId, params) =>
  api
    .create(`people/${personId}/search-profiles`, normalize(params))
    .then(responseMessage('Perfil criado'))
    .catch(responseMessage('Falha ao criar perfil'))
    .catch((err) => {
      console.dir(err);
      throw err;
    });
export const update = (personId, data) =>
  api
    .update(`people/${personId}/search-profiles`, data)
    .then(responseMessage('Perfil Alterado'));

export const remove = (personId, id, params) =>
  api
    .delete(`people/${personId}/search-profiles`, id, { params })
    .then(responseMessage('Perfil removido'));

export const fetchPropertiesByCount = async ({ name, filter }) => {
  let params = {};
  params.with_grouped_condos = true;

  if (filter.condominium_id) {
    delete params.with_grouped_condos;
  }

  if (filter.transaction === 1) {
    delete filter.price.with_rental_taxes;
  }

  const res = await api.getList(`properties/count-${name}`, {
    filter,
    ...params,
  });

  return {
    ...res.data,
    name,
  };
};

const transforToObject = (jsonArray) => {
  const transformedObject = {};

  for (const item of jsonArray) {
    if (item.name) {
      transformedObject[item.name] = {
        count: item?.count,
        isFetching: false,
      };
    }
  }

  return transformedObject;
};

export const getSearchCountByFilter = async (filter) => {
  // Pega informações do usuário que está logado no momento
  let currentUser = localStorage.getItem('current-user');

  // Verifica se pode buscar no portal do guru
  const canFetchGuru = currentUser.real_estate.with_guru;

  const canFetchNetwork =
    !!filter?.by_city_id &&
    !!filter.by_type_or_subtype_id &&
    !!filter.transaction &&
    !filter.condominium_id;

  // Cria um array pra adicionar todas as promisses que precisam ser feitas
  let arrayRequests = [];

  arrayRequests.push(
    fetchPropertiesByCount({
      name: 'self',
      filter,
    })
  );

  // Verifica se realmente pode buscar as informações dos portais
  if (canFetchNetwork) {
    // Busca e adiciona no array de requests o count do Guru
    if (canFetchGuru) {
      arrayRequests.push(
        fetchPropertiesByCount({
          name: 'guru',
          filter,
        })
      );
    }

    // Busca e adiciona no array de requests o count do orulo
    arrayRequests.push(
      fetchPropertiesByCount({
        name: 'orulo',
        filter,
      })
    );

    // Busca e adiciona no array de requests o count do orulo
    arrayRequests.push(
      fetchPropertiesByCount({
        name: 'dwv',
        filter,
      })
    );
  }

  const responses = await Promise.all(arrayRequests);

  return transforToObject(responses);
};

/**
 * Busca os dados para inicializar o filtro de pesquisa do imóvel
 * @param personId
 * @param profileId
 * @param cityId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const searchPropertyFilter = async ({ personId, profileId, cityId }) => {
  const response = await api.getList(
    `people/${personId}/search-profiles/${profileId}/property-filter`,
    { city_id: cityId }
  );

  // Pega o filtro de busca
  const filter = response.data;

  // Pega todos os count
  const count = await getSearchCountByFilter(filter);

  response.data = {
    ...filter,
    count,
  };

  return response;
};

export const archive = ({ person_id, profile_id, remove_compatible }) => {
  return api.patch(`people/${person_id}/search-profiles/${profile_id}/status`, {
    status: 2,
    remove_compatible,
  });
};

export const unarchive = ({ person_id, profile_id }) => {
  return api.patch(`people/${person_id}/search-profiles/${profile_id}/status`, {
    status: 1,
  });
};
