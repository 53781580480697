// Sevices
import * as crmService from 'services/settings/crm';
import Alert from 'react-s-alert';
// Modules
import dataReducer from 'modules/data';

export const {
  reducer: crmConfigReducer,
  selectors: crmConfigSelector,
  actionCreators: crmConfigActions,
} = dataReducer(`site/crmConfig`);

// Thunks
export const fetchData = (params) => (dispatch) => {
  dispatch(crmConfigActions.request());

  return crmService.getConfig().then(({ data }) => {
    dispatch(crmConfigActions.receive(data));
    return data;
  });
};

export const handleSubmit = (values) => async (dispatch) => {
  let _values = { ...values };
  dispatch(crmConfigActions.request());

  if (_values?.config?.should_disassociate_people_user) {
    _values.config.should_disassociate_people_user =
      _values?.config?.should_disassociate_people_user === 'true';
  }

  try {
    const res = await crmService.update(_values);

    dispatch(crmConfigActions.receive(res.data));
    Alert.success('Configurações Salvas');
    return res;
  } catch (err) {
    console.log('err: ', err);
    dispatch(crmConfigActions.error());
    throw err;
  }
};

export default crmConfigReducer;
