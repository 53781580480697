import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #d3dceb;
  border-radius: 4px;

  & + & {
    margin-top: 20px;
  }
`;

export const Inner = styled.div`
  display: flex;
`;

export const Text = styled.div`
  flex: 1 0;
  word-break: break-word;
  hyphens: auto;

  ul,
  ol {
    margin-left: 15px;
  }
`;

export const Content = styled.div`
  padding: 15px;
  flex: 1 0;
`;

export const Actions = styled.div`
  flex-shrink: 0;
  display: flex;
  padding: 15px;
`;

export const Action = styled.div`
  margin-left: 5px;
`;
export const FooterBar = styled.div``;

export const Ball = styled.div`
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  background: #e9eef5;
  border-radius: 50%;

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    vertical-align: middle;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserInfo = styled.p`
  font-size: 14px;
  color: #8d858e;
  margin-top: 10px;
`;
