import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @media print {
    .h-hide-print {
      display: none !important;
    }
  }

  .p-multiselect-panel {
    z-index: 9999999 !important;
  }

  .p-multiselect.p-disabled {
    background: #e0e6f1;
  }

  .Table__row--sort {
    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      padding-right: 15px;
    }
  }
`;
