import api from '../';
import axios from 'axios';
// Helpers
import { responseMessage } from 'lib/service-helpers';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';

const FB = window.FB;

const GraphFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v16.0/',
});

GraphFacebook.interceptors.response.use((res) => {
  let response = res;
  if (response.data) {
    response = { ...response, ...response.data };
  }
  return response;
});

/**
 * Retorna todas as permissões aceitas ou rejeitadas
 * @param access_token
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPermissions = (access_token) =>
  GraphFacebook.get('me/permissions', { params: { access_token } });

/**
 * Verifica os escopos das permissões
 * @param access_token
 * @param scopes
 * @returns {Promise<boolean>}
 */
const checkScopes = async (access_token, scopes) => {
  try {
    const { data: permissions } = await getPermissions(access_token);

    const permissionsArray = permissions.reduce((acc, item) => {
      if (item.status === 'granted') {
        return [...acc, item.permission];
      }

      return acc;
    }, []);

    for (let scope of scopes) {
      if (!permissionsArray.includes(scope)) return false;
    }

    return true;
  } catch (err) {
    return false;
  }
};

export const getLongAccessToken = (accessToken) => {
  return GraphFacebook.get(`oauth/access_token`, {
    params: {
      grant_type: 'fb_exchange_token',
      client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
      client_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
      fb_exchange_token: accessToken,
    },
  });
};

/**
 * Faz login com o facebook
 * @param scope
 * @returns {Promise<unknown>}
 */
export const login = async (scope) => {
  return new Promise((resolve, reject) => {
    const FB = window.FB;

    if (!FB) {
      Alert.error('Facebook SDK não carregado');
      reject('SDK não disponível');
      return;
    }

    FB.login(
      (response) => {
        if (!response?.authResponse?.accessToken) {
          Alert.error('Erro ao se conectar com o Facebook');
          reject('Erro');
          return;
        }

        getLongAccessToken(response?.authResponse?.accessToken).then((res) => {
          response.authResponse.accessToken = res.access_token;

          checkScopes(response.authResponse.accessToken, scope).then(
            (hasScopes) => {
              if (hasScopes) {
                resolve(response);
              } else {
                reject('Sem permissão');
              }
            }
          );
        });
      },
      {
        scope: scope.join(','),
      }
    );
  });
};

/**
 * Lista todos os negócios
 * @param user_id
 * @param access_token
 */
export const getBusiness = ({ user_id, access_token }) =>
  GraphFacebook.get(`${user_id}/businesses`, {
    params: { access_token },
  });

/**
 * Lista os catálogos
 * @param business_id
 * @param access_token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCatalogs = ({ business_id, page_access_token }) =>
  GraphFacebook.get(`${business_id}/owned_product_catalogs`, {
    params: { access_token: page_access_token },
  });

/**
 * Pega as páginas dos leads
 * @param access_token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPagesLeads = ({ access_token }) =>
  GraphFacebook.get('me/accounts', {
    params: {
      access_token,
    },
  }).catch((err) => {
    return err;
  });

export const getPagesLeadsWithForms = async ({ access_token }) => {
  try {
    const res = await getPagesLeads({ access_token });

    const pages = res?.data?.filter((page) => page.access_token);

    const newPages = pages?.map(async (page) => {
      const { data: forms } = await getForms({
        access_token: page.access_token,
        page_id: page.id,
      });

      return { ...page, forms };
    });

    const _pages = await Promise.all(newPages);

    res.data = _pages;

    return res;
  } catch (err) {
    console.dir(err);
    throw err;
  }
};

/**
 * Pega as páginas de um businessId
 * @param access_token
 * @param business_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPagesCatalog = ({ access_token, business_id }) =>
  GraphFacebook.get(`${business_id}/owned_pages`, {
    params: { access_token, fields: 'access_token,name' },
  });

export const getForms = ({ access_token, page_id }) =>
  GraphFacebook.get(`${page_id}/leadgen_forms`, {
    params: {
      access_token,
      limit: 1000,
      filtering: [{ field: 'status', operator: 'EQUAL', value: 'ACTIVE' }],
      subscribed_fields: 'leadgen,leadgen_fat',
    },
  });

/**
 * Cadastra e instala o webhook nas páginas selecionadas
 * @description
 * Envia o token para o sistema, onde será gerado um token de longa duração e armazenado no real_estate, depois, feita a checagem se todas as permissões necessárias foram concedidas, e armazenamento das páginas no banco de dados.
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const connect = ({ access_token }) =>
  api
    .create('settings/facebook', { access_token })
    .then(responseMessage('Conectado'));

/**
 * Desfaz o webhook e deleta as páginas
 * @return {Promise<AxiosResponse<any>>}
 */
export const disconnect = () =>
  api.delete('settings/facebook', '').then(responseMessage('Desconectado'));

/**
 * Pega os dados da integração com o facebook
 * @return {Promise<AxiosResponse<any>>}
 */
export const getIntegration = async () => {
  try {
    const response = await api.getList('settings/facebook');
    return response;
  } catch {
    return { data: {} };
  }
};

/**
 * Edita o usuário responsável pela integração
 * @param access_token
 * @param user_id
 * @param client_code
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = ({ access_token, user_id, client_code }) =>
  api.create('settings/facebook', { access_token, user_id, client_code });

export const connectLeads = ({
  long_lived_access_token,
  facebook_user_id,
  user_id,
  name,
  page_id,
  access_token,
  lead_pages,
}) =>
  api
    .create(`settings/facebook/leads`, {
      long_lived_access_token,
      facebook_user_id,
      user_id,
      name,
      page_id,
      access_token,
      lead_pages,
    })
    .then(responseMessage('Formulários (Leads) conectado'));

export const connectCatalog = ({
  page_id,
  long_lived_access_token,
  facebook_user_id,
  name,
  access_token,
  catalog_id,
  business_id,
}) => {
  if (!business_id) {
    throw new SubmissionError({ business_id: 'Campo obrigatório' });
  }

  if (!page_id) {
    throw new SubmissionError({ page_id: 'Campo obrigatório' });
  }

  if (!catalog_id) {
    throw new SubmissionError({ catalog_id: 'Campo obrigatório' });
  }

  try {
    return api
      .create(`settings/facebook/catalog`, {
        long_lived_access_token,
        facebook_user_id,
        name,
        access_token,
        catalog_id,
        business_id,
        page_id,
      })
      .then(responseMessage('Catálogo (Loja) conectado'));
  } catch (err) {
    throw err;
  }
};

export const disconnectLeads = () =>
  api
    .delete('settings/facebook/leads')
    .then(responseMessage('Formulários (Leads) desconectado'));

export const disconnectCatolog = () =>
  api
    .delete('settings/facebook/catalog')
    .then(responseMessage('Catálogo (Loja) desconectado'));

export const updateUserLeads = (userId) => {
  if (!userId) {
    throw new SubmissionError({ user_id: ['Campo obrigatório'] });
  }

  return api
    .patch(`settings/facebook/${userId}/leads`)
    .then(responseMessage('Corretor responsável alterado'));
};
