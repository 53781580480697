import { useDispatch } from 'react-redux';
import { compose } from 'redux';
// Components
import Button from 'components/Button';
import MainTitle from 'components/MainTitle';
import NoContent from 'components/NoContent';
import FormTestimonials from './components/FormTestimonials';
import TestimonialsList from './components/TestimonialsList';
// Services
import { hideLoading, showLoading } from 'modules/loading';
import * as testimonialsService from 'services/sites/testimonials';
// HOC
import withReception from 'HOC/withReception';
// Assets
import { useRequest } from 'hooks/useRequest';
import depoimentos from './depoimentos.png';
import { arrayMove } from 'react-sortable-hoc';

const Testimonials = ({ reception, handleClickAction }) => {
  const dispatch = useDispatch();
  const { data: testimonials } = useRequest({
    request: testimonialsService.getComponents,
  });

  const {
    data: formValues,
    fetchData,
    isFetching,
    setData,
  } = useRequest({
    request: testimonialsService.getOneConfig,
    initialState: {},
  });

  const handleChangeOrder = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(formValues.testimonials, oldIndex, newIndex);

    // pega a imagem para ser modificada
    const item = newItems.find(
      (image, index) => [newIndex].indexOf(index) >= 0
    );

    setData((data) => {
      return {
        ...data,
        testimonials: newItems,
      };
    });

    testimonialsService.update({ ...item, order: newIndex + 1 });
  };

  const handleChangeStatus = (testimonial) => async () => {
    dispatch(showLoading());

    await testimonialsService.update({
      ...testimonial,
      is_published: !testimonial.is_published,
    });

    setData((data) => {
      return {
        ...data,
        testimonials: data?.testimonials?.map((item) => {
          if (item.id === testimonial.id) {
            return { ...item, is_published: !testimonial.is_published };
          }

          return item;
        }),
      };
    });

    dispatch(hideLoading());
  };

  const handleSubmit = (values) => testimonialsService.update(values);

  if (reception.isVisible) {
    return (
      <NoContent
        full
        reversed
        image={depoimentos}
        title="Depoimentos"
        text={
          <>
            Para transmitir ainda mais confiança aos usuários, adicione
            depoimentos de seus clientes na página inicial do seu site.
          </>
        }
      >
        <Button color="secondary" size="large" onClick={handleClickAction}>
          Adicionar Depoimento
        </Button>
      </NoContent>
    );
  }

  return (
    <>
      <MainTitle
        title="Depoimentos"
        text="Adicione depoimentos dos seus clientes na página inicial do seu site."
      />
      <FormTestimonials
        isFetching={isFetching}
        fetchData={fetchData}
        testimonials={testimonials}
        formValues={formValues}
        onSubmit={handleSubmit}
      />
      <TestimonialsList
        handleChangeStatus={handleChangeStatus}
        testimonials={formValues.testimonials}
        fetchTestimonials={fetchData}
        handleChangeOrder={handleChangeOrder}
      />
    </>
  );
};

export default compose(withReception('site/testimonials', true))(Testimonials);
