// import * as S from './styles'

import { Filter } from 'components/Filter';
import Button from 'components/Button';
import { MdAdd } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

const FILTERS = [
  { label: 'Ativos', value: 'active' },
  { label: 'Arquivados', value: 'inactive' },
  { label: 'Todos', value: 'all' },
];

export function Filters({ personId, onChangeFilter, handleClose }) {
  const [value, setValue] = useState('active');

  useEffect(() => {
    if (value && onChangeFilter) {
      onChangeFilter(value);
    }
  }, [value]);

  const handleClick = (filter) => () => {
    setValue(filter.value);
  };

  return (
    <Filter.Wrapper>
      <Filter.Content>
        <Filter.Title>Filtros</Filter.Title>
        <Filter.Labels>
          {FILTERS.map((filter) => {
            const isActive = filter.value === value;

            return (
              <Filter.Label
                className={isActive ? 'is-enabled' : 'is-disabled'}
                key={`label-${filter.value}`}
                onClick={handleClick(filter)}
              >
                {filter.label}
              </Filter.Label>
            );
          })}
        </Filter.Labels>
      </Filter.Content>
      <Button
        to={`/profile/create?formData[people_id]=${personId}`}
        color="white"
        size="medium"
        onClick={() => {
          handleClose();
        }}
      >
        <MdAdd className="h-margin-right--5" />
        Novo perfil de busca
      </Button>
    </Filter.Wrapper>
  );
}
