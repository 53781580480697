import React from 'react';
import Modal from 'react-modal';
// Services
// Components
import { ModalTemplate } from 'components/Modal';
import MoveDealsForm from './components/MoveDealsForm';
import api from 'services';

function ModalMoveDeals({
  fromStageId,
  userId,
  funnelId,
  onSubmitSuccess,
  onSubmitFail,
  afterClose,
  modalConfig,
  isOpen,
  modalType,
  handleClose,
}) {
  function handleSubmit(values) {
    return api.create('crm/deals/move-stage', {
      from_stage_id: fromStageId,
      to_stage_id: values.to_stage_id,
      user_id: userId,
    });
  }

  function handleSubmitSuccess(res) {
    handleClose();

    if (onSubmitSuccess) onSubmitSuccess(res);
  }

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      className={{
        base: 'Modal Modal--small',
      }}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title="Mover negócios" handleClose={handleClose}>
        <MoveDealsForm
          funnelId={funnelId}
          userId={userId}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          // onSubmitFail={handleSubmitFail}
          handleClose={handleClose}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalMoveDeals;
