import setAuthorizationToken, {
  setOriginalToken,
} from 'lib/setAuthorizationToken';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { Container } from './styles';

const Loading = () => {
  const { token } = useParams();

  useEffect(() => {
    localStorage.removeItem('original-user');
    localStorage.removeItem('current-user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('original_token');
  }, []);

  useEffect(() => {
    if (token) {
      setOriginalToken(token);
      setAuthorizationToken(token);
      window.location.replace(window.location.origin + '/reception');
    }
  }, [token]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
      <div class="loading-home-screen">
      </div>
    `,
      }}
    />
  );
};

export default Loading;
