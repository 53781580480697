import { Field } from 'redux-form';
import { Select } from 'components/Form';
import { useRequest } from 'hooks/useRequest';
import { getTypes } from 'services/properties';

export function FieldPropertyTypes(props) {
  const { data, isFetching } = useRequest({
    request: getTypes,
    params: {
      sort: '',
    },
  });

  return (
    <Field
      name="type_id"
      options={data}
      isFetching={isFetching}
      component={Select}
      labelKey="title"
      valueKey="id"
      {...props}
    />
  );
}
