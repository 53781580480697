import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1 0;
  margin: 0 5px;
  max-height: calc(100% - 48px);
`;
export const Content = styled.div`
  color: #21232c;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  height: 100%;
  background: #f5f5f6;
  padding: 5px;
`;

export const Header = styled.header`
  padding: 8px 8px 8px 12px;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: sticky;
  top: -8px;
  min-width: 45px;
  z-index: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #f5f5f6;

  ${Wrapper}:first-child & {
    padding-left: 8px;
  }
`;

export const HeaderInner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;

  .Dropdown {
    z-index: 999999 !important;
  }
`;

export const ColLeft = styled.div`
  flex: 1 0;
  max-width: 95%;
`;

export const Title = styled.p`
  vertical-align: baseline;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #21232c;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  vertical-align: baseline;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #65686f;
`;

export const Link = styled.a`
  display: block;
  font-size: 14px;
  background: #d3d3d3;
  text-align: center;
  padding: 10px 5px;
  border-radius: 4px;
  color: #3a3a3a;
  margin: 8px 4px 4px 4px;
  font-weight: bold;
`;
