import * as S from './styles';
import { MdHome } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, selectedsSelector } from 'modules/selecteds';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from 'hooks/useCurrentUser';

export default function SelectedProperties() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const propertiesSelected = useSelector(
    selectedsSelector('properties_search')
  );

  const history = useHistory();

  useEffect(() => {
    if (currentUser.id) {
      const propertiesSearch = localStorage.getItem(
        `@tecimob/${currentUser?.id}/properties_search`
      );

      // Adiciona uma nova lista de seleção
      dispatch(Actions.registerSelected('properties_search', propertiesSearch));
    }
  }, [currentUser]);

  useEffect(() => {
    if (propertiesSelected) {
      localStorage.setItem(
        `@tecimob/${currentUser?.id}/properties_search`,
        propertiesSelected || []
      );
    }
  }, [currentUser, propertiesSelected]);

  useEffect(() => {
    return () => {
      dispatch(Actions.unregisterSelected('properties_search'));
    };
  }, [dispatch]);

  if (!propertiesSelected?.length || propertiesSelected?.length === 0) {
    return null;
  }

  const handleWrapperClick = () => {
    const queryParams = propertiesSelected
      .map((id) => `filter[by_id][]=${id}`)
      .join('&');
    history.push(`/properties/search?${queryParams}&limit=50&offset=1`);
  };

  const handleRemoveAll = (e) => {
    e.stopPropagation();
    dispatch(Actions.resetSelecteds('properties_search'));
  };

  return (
    <S.Wrapper className="js-selected-properties" onClick={handleWrapperClick}>
      <S.Ball>
        <S.Number>
          {propertiesSelected.length.toString().padStart(2, '0')}
        </S.Number>
        <MdHome />
      </S.Ball>
      <S.RemoveAll onClick={handleRemoveAll}>
        <button>Remover todos</button>
      </S.RemoveAll>
    </S.Wrapper>
  );
}
