import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { Form } from 'features/Payments';
import { useModalUser } from 'containers/ModalRoot/ModalContractExtra/pages/Integrations/contexts/ModalUserContext';

const Pix = () => {
  const { preview2 } = useModalContractExtra();
  const { setPage } = useModalUser();

  return (
    <Form.Pix
      maxInstallments={1}
      totalCost={preview2.total_cost}
      onBack={() => setPage('plan')}
      onFinishPayment={() => {
        setPage('pix');
      }}
    />
  );
};

export default Pix;
