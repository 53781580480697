import React, { useMemo } from 'react';
import Modal from 'react-modal';

// Components
import { ModalTemplate } from 'components/Modal';
import Form from './components/Form';

function ModalProfileRemove({
  modalConfig,
  isOpen,
  handleClose,
  modalType,
  onCancel,
  onSubmitSuccess,
  onSubmit,
  profile,
  isDelete,
}) {
  const texts = useMemo(() => {
    if (isDelete) {
      return {
        title: 'Deletar perfil de busca',
        text: 'Você tem certeza que deseja deletar esse perfil de busca?',
        submitText: 'Deletar',
      };
    }

    return {
      title: 'Arquivar perfil de busca',
      text: 'Você tem certeza que deseja arquivar esse perfil de busca?',
      submitText: 'Arquivar',
    };
  }, [isDelete]);

  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={texts.title} handleClose={handleClose}>
        <Form
          texts={texts}
          onSubmit={onSubmit}
          data={profile}
          handleClose={handleClose}
          onCancel={onCancel}
          onSuccess={onSubmitSuccess}
        />
      </ModalTemplate>
    </Modal>
  );
}

export default ModalProfileRemove;
