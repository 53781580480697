import React, { createContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Collumn from './components/Collumn';
import { Wrapper } from './styles';
import isEmpty from 'lodash/isEmpty';

export const PipelineContext = createContext({
  data: [],
  onAfterClosePerson: () => {},
});

function Pipeline({
  layout = 'fixed',
  stages,
  isDraggable,
  handleDragStart,
  handleDragEnd,
  onAfterClosePerson,
  onSortInteraction,
  onMoveAll,
  onLossAllDeals,
}) {
  if (!stages || isEmpty(stages)) return null;

  // retorna as chaves das etapas
  const stagesKeys = Object.keys(stages);

  return (
    <PipelineContext.Provider
      value={{
        isDraggable,
        stages,
        onAfterClosePerson,
        layout,
      }}
    >
      <Wrapper layout={layout}>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {stagesKeys.map((key, index) => {
            const stage = stages[key];

            return (
              <Collumn
                key={stage.id}
                index={index}
                length={stagesKeys.length}
                stage={stage}
                onSortInteraction={onSortInteraction}
                onMoveAll={onMoveAll}
                onLossAllDeals={onLossAllDeals}
              />
            );
          })}
        </DragDropContext>
      </Wrapper>
    </PipelineContext.Provider>
  );
}

export default Pipeline;
