import { Filters } from './components/Filters';
import * as profileSearchService from 'services/profileSearch';
import { useRequest } from 'hooks/useRequest';
import { Profiles } from './components/Profiles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModalProfileRemove } from 'modules/modal';
import { openConfirmation } from 'containers/ModalConfirmation/module';

export function SearchProfile({ modalPerson, reopenModal, handleClose }) {
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = useState();

  // Id da pessoa que está ativa
  const personId = modalPerson?.person?.id;

  const { data, fetchData, isFetching } = useRequest({
    request: profileSearchService.getList,
    initialFetch: false,
  });

  const handleArchive = (profile) => {
    if (profile.status === 2) {
      dispatch(
        openConfirmation({
          title: 'Reativar perfil de busca',
          text: 'Você deseja realmente reativar esse perfil de busca?',
          submitButtonText: 'Reativar',
          request: () => {
            return profileSearchService.unarchive({
              person_id: profile.people_id,
              profile_id: profile.id,
            });
          },
          onSuccess: () => {
            handleChangeFilter(currentFilter);
          },
        })
      );

      return null;
    }

    dispatch(
      openModalProfileRemove({
        profile,
        onSubmit: ({ can_remove_properties }) => {
          return profileSearchService.archive({
            person_id: profile.people_id,
            profile_id: profile.id,
            remove_compatible: can_remove_properties,
          });
        },
        onSubmitSuccess: () => {
          reopenModal({
            initialPage: 'SearchProfile',
          });
        },
        onCancel: () => {
          reopenModal({
            initialPage: 'SearchProfile',
          });
        },
      })
    );
  };

  const handleDelete = (profile) => {
    dispatch(
      openModalProfileRemove({
        isDelete: true,
        profile,
        onSubmit: ({ can_remove_properties }) => {
          return profileSearchService.remove(profile.people_id, profile.id, {
            remove_compatible: can_remove_properties,
          });
        },
        onSubmitSuccess: () => {
          reopenModal({
            initialPage: 'SearchProfile',
          });
        },
        onCancel: () => {
          reopenModal({
            initialPage: 'SearchProfile',
          });
        },
      })
    );
  };

  const handleChangeFilter = (filter) => {
    setCurrentFilter(filter);

    let params = {};

    if (filter === 'active') {
      params.filter = {
        by_actives: true,
      };
    }

    if (filter === 'inactive') {
      params.filter = {
        by_deleteds: true,
      };
    }

    fetchData(personId, params);
  };

  return (
    <>
      <Filters
        personId={personId}
        onChangeFilter={handleChangeFilter}
        handleClose={handleClose}
      />
      <Profiles
        data={data}
        filter={currentFilter}
        onDelete={handleDelete}
        onArchive={handleArchive}
        onEdit={() => {
          handleClose();
        }}
      />
    </>
  );
}
