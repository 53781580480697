import { combineReducers } from 'redux';
// Modules
import crmConfigReducer from '../../modules/configCrm';
import scriptsReducer from './pages/Config/pages/ScriptsPages/module';
import { companyDataReducer } from './pages/Config/pages/CompanyData/module';
import menuReducer from './pages/Layout/pages/Menu/module';
import slidesReducer from 'pages/Site/pages/ContentSite/pages/Slides/module';
import searchReducer from './pages/Layout/pages/Search/module';

import { postsReducer } from 'pages/Site/pages/ContentSite/pages/Posts/module';

export default combineReducers({
  slides: slidesReducer,
  menu: menuReducer,
  search: searchReducer,
  crmConfig: crmConfigReducer,
  scripts: scriptsReducer,
  companyData: companyDataReducer,
  posts: postsReducer,
});
