// import * as S from './styles'

import successIcon from '../../success.svg';
import { Content } from '../../styles';

export function Pix({ installments }) {
  return (
    <Content style={{ maxWidth: 381 }}>
      <img src={successIcon} alt="Sucesso" />

      <h2>Sucesso</h2>

      <p className="text">Obrigado pela parceria!</p>

      {installments && installments > 1 ? (
        <p className="payment-method-text">
          Para as demais parcelas, será enviado instruções de pagamento 5 dias
          antes do vencimento ao seu WhatsApp.
        </p>
      ) : null}
    </Content>
  );
}
