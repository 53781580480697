import { useEffect } from 'react';
import { usePix } from '../../hooks/usePix';
import { Screens } from 'features/Payments';
import useFormValue from 'hooks/useFormValue';
import { removeCurrency } from 'lib/money-helpers';

const PaymentPixComponent = ({ goTo, onSuccess }) => {
  const plan = useFormValue('plan');

  const { code, data, isLoading, contract, creditId } = usePix(plan);

  useEffect(() => {
    contract();
  }, []);

  return (
    <div className="Modal__wrapper">
      <Screens.PaymentPix
        gatewayName={data?.gateway_name}
        isLoading={isLoading}
        pixCode={code}
        openAiId={creditId}
        netValue={removeCurrency(data?.price)}
        onBack={goTo('Payment')}
        onVerifyPaymentSuccess={({ handleClose }) => {
          onSuccess();
          handleClose();
        }}
      />
    </div>
  );
};

export default PaymentPixComponent;
