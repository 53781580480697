import useFormValue from 'hooks/useFormValue';

export const usePixValues = () => {
  const months = useFormValue('months');
  const users = useFormValue('additional_users');
  const mailboxes_number = useFormValue('mailboxes_number');
  const redirect_mails_number = useFormValue('redirect_mails_number');
  const is_dwv = useFormValue('is_dwv');
  const is_orulo = useFormValue('is_orulo');
  const type_plan = useFormValue('type_plan');
  const installments = useFormValue('installments');

  return {
    months,
    users,
    mailboxes_number,
    redirect_mails_number,
    is_dwv,
    is_orulo,
    type_plan,
    installments,
  };
};
