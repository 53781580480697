import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { contractWithPix } from 'services/financial/transactions';

export const usePix = ({
  type_plan = null,
  users,
  months,
  mailboxes_number,
  redirect_mails_number,
  credit_id,
  is_dwv,
  is_orulo,
  installments,
  consultant_id,
  consultant_should_not_sent,
  consultant_due_date,
}) => {
  const [contractResponse, setContractResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    try {
      setIsLoading(true);

      const res = await contractWithPix({
        additional_users: users,
        type_plan,
        months,
        mailboxes_number,
        redirect_mails_number,
        credit_id,
        is_dwv,
        is_orulo,
        installments,
        consultant_id,
        consultant_should_not_sent,
        consultant_due_date,
      });

      const { pix_qr_code, creditId } = res.data;

      setContractResponse(res);

      setGeneratedCreditId(creditId);
      setCode(pix_qr_code);
    } catch (err) {
      setError(err.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return {
    code,
    contractResponse,
    isLoading,
    error,
    imageUrl,
    contract,
    creditId: generatedCreditId,
  };
};
