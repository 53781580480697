import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { generatePix } from 'services/openai';

export const usePix = (plan) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    try {
      setIsLoading(true);
      const {
        data: { pix_qr_code, openai_id: creditId, price, gateway_name },
      } = await generatePix({ plan });

      setData({
        price,
        gateway_name,
        pix_qr_code,
        openai_id: creditId,
      });

      setGeneratedCreditId(creditId);
      setCode(pix_qr_code);
    } catch (error) {
      setData(null);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return {
    code,
    data,
    isLoading,
    imageUrl,
    contract,
    creditId: generatedCreditId,
  };
};
