import { WrapperAll } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import TableRow from './TableRow';

// import { Wrapper } from './styles';

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);

function TableSuperHighlights({
  data,
  handleChangeStatus,
  update,
  handleRemove,
  handleChangeOrder,
}) {
  // const dispatch = useDispatch();
  // const history = useHistory();

  if (!data || data.length === 0) return null;

  return (
    <WrapperAll>
      <table className="Table">
        <SortableCont
          onSortEnd={handleChangeOrder}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {data?.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              data={value}
              update={update}
              handleRemove={handleRemove}
              handleChangeStatus={handleChangeStatus}
            />
          ))}
        </SortableCont>
      </table>
    </WrapperAll>
  );
}

export default TableSuperHighlights;
