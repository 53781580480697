import { get, omitBy } from 'lodash';
import Alert from 'react-s-alert';
import { SubmissionError } from 'redux-form';
import api, { apiStorage } from './index';
// Helpers
import { filterSelected, responseMessage } from 'lib/service-helpers';
// Constants
import {
  LAND_TYPE,
  NEAR_SEA,
  PROFILE,
  SITUATION,
  TYPE_TRANSACTION,
} from 'constants/options';
import { googleRouteDirections } from 'lib/google-maps-helper';
import { STORAGE } from 'lib/HTTP';
import { STATUS } from './deals';
import { plural } from 'lib/text';

export const getName = (obj, { field, fieldName, data }) => {
  if (obj[field]) {
    return {
      ...obj,
      [fieldName]: data.find((v) => {
        // eslint-disable-next-line
        return v.value == obj[field];
      }).label,
    };
  }
  return obj;
};

/**
 * Normaliza a informação de um imóvel
 */
const normalizeProperty = (property) => {
  if (!property) return { id: '', name: '' };

  let currentProperty = { ...property };

  currentProperty = getName(currentProperty, {
    data: PROFILE,
    field: 'profile',
    fieldName: 'profileName',
  });

  currentProperty = getName(currentProperty, {
    data: TYPE_TRANSACTION,
    field: 'transaction',
    fieldName: 'transactionName',
  });

  currentProperty = getName(currentProperty, {
    data: NEAR_SEA,
    field: 'near_sea',
    fieldName: 'nearSeaName',
  });

  currentProperty = getName(currentProperty, {
    data: LAND_TYPE,
    field: 'land_type',
    fieldName: 'landTypeName',
  });

  return currentProperty;
};

/**
 * Normaliza uma lista de imóveis
 * @param properties
 */
const normalizeProperties = (properties) => properties.map(normalizeProperty);

/**
 * Transforma a resposta da busca de imóvel
 * @param res
 * @return {*}
 */
export const transformResponse = ({ data, ...res }) =>
  Array.isArray(data)
    ? { ...res, data: normalizeProperties(data) }
    : { ...res, data: normalizeProperty(data) };

/**
 *
 * @param id
 * @param params
 */
export const getOne = (id, params) =>
  api.getOne('properties', id, params).then(transformResponse);

/**
 * Lista todos os imóveis
 * @param params
 */
export const getList = (_params) => {
  const params = { ..._params };

  const filter = params?.filter;

  const available_lower_equals = filter?.available_lower_equals;
  const available_greater_equals = filter?.available_greater_equals;

  delete filter.available_lower_equals;
  delete filter.available_greater_equals;

  // Une os campos available_lower_equals e available_greater_equals em season_available_between
  if (available_lower_equals && available_greater_equals) {
    filter.season_available_between = `${available_lower_equals}-${available_greater_equals}`;
  }

  return api.getList('properties', params).then(transformResponse);
};

export const searchPropertyByReference = (reference) =>
  getList({ filter: { reference } });

/**
 * Pesquisa por imóveis
 * @param params
 */
export const searchProperties = (params) => {
  let _params = JSON.parse(JSON.stringify(params));

  _params.with_grouped_condos = true;

  if (_params?.filter?.condominium_id || _params.type_list === 'table') {
    delete _params.with_grouped_condos;
  }

  if (_params?.filter?.count) {
    delete _params?.filter?.count;
  }

  if (_params?.filter?.type_name) {
    delete _params?.filter?.type_name;
  }

  if (!_params?.filter?.on_network?.includes?.('guru')) {
    delete _params?.filter?.real_estate_id;
  }

  if (!_params.sort) {
    _params.sort = '-calculated_price';
  }

  return getList(_params);
};

/**
 * Busca os imóveis do condominium
 * @param params
 * @returns {Promise<* | {data} | {data: ({name: string, id: string}|*)}>}
 */
export const searchPropertiesCondo = (params) => {
  let _params = JSON.parse(JSON.stringify(params));

  delete _params?.with_grouped_condos;
  _params.filter.by_available_for_portals = true;

  if (_params?.filter?.count) {
    delete _params?.filter?.count;
  }

  if (_params?.filter?.type_name) {
    delete _params?.filter?.type_name;
  }

  if (!_params.sort) {
    _params.sort = '-calculated_price';
  }

  return getList(_params);
};

export const getListPropertyPortals = (params) => {
  const _params = { sort: '-updated_at,id', ...params };

  _params.filter = {
    ..._params.filter,
    by_available_for_portals: true,
  };

  return getList(_params);
};

/**
 * Pega a quantidade de imóveis que tem em um cep específico
 * @param cep
 * @returns {Promise<void>}
 */
export const getCountPropertiesByCep = async (cep) => {
  try {
    const { meta } = await getList({
      filter: {
        zip_code: cep,
        without_draft_scope: true,
        status: [1, 2, 3, 4],
      },
      offset: 1,
      limit: 1,
    });

    return parseInt(meta?.pagination?.total, 10);
  } catch {
    return 0;
  }
};

export const transformParams = (params = { filter: {} }) => {
  let currentParams = JSON.parse(
    JSON.stringify({
      filter: { ...params.filter },
      include: 'user',
      with_grouped_condos: true,
      sort: '',
      ...params,
    })
  );

  const isNotPublished = currentParams?.filter?.is_not_published;

  delete currentParams?.filter?.is_not_published;

  if (isNotPublished) {
    currentParams = {
      ...currentParams,
      filter: {
        ...currentParams?.filter,
        is_published: !isNotPublished,
      },
    };
  }

  const reference = currentParams?.filter?.reference;

  // Se tiver referência ignora qualquer outro tipo de filtro
  if (reference) {
    currentParams = {
      ...currentParams,
      filter: {
        reference,
      },
    };
  }

  const byPortalRealEstateId = currentParams?.filter?.by_portal_real_estate_id;

  if (byPortalRealEstateId) {
    currentParams.filter.is_published = true;
  }

  // Pega o campo status dos parametros
  const status = get(params, 'filter.status');

  // Verifica se tem status vindo no filtro
  const hasStatus = status || status === null || status === '';

  // Remove with_grouped_condos se existir um status
  // Não pode ter esse parametro se tiver status
  if (hasStatus) {
    delete currentParams.with_grouped_condos;
  }

  return currentParams;
};

/**
 * Retorna uma lista de imoveis
 * @param params
 */
export const getListWithCondos = (params = { filter: {} }) => {
  return getList(transformParams(params)).then(transformResponse);
};

/**
 * Pega os ultimos imóveis que
 * @param params
 * @return {*|{params}}
 */
export const getPropertiesLastedWinnings = (params) =>
  api.getList('properties/latest-winnings', params);

/**
 * Transforma o payload do cadastro/edição de imóvel
 * @param values
 * @returns {*}
 */
const transformPropertyPayload = (values) => {
  // Verifica se a transação é de venda
  const isRent = values.transaction === '2' || values.transaction === 2;

  // Remove os campos necessários quando a transação for
  if (isRent) {
    values.has_finance = false;
    values.is_financeable = false;
    values.is_exchangeable = false;
    values.exchange_note = '';
    values.is_financeable_mcmv = false;
  }

  return values;
};

/**
 * Adiconar um novo imóvel
 * @param {Object} values
 */
export const addProperty = (values) =>
  api
    .create('properties', transformPropertyPayload(values))
    .then(responseMessage('Imóvel Cadastrado'))
    .catch(responseMessage('Erro ao cadastrar imóvel'));

/**
 * Editar o imóvel
 * @param {Object} values
 */
export const alterProperty = (values) => {
  return api
    .update('properties', transformPropertyPayload(values))
    .then(responseMessage('Imóvel Alterado'))
    .catch(responseMessage('Erro ao editar imóvel'));
};

export const moveToActiveProperty = (values) =>
  api
    .update('properties', { ...values, status: 1 })
    .then(responseMessage('Imóvel Reativado'))
    .catch(responseMessage('Erro ao reativar imóvel'));

export const transferProperty = (values) =>
  api
    .update('properties', values)
    .then(responseMessage('Imóvel transferido'))
    .catch(responseMessage('Erro ao tranferir imóvel'));

/*.then(responseMessage('Imóvel Publicado'))
.catch(responseMessage('Erro ao publicar imóvel'));*/

const clearUncheked = (object) => {
  return omitBy(object, ({ is_checked }) => !is_checked);
};

window.clearUncheked = clearUncheked;

export const publicProperty = async ({ id, ...values }) => {
  try {
    if (values.portal_real_estate_options) {
      values.portal_real_estate_options = clearUncheked(
        values.portal_real_estate_options
      );
    }

    const res = await api.update(`properties/${id}/publish`, values);
    responseMessage('Imóvel publicado')(res);
  } catch (e) {
    responseMessage('Erro ao publicar imóvel')(e);
    throw e;
  }
};

export const inativeProperty = (propertyId) =>
  api
    .delete(`properties/${propertyId}/trash`)
    .then(responseMessage('Imóvel inativado'))
    .catch(responseMessage('Erro ao inativado imóvel'));

export const removeProperty = (propertyId) =>
  api
    .delete('properties', `${propertyId}?force=true`)
    .then(responseMessage('Imóvel excluído'))
    .catch(responseMessage('Erro ao deletar imóvel'));

export const reactivateProperty = (propertyId) =>
  api
    .create(`properties/${propertyId}`)
    .then(responseMessage('Imóvel Reativado'))
    .catch(responseMessage('Erro ao reativar imóvel'));

/**
 * Inverte a ordem de destacado do imóvel
 * @param property
 * @return {*}
 */
export const fetchToggleFeatured = (property) =>
  api.update('properties', {
    id: property.id,
    is_featured: !property.is_featured,
  });

// Type Subtypes
export const normalizeTypeSubtype = (data) =>
  data.map((type) => ({
    ...type,
    type_id: type.id,
    label: type.title,
    options: type.subtypes.map((subtype) => ({
      label: subtype.title,
      value: subtype.id,
      type_name: type?.title,
    })),
  }));

export const normalizeTypeSubtypeResponse = (res) => ({
  ...res,
  data: normalizeTypeSubtype(res.data),
});
export const getTypesSubtypes = (params) =>
  getTypes(params).then(normalizeTypeSubtypeResponse);

// Reference
export const getReference = (reference) =>
  api.getList(`properties/references/${reference || ''}`);
export const isValidReference = (reference, params) => {
  if (params.skip_references) {
    return api.get('properties/references', params);
  }

  return api.create('properties/references', { reference, ...params });
};

export const registerAddImage = (propertyId) =>
  api.create(`properties/${propertyId}/images/audits`, {
    event: 'image_created',
  });

export const registerRemoveImage = (propertyId) =>
  api.create(`properties/${propertyId}/images/audits`, {
    event: 'image_deleted',
  });

// Property Images
export const addImage = ({ property_id, ...params }) => {
  return apiStorage
    .uploadFile(`properties/${property_id}/images`, params)
    .catch(
      responseMessage({
        413: 'Tamanho máximo da imagem é 10 Mega',
      })
    );
};

export const lastOrder = async ({ property_id, gallery }) => {
  try {
    const { data: images } = await getImages(property_id, {
      filter: { gallery },
    });

    return images[images.length - 1].order;
  } catch {
    return 0;
  }
};

// Reordena as imagens do imóvel de uma galeria em específico
export const orderImages = (property_id, gallery) =>
  api.create(`properties/${property_id}/images/order/${gallery}`);
export const getImages = (property_id, params) =>
  api.getList(`properties/${property_id}/images`, { ...params, sort: null });
export const removeImage = async (id, property_id) => {
  try {
    const response = await apiStorage.delete(
      `properties/${property_id}/images`,
      id
    );
    registerRemoveImage(property_id);
    Alert.success('Imagem removida');
    return response;
  } catch (e) {
    Alert.success('Erro ao remover imagem');
    throw e;
  }
};
export const alterImage = ({ id, property_id, ...params }) =>
  api.update(`properties/${property_id}/images/${id}`, params);
export const rotateImage = ({ id, property_id }) =>
  STORAGE.post(`properties/${property_id}/images/${id}/rotate`);

/**
 * Remove todas as imagens do imóvel
 * @param propertyId
 * @param gallery
 */
export const removeGallery = async (propertyId, gallery) => {
  try {
    const response = await STORAGE.delete(`properties/${propertyId}/images`, {
      params: { filter: { gallery } },
    });

    registerRemoveImage(propertyId);

    return response;
  } catch (e) {
    throw e;
  }
};

// Negócios
/**
 * Lista todos os negócios do imóvel
 * @param propertyId
 * @param params
 */
export const getDeals = (propertyId, status, params) =>
  api.getList(`properties/${propertyId}/deals`, {
    ...params,
    filter: { status },
    include: 'stage.funnel',
  });

/**
 * Pega todos os negócios que estão abertos
 * @param propertyId
 */
export const getOpenedDeals = (propertyId) =>
  getDeals(propertyId, STATUS.OPENED);

/**
 * Pega todos os negócios ganhos do imóvel
 * @param propertyId
 */
export const getGainDeals = (propertyId) => getDeals(propertyId, STATUS.GAIN);

/**
 * Pega todos os negócios perdidos
 * @param propertyId
 */
export const getLossDeals = (propertyId) => getDeals(propertyId, STATUS.LOSS);

/**
 * Pega os perfis de imóvel
 * @param params
 * @param config
 * @return {Array} - [1,3,5]
 */
export const getProfiles = (params, config) =>
  api.getList('properties/profiles', params, config);

/**
 * Clonar imóvel
 */
export const cloneProperty = (propertyId, values) =>
  api
    .create(`properties/${propertyId}/clone`, values)
    .then(responseMessage('Imóvel duplicado'))
    .catch(responseMessage('Erro ao duplicar imóvel'));

/**
 * Pegar caracteristicas
 * @param propertyId
 * @param params
 */
export const getCharacteristics = (params) =>
  api.getList('properties/characteristics', params);

/**
 * Pega as caracteristicas de um imóvel
 * @param params
 * @return {*|{params}}
 */
export const getCondosCharacteristics = (params) =>
  api.getList('properties/condos-characteristics', params);

/**
 * Pega uma lista de tipos de imóvel
 * @param params
 */
export const getTypes = (params) => api.getList('properties/types', params);

/**
 * Pega um tipo de imóvel específico
 * @param typeId
 * @param params
 * @return {*|{params}}
 */
export const getType = (typeId, params) =>
  api.getOne('properties/types', typeId, params);

/**
 * Pega uma lista de subtipos de imóvel
 * @param params
 */
export const getSubtypes = ({ type_id, ...values }) =>
  api.getList(`types/${type_id}/subtypes`, values);

const transformCondo = (condo) => {
  let titleFormatted = condo?.title;

  if (condo?.neighborhood) {
    titleFormatted = `${condo?.title} (${condo?.neighborhood?.name})`;
  }

  return {
    ...condo,
    title: titleFormatted,
  };
};

/**
 * Pega uma lista de condomínios
 * @param params
 */
export const getCondos = async (params) => {
  const res = await api.getList('properties/condos', {
    ...params,
    include: 'neighborhood',
    sort: '',
  });

  res.data = res.data.map(transformCondo);

  return res;
};

export const getCondoById = async (id) => {
  try {
    const res = await getCondos({ filter: { by_id: id } });
    res.data = res.data[0];
    return res;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Pega os usuários do imóvel
 * @param params
 */
export const getUsers = (params) => api.getList('properties/realtors', params);

/**
 * Pega indíces financeiros do imóvel
 * @param params
 */
export const getFinancialIndexes = (params) =>
  api.getList('properties/financial-indexes', params);

/**
 * Pega todos os estabelecimentos do imóvel
 * @param params
 * @return {*|{params}}
 */
export const getEstablishments = (params) =>
  api.getList('properties/establishments', params);

/**
 * Faz o upload de um documento
 */
export const uploadDocument = async ({ property_id, id, ...params }) => {
  try {
    const res = await apiStorage.uploadFile(
      `properties/${property_id}/documents/${id || ''}`,
      params
    );

    return res;
  } catch (err) {
    if (err instanceof SubmissionError) {
      if (err.errors.document) {
        Alert.success(
          `Erro ao enviar o arquivo ${params.name}. O formato ${params.document.type} não é suportado`
        );
      }
    }
  }
};

export const uploadDocumentForm = async ({ property_id, id, ...params }) => {
  try {
    const res = await api.uploadFile(
      `properties/${property_id}/documents/${id || ''}`,
      params
    );

    return res;
  } catch (err) {
    if (err instanceof SubmissionError) {
      if (err.errors.document) {
        Alert.success(
          `Erro ao enviar o arquivo ${params.name}. O formato ${params.document.type} não é suportado`
        );

        throw new SubmissionError({
          document: 'Tipo de arquivo inválido',
        });
      }
    }

    if (err?.response?.data?.alerts)
      throw new SubmissionError({
        document: err?.response?.data?.alerts,
      });
  }
};

/**
 * Lista todos os documentos do imóvel
 * @param property_id
 * @param params
 */
export const getDocuments = (property_id, params) =>
  api.getList(`properties/${property_id}/documents`, {
    ...params,
  });

export const getDocument = ({ property_id, id, params }) =>
  api.getOne(`properties/${property_id}/documents`, id, {
    ...params,
  });

/**
 * Atualiza um documento
 * @param property_id
 * @param data
 * @return {Promise<any>}
 */
export const updateDocument = ({ property_id, ...data }) =>
  api
    .update(`properties/${property_id}/documents`, data)
    .then(responseMessage('Documento alterado!'))
    .catch(responseMessage('Falha ao alterar documento'));

/**
 * Deletar um documento
 * @param id
 * @param property_id
 * @return {*}
 */
export const removeDocument = ({ id, property_id }) =>
  apiStorage
    .delete(`properties/${property_id}/documents`, id)
    .then(responseMessage('Documento excluído!'))
    .catch(responseMessage('Falha ao deletar documento'));

export const getSituations = (params) =>
  api.getList('properties/situations', params);

/**
 * Retorna uma lista de situações
 * @param situations
 */
export const filterSituations = (situations) =>
  filterSelected({
    data: SITUATION,
    selected: situations,
  });

/**
 * Retorna a lista dos perfil selecionados
 * @param profiles
 */
export const filterProfiles = (profiles) =>
  filterSelected({
    data: PROFILE,
    selected: profiles,
  });

/**
 * Cria um link para visualização dos imóveis no site
 * @param properties_id - array de ids de imóveis
 */
export const shareProperties = (properties_id) =>
  api.create('settings/sites/properties-list', {
    properties_id,
  });

export const sharePropertiesUpdate = ({ id, title }) =>
  api
    .update(`settings/sites/properties-list/${id}`, {
      title,
    })
    .then(responseMessage('Título atualizado'));

/**
 * Atualiza varios imóveis de uma vez
 * @param properties
 * @returns {*|AxiosPromise<any>}
 * @example
 * propertiesMassUpdate({
 *   properties: {
 *     dfe06277-d19b-42a6-9578-e8202b26948e: {
 *       id: 'dfe06277-d19b-42a6-9578-e8202b26948e',
 *       price: 'R$ 999.000,00'
 *     }
 *   }
 * })
 */
export const propertiesMassUpdate = (properties) =>
  api.update('properties/mass-update', {
    properties,
  });

export const propertiesMassPublish = (properties) =>
  api.patch('properties/partial/mass-publish', {
    properties,
  });

export const propertiesMassFinancial = (properties) =>
  api.patch('properties/partial/mass-financial', {
    properties,
  });

export const propertiesMassUser = (properties) =>
  api.patch('properties/partial/mass-user', {
    properties,
  });

/**
 * Lista todos os negócios relacionados com um imóvel
 * @param propertyId
 * @param params
 * @return {*|Promise<AxiosResponse<T>>}
 */
export const propertiesDeals = (propertyId, params = {}) =>
  api.get(`properties/${propertyId}/deals`, {
    ...params,
    sort: 'updated_at',
    include: 'stage.funnel.user',
  });

export const propertiesEarnedDeals = (propertyId) =>
  propertiesDeals(propertyId, { filter: { by_status: '1' } });

export const propertiesLossDeals = (propertyId) =>
  propertiesDeals(propertyId, { filter: { by_status: '2' } });

/**
 * Retorna todos os negócios perdidos/abertos
 * @param propertyId
 */
export const getAllDeals = async (propertyId) =>
  api.get(`properties/${propertyId}/deals`);

/**
 * Retorna todos os negócios ganhos do imóvel
 * @param propertyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllEarnedDeals = async (propertyId) => {
  return api.get('crm/deals/earneds', {
    sort: 'property_id',
    filter: {
      property_id: propertyId,
    },
    include: 'user,people,property',
  });
};

const getDestinations = (property) => ({
  lat: property.maps_latitude,
  lng: property.maps_longitude,
});

export const propertiesItinerary = async (propertiesIds) => {
  try {
    //const { latitude, longitude } = await getUserPosition();

    const { data: properties } = await getList({
      filter: {
        by_id: propertiesIds,
      },
    });

    // Monta o array com os destinos
    let destinations = properties.map(getDestinations);
    const origin = destinations[0];
    destinations.shift();

    // Retorna a url que tem o trajeto a ser feito
    return googleRouteDirections(origin, destinations);
  } catch (err) {
    throw err;
  }
};

/**
 * Move para lixeira multiplos imóveis
 * @param properties_id
 */
export const inativeMultipleProperties = (properties_id) =>
  api.delete('properties/mass-trash', '', {
    params: {
      properties_id,
    },
  });

/**
 * Busca se ele pode ou não remover os imóveis
 * @param properties_id
 * @returns Promise<>{
 *   data: [
 *     { reference: 'ref1', reason: '...' }
 *   ]
 * }
 */
export const canInativeProperties = (properties_id) =>
  api.getOne('properties/mass-trash', '', {
    properties_id,
  });

/**
 * Verifica se a descrição está dentro dos padrões dos portais imobiliários
 * @param description
 * @param condo_description
 * @returns {*}
 */
export const checkPropertyDescription = (description, condoDescription) =>
  api.create('settings/portals/check-property-description', {
    description,
    condo_description: condoDescription,
  });

/**
 * Pega um imóvel do portal
 * @param id
 * @returns {Promise<AxiosResponse<never>>}
 */
export const getPropertyPortal = ({ id }) => {
  return api.getOne(`properties/${id}/network-property`, '');
};

/**
 * Atualiza um imóvel do órulo
 * @param id
 * @param payload
 */
export const editOrulo = ({ id, property_id, ...values }) => {
  return api.update(`properties/${property_id}/network-property`, values);
};

export const getSolarPositions = (params, config) =>
  api.getList('properties/solar-positions', params, config);

/**
 * Busca os clientes compatíveis com o imóvel
 * @param id
 * @return {Promise<AxiosResponse<never>>}
 */
export const getMatcheds = ({ id }, sort = 'updated_at') => {
  // const currentUser = localStorage.getItem('current-user');

  return api.getList(`properties/${id}/matcheds/new`, {
    sort,
  });
};

/**
 * Descartar vários imóveis da lista de interessados
 * @param properties_id
 * @param user_id
 * @return {Promise<AxiosResponse<never>>}
 */
export const discardMatcheds = (properties_id, user_id) => {
  return api.delete('properties/matcheds', '', {
    params: {
      properties_id,
      user_id,
    },
  });
};

export const propertiesMatched = ({
  filter,
  user_id,
  offset = 1,
  limit = 20,
}) =>
  api.getList('properties/properties-matcheds', {
    filter,
    user_id,
    offset,
    limit,
  });

export const peopleMatched = ({
  filter,
  sort,
  user_id,
  offset = 1,
  limit = 20,
}) =>
  api.getList('properties/people-matcheds', {
    filter,
    user_id,
    offset,
    limit,
    sort: sort || 'by_name',
  });

export const discardMatchedsById = (properties_id, user_id) =>
  api
    .delete(`properties/matcheds`, '', {
      params: {
        properties_id,
        user_id,
      },
    })
    .then(
      responseMessage(
        plural(properties_id.length, 'imóvel descartado', 'imóveis descartados')
      )
    );

export const generateDescription = async ({
  property_id,
  tone = 'profissional',
}) => {
  if (process.env.NODE_ENV === 'development') {
    return {
      data: {
        role: `Título ${tone}`,
        content: `Texto ${tone}`,
      },
    };
  }

  return api.getOne(`properties/${property_id}/generate-description`, '', {
    tone,
  });
};

export const duplicateProperties = async ({ property_id, properties }) => {
  return api.create(`properties/${property_id}/mass-clone`, {
    properties,
  });
};

/**
 * Renovar data
 * @param id
 * @param values
 * @returns {Promise<AxiosResponse<*>>}
 */
export const nextReview = ({ id, ...values }) => {
  return api.patch(`properties/partial/${id}/next-review`, values);
};
