import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Modules
import {
  currentPageSelector,
  gallerySelector,
  infosSelector,
  isFetchingSelector,
  menuThunks,
} from 'modules/modalProperty';
import {
  Actions as ImagesActions,
  Selectors as ImagesSelector,
} from 'modules/modalImages';

import {
  closeModal,
  openModalAuthorizationRent,
  openModalAuthorizationSell,
  openModalCreci,
  openModalOpenDeal,
  openModalProperty,
  openModalPropertyBook,
  openModalPropertyDuplicate,
  openModalPropertyDuplicateMulti,
  openModalPropertyEditOrulo,
  openModalPropertyExcluded,
  openModalPropertyPrint,
  openModalPropertyPublication,
  openModalPropertySendMail,
  openModalReservationClose,
  openModalReservationCreate,
  openModalReservationUpdate,
  openModalVisit,
} from 'modules/modal';
import { getPropertyInfo } from 'modules/propertyInfo';
import { getProperties } from 'modules/properties';
// Services
import * as userService from 'services/user';
import * as propertiesService from 'services/properties';
// Pages Components
import ModalDefault from './components/ModalDefault';
import ModalPropertyNetwork from './components/ModalPropertyNetwork';
import ModalBlocked from './components/ModalBlocked';
// Pages
import Pages from './pages';

const defaultProps = {
  currentPage: 'Record',
};
const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  getPropertyInfo: PropTypes.func.isRequired,
};

class ModalProperty extends Component {
  state = {
    galleryOpen: false,
    ownerUser: {},
    transferProperty: false,
  };

  handleEsc = (event) => {
    if (event.keyCode == 27 && this.props.isOpenCarousel) {
      return this.props.closeCarousel();
    }

    if (event.keyCode == 27 && this.props.isOpenGallery) {
      return this.props.closeGallery();
    }

    if (event.keyCode == 27 && !this.state.galleryOpen) {
      this.props.closeModal();
    }
  };

  async componentDidMount() {
    const { property, getPropertyInfo, defaultPage, initialPage, setPage } =
      this.props;

    setPage('Record');

    if (defaultPage || initialPage) {
      setPage(defaultPage || initialPage);
    }

    if (!property.is_blocked) {
      getPropertyInfo(property);
    } else {
      userService
        .getOne(property.user_id, { include: 'permissions' })
        .then((user) => {
          this.setState({ ownerUser: user.data });
        });
    }

    document.addEventListener('keydown', this.handleEsc, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, true);
  }

  onlyCloseModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  /**
   * Liga e desliga a parte de transferência de modal
   */
  handleToogleTransferProperty = () => {
    this.setState({ transferProperty: !this.state.transferProperty });
  };

  /**
   * Abre a modal para verificar o CRECI
   * @param user
   */
  handleOpenModalCreci = () => {
    const { ownerUser } = this.state;
    const { property, openModalCreci, openModalProperty } = this.props;

    openModalCreci({
      user: ownerUser,
      afterClose: () => {
        getProperties();
        openModalProperty({ property });
      },
    });
  };

  // Abre a modal de ficha de visita
  handleOpenModalVisit = () => {
    const { infos, openModalVisit, openModalProperty } = this.props;

    openModalVisit({
      property: infos,
      afterClose: () => {
        openModalProperty({ property: infos });
      },
    });
  };

  // Abre a modal de ficha de visita
  handleOpenModalSalesAuthorization = () => {
    const {
      infos,
      openModalAuthorizationSell,
      openModalAuthorizationRent,
      openModalProperty,
    } = this.props;

    if (infos?.transaction === 1 || infos.transaction === '1') {
      openModalAuthorizationSell({
        property: infos,
        initialValues: {
          user_id: infos?.user?.id,
          people_id: infos?.people_id,
        },
        afterClose: () => {
          openModalProperty({ property: infos });
        },
      });
    } else {
      openModalAuthorizationRent({
        property: infos,
        initialValues: {
          user_id: infos?.user?.id,
          people_id: infos?.people_id,
        },
        afterClose: () => {
          openModalProperty({ property: infos });
        },
      });
    }
  };

  /**
   * Cuida da lógica para transferir um ou mais imóveis do corretor
   */
  handleTransferProperty = (values) => {
    const { property, handleClose, getProperties } = this.props;
    const { ownerUser } = this.state;
    const { to_user_id, quantity_properties } = values;

    if (quantity_properties === 'one') {
      return propertiesService
        .transferProperty({ id: property.id, user_id: to_user_id })
        .then(() => {
          getProperties();
          handleClose();
        });
    } else if (quantity_properties === 'all') {
      return userService.transferUser(ownerUser.id, to_user_id).then(() => {
        getProperties();
        handleClose();
      });
    }
  };

  /**
   * Lida com o fechamento da modal
   * @param e
   */
  handleCloseModal = (e) => {
    const { handleClose } = this.props;
    handleClose();
  };

  openModalProperty = () => {
    const { property, openModalProperty, ...props } = this.props;

    openModalProperty({
      property,
      ...props,
    });
  };

  changeGalleryVisibility = (visibility) => {
    this.setState({ galleryOpen: visibility });
  };

  /**
   * Lida com a mudança do menu
   * @param item
   * @return {*}
   */
  handleChangeMenuItem = (item) => this.props.setPage(item);

  /**
   * Função que renderiza a página que ele está visualizando
   */
  handleRenderPage = (props) =>
    React.createElement(Pages[this.props.currentPage], {
      ...this.props,
      ...props,
    });

  /**
   * Abre a modal de duplicar imóvel
   * @param property
   */
  handleOpenModalDuplicate = (property) => () => {
    const { afterClose } = this.props;

    this.props.openModalPropertyDuplicateMulti({
      property,
      onSubmitSuccess: (res) => {
        const propertyId = res.data.properties_id[0];

        if (afterClose) afterClose();
        this.props.openModalProperty({
          property: propertyId ? { id: propertyId } : property,
        });
      },
    });
  };

  /**
   * Abre a modal de imprimir imóvel
   * @param {Object} property - Imóvel atual
   */
  handleOpenModalPrint = (property) => () =>
    this.props.openModalPropertyPrint({ property });

  /**
   * Abre a modal de edicao de publicação
   * @param property
   * @return {function(): *}
   */
  handleOpenModalPropertyEditOrulo = (property) => () =>
    this.props.openModalPropertyEditOrulo({
      id: property?.id,
      onSubmitSuccess: () => {
        this.props.openModalProperty({ property });
      },
    });

  /**
   * Abre a modal de edicao de publicação
   * @param property
   * @return {function(): *}
   */
  handleOpenModalPublication = (property) => () =>
    this.props.openModalPropertyPublication({
      property,
      afterClose: () => {
        this.props.openModalProperty({ property });
      },
    });

  /**
   * Abre a modal de enviar imóvel por e-mail
   * * @param {Object} property - Imóvel atual
   */
  handleOpenModalSendMail = (property) => () =>
    this.props.openModalPropertySendMail({
      properties: property,
      afterClose: () => {
        this.props.openModalProperty({ property });
      },
    });

  /**
   * Abre a modal de reserva
   * @param property
   */
  handleOpenModalPropertyBook = (property) => () => {
    if (property.is_booked) {
      this.props.openModalReservationUpdate({
        property,
        afterClose: () => {
          this.props.openModalProperty({ property });
        },
      });
    }

    this.props.openModalReservationCreate({
      property,
      afterClose: () => {
        this.props.openModalProperty({ property });
      },
    });
  };

  /**
   * Abre a modal de enviar imóvel para os excluidos
   * * @param {Object} property - Imóvel atual
   */

  handleOpenModalPropertyExcluded = (property) => () => {
    const {
      infos,
      openModalPropertyExcluded,
      onDelete,
      openModalProperty,
      afterClose,
    } = this.props;

    openModalPropertyExcluded({
      property: infos,
      onSubmitSuccess: () => {
        if (onDelete) onDelete();
        if (afterClose) afterClose();
        openModalProperty({ property });
      },
    });
  };

  handleChangeStatusActive = (property) => () => {
    return propertiesService.reactivateProperty(property.id).then((res) => {
      this.props.getPropertyInfo(this.props.property);
      return res;
    });
  };

  handleOpenPropertyOnSite = () => {
    const { property } = this.props;

    window.open(property.site_link);
  };

  get isOnNetwork() {
    const { infos } = this.props;
    return (
      infos?.is_on_network &&
      infos?.network_type !== 'Órulo' &&
      infos?.network_type !== 'DWV'
    );
  }

  isExcluded() {
    return this.props.infos.status === 'Excluído';
  }

  render() {
    const allProps = {
      ...this.props,
      ownerUser: this.state.ownerUser,
      isExcluded: this.isExcluded(),
      changeGalleryVisibility: this.changeGalleryVisibility,
      handleOpenMyself: this.openModalProperty,
      handleCloseModal: this.handleCloseModal,
      onlyCloseModal: this.onlyCloseModal,
      handleRenderPage: this.handleRenderPage,
      handleChangeMenuItem: this.handleChangeMenuItem,
      handleOpenModalDuplicate: this.handleOpenModalDuplicate,
      handleOpenModalPrint: this.handleOpenModalPrint,
      handleOpenModalSendMail: this.handleOpenModalSendMail,
      handleOpenModalPropertyExcluded: this.handleOpenModalPropertyExcluded,
      handleChangeStatusActive: this.handleChangeStatusActive,
      handleOpenModalPublication: this.handleOpenModalPublication,
      handleOpenPropertyOnSite: this.handleOpenPropertyOnSite,
      handleOpenModalVisit: this.handleOpenModalVisit,
      handleOpenModalPropertyBook: this.handleOpenModalPropertyBook,
      handleOpenModalSalesAuthorization: this.handleOpenModalSalesAuthorization,
      handleOpenModalPropertyEditOrulo: this.handleOpenModalPropertyEditOrulo,
    };

    // Verifica se o imóvel está bloqueado
    // se for, renderiza a propriedado com o componente especial para quando o imóvel é bloqueado
    if (this.props?.property?.is_blocked) {
      return (
        <ModalBlocked
          {...allProps}
          currentUser={localStorage.getItem('current-user')}
          transferProperty={this.state.transferProperty}
          handleToogleTransferProperty={this.handleToogleTransferProperty}
          handleTransferProperty={this.handleTransferProperty}
          handleOpenModalCreci={this.handleOpenModalCreci}
        />
      );
    }

    // Verifica se o imóvel é da rede
    // se for, renderiza a propriedade com o componente especial para quando o imóvel é da rede
    if (this.isOnNetwork) {
      return <ModalPropertyNetwork {...allProps} />;
    }

    return <ModalDefault {...allProps} />;
  }
}

ModalProperty.defaultProps = defaultProps;
ModalProperty.propTypes = propTypes;

const mapStateToProps = (state) => ({
  isOpenGallery: ImagesSelector.isOpen(state),
  isOpenCarousel: ImagesSelector.isOpenCarousel(state),
  infos: infosSelector(state),
  isFetching: isFetchingSelector(state),
  gallery: gallerySelector(state),
  currentPage: currentPageSelector(state),
});

const mapDispatchToProps = {
  closeGallery: ImagesActions.close,
  closeCarousel: ImagesActions.closeCarousel,
  getPropertyInfo,
  setPage: menuThunks.setPage,
  openModalPropertyDuplicate,
  openModalProperty,
  openModalOpenDeal,
  openModalPropertyEditOrulo,
  openModalPropertyPrint,
  openModalPropertySendMail,
  openModalPropertyExcluded,
  openModalPropertyPublication,
  openModalCreci,
  openModalVisit,
  openModalAuthorizationSell,
  openModalAuthorizationRent,
  openModalReservationCreate,
  openModalReservationUpdate,
  openModalReservationClose,
  openModalPropertyBook,
  openModalPropertyDuplicateMulti,
  getProperties,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProperty);
