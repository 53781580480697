import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// Modules
import { currentUserSelector, resellerSelector } from 'modules/login';
import {
  openModalContract,
  openModalPayment,
  openModalSupport,
} from 'modules/modal';

// Services
import api from 'services';

// Components
import Button from 'components/Button';
import NoContent from 'components/NoContent';
import Wrapper from 'components/Wrapper';
// Assets
import { shareWhatsapp } from 'components/ChatBox';
import noCredits from './icon-no-credits.png';
import timer from './icon-timer.png';

const Link = ({ children, onClick }) => {
  return (
    <span className="h-link h-pointer" onClick={onClick}>
      {children}
    </span>
  );
};

class PlanFinished extends React.Component {
  handleClickSupport = (e) => {
    e.preventDefault();
    const { openModalSupport } = this.props;

    openModalSupport();
  };

  get renderContactPlan() {
    const { suport_email, is_tecimob } = this.props.reseller;

    if (!suport_email || !is_tecimob) return null;

    return (
      <p className="h-color--primary">
        Dúvidas?{' '}
        <a
          className="h-link"
          target="_blank"
          href={shareWhatsapp('554896049333')}
          rel="noreferrer"
        >
          clique aqui
        </a>{' '}
        e entre em contato.
      </p>
    );
  }

  handleContractPlan = (params) => {
    const { openModalPayment } = this.props;
    openModalPayment(params);
  };

  handleSchedule = () => {
    this.handleContractPlan();
  };

  handleUnlock2Days = async () => {
    await api.create('real-estates/unlock-2-days');
    window.location.reload();
  };

  // Liberar +2 dias de teste
  // Adicionar um botão que permita que imobiliárias não pagantes bloqueadas após o fim do período de testes desbloqueiem o sistema por 2 dias.
  get canUnlock2Days() {
    const {
      currentUser: { real_estate },
    } = this.props;

    // A imobiliária precisa estar bloqueada
    const isBlocked = real_estate.blocked === true;

    // A imobiliaria não pode ser pagante
    const isNotPaying = real_estate.is_paying === false;

    // A imobiliária precisa ter dias
    const hasFreeDays = real_estate.has_free_days;

    return isBlocked && isNotPaying && hasFreeDays;
  }

  render() {
    const {
      reseller: { is_tecimob },
      currentUser: {
        real_estate: { is_paying },
      },
    } = this.props;

    // Quando a imobiliária ja é recorrente
    // Retorna o aviso de imobiliaria bloqueada por falta de pagamento
    if (is_paying) {
      return (
        <Wrapper>
          <NoContent
            title="Sua conta esta bloqueada."
            text="Acesse a área pagamentos para regularizar sua conta"
            image={noCredits}
          >
            <Button
              to="/plan/history"
              color="success"
              className="h-margin-bottom--15"
            >
              Ir para área de pagamentos
            </Button>
            {this.renderContactPlan}
          </NoContent>
        </Wrapper>
      );
    }

    // Quando a imobiliaria não é recorrente
    return (
      <Wrapper>
        <NoContent
          title="Seu período de testes chegou ao fim"
          text="O que você achou de experimentar os nossos recursos? Vamos continuar com essa parceira de sucesso!"
          image={timer}
        >
          <Button
            onClick={this.handleContractPlan}
            color="success"
            className="h-margin-bottom--15"
          >
            Contratar
          </Button>
          {this.canUnlock2Days ? (
            <Button
              onClick={this.handleUnlock2Days}
              color="secondary"
              className="h-margin-bottom--15"
            >
              Liberar por mais 2 dias
            </Button>
          ) : null}
          {this.renderContactPlan}
        </NoContent>
      </Wrapper>
    );
  }
}

PlanFinished.defaultProps = {
  currentUser: {
    real_estate: {
      is_paying: false,
      users_count: 0,
    },
  },
  reseller: {
    is_tecimob: false,
    suport_email: null,
  },
  openModalSupport: null,
};

PlanFinished.propTypes = {
  currentUser: PropTypes.shape({
    real_estate: PropTypes.shape({
      is_paying: PropTypes.bool,
      users_count: PropTypes.number,
    }),
  }),
  reseller: PropTypes.shape({
    is_tecimob: PropTypes.bool,
    suport_email: PropTypes.string,
  }),
  openModalSupport: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: currentUserSelector(state),
  reseller: resellerSelector(state),
});

const mapDispatchToProps = {
  openModalSupport,
  openModalContract,
  openModalPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanFinished);
