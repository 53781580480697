import * as S from './styles';
import { MdArchive, MdDelete, MdEdit, MdUnarchive } from 'react-icons/md';
import Button from 'components/Button';
import { Rooms } from '../Rooms';
import { Address } from '../Address';

const TRANSACTION = {
  1: 'Venda',
  2: 'Aluguel',
  3: 'Temporada',
};

export function Item({ data, filter, onEdit, onArchive, onDelete }) {
  return (
    <S.Wrapper>
      <S.BoxLeft>
        <S.Box>
          <p>Tipo de imóvel</p>
          <p>
            <strong>{data.title}</strong>
          </p>
        </S.Box>
        <S.Box>
          <p>Valor</p>
          <p>
            <strong>{data.price_range}</strong>
          </p>
        </S.Box>
        <S.Box>
          <p>Negócio</p>
          <p>
            <strong>{TRANSACTION[data.transaction]}</strong>
          </p>
        </S.Box>
        <Address data={data} />
      </S.BoxLeft>
      <Rooms data={data} />
      <S.Spacing />
      <S.BoxActions>
        <S.Buttons>
          {data.status === 1 ? (
            <Button
              to={`/profile/update/${data.people_id}/${data.id}`}
              color="white"
              size="icon"
              onClick={onEdit}
            >
              <MdEdit />
            </Button>
          ) : null}
          {data.status === 1 ? (
            <Button color="white" size="icon" onClick={() => onArchive(data)}>
              <MdArchive className="h-color--secondary" />
            </Button>
          ) : (
            <Button
              data-tip="Reativar"
              color="white"
              size="icon"
              onClick={() => onArchive(data)}
            >
              <MdUnarchive className="h-color--secondary" />
            </Button>
          )}
          <Button color="white" size="icon" onClick={() => onDelete(data)}>
            <MdDelete className="h-color--danger" />
          </Button>
        </S.Buttons>
        <S.Spacing />
        {data.status === 1 ? (
          <S.Box>
            <p>Ativo até</p>
            <p>
              <strong>{data.expiry_date}</strong>
            </p>
          </S.Box>
        ) : null}
      </S.BoxActions>
    </S.Wrapper>
  );
}
