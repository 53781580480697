import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import {
  Image,
  Person,
} from 'pages/Site/pages/ContentSite/pages/SuperHighlights/components/SuperhighlightList/styles';
import Button from 'components/Button';
import styled from 'styled-components';
import { DragIcon } from 'pages/Site/pages/ContentSite/pages/Posts/components/TablePosts/styles';
import ReactToggle from 'react-toggle';
// import { Wrapper } from './styles';

const Handle = styled.div`
  cursor: grab;
  margin-top: -5px;
  margin-right: 4px;
`;

const Tr = styled.tr`
  td:first-child {
    padding-left: 15px !important;
  }

  td:last-child {
    padding-right: 15px !important;
  }

  td {
    padding: 10px 20px;
  }
`;

const WrapInfos = styled.div`
  display: flex;
  align-items: center;
`;

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <DragIcon />
  </Handle>
));

function TableRow({ data, handleChangeStatus, update, handleRemove }) {
  return (
    <Tr key={data.id + data.file_url}>
      <td>
        <WrapInfos>
          <RowHandler />
          <Person className="h-text-nowrap">
            <Image size="small" src={data.file_url} />
            <span>{data.person_name}</span>
          </Person>
        </WrapInfos>
      </td>
      <td width={300}>
        <div className="h-flex h-flex--center-center">
          <ReactToggle
            checked={data.is_published}
            icons={false}
            style={{ fontSize: 11 }}
            className="h-margin-right--5"
            onChange={handleChangeStatus(data)}
          />
          <div style={{ width: 100, textAlign: 'center' }}>
            {data.is_published ? 'Publicado' : 'Não publicado'}
          </div>
        </div>
      </td>
      <td className="Table__cell--small">
        <Button onClick={update(data)} color="white" colorText="primary">
          Editar
        </Button>
        <Button onClick={handleRemove(data)} color="white" colorText="danger">
          Remover
        </Button>
      </td>
    </Tr>
  );
}

export default TableRow;
