import React, { useEffect } from 'react';
import { usePix } from './hooks/usePix';
import { Screens } from 'features/Payments';
import { ModalClose } from 'components/Modal';
import { useModalContractExtra } from 'containers/ModalRoot/ModalContractExtra/contexts/modalContractExtra';
import { useModalUser } from 'containers/ModalRoot/ModalContractExtra/pages/Integrations/contexts/ModalUserContext';
import { removeCurrency } from 'lib/money-helpers';

const PaymentPixComponent = () => {
  const { item, handleClose } = useModalContractExtra();
  const { setPage } = useModalUser();

  const { code, contractResponse, isLoading, contract, creditId } = usePix({
    item,
    installments: 1,
    quantity: 1,
  });

  useEffect(() => {
    contract();
  }, []);

  return (
    <div className="Modal__wrapper">
      <ModalClose handleClose={handleClose} />
      <Screens.PaymentPix
        isLoading={isLoading}
        pixCode={code}
        creditId={creditId}
        gatewayName={contractResponse?.data?.gateway_name}
        netValue={removeCurrency(contractResponse?.data?.price)}
        onBack={() => setPage('payment')}
      />
    </div>
  );
};

export default PaymentPixComponent;
