import React, { useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { Row } from 'react-flexbox-grid';
// Components
import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
// Containers
import { NeighborhoodField } from 'containers/LocationFields';
// Validations
import validate from './validate';
// Hooks;
import { plural } from 'lib/text';

function FormNeighborhood({
  neighborhood,
  options,
  handleClose,
  isSubmitting,
  isPristine,
  handleSubmit,
}) {
  // properties,people,search_profiles
  const counts = useMemo(() => {
    let acc = [];

    if (neighborhood?.properties_count) {
      acc = [
        ...acc,
        plural(neighborhood?.properties_count, 'imóvel', 'imóveis'),
      ];
    }

    if (neighborhood?.people_count) {
      acc = [...acc, plural(neighborhood?.people_count, 'cliente', 'clientes')];
    }

    if (neighborhood?.search_profiles_count) {
      acc = [...acc, plural(neighborhood?.people_count, 'perfil', 'perfis')];
    }

    if (neighborhood?.offices_count) {
      acc = [
        ...acc,
        plural(
          neighborhood?.offices_count,
          'dados de contato',
          'dados de contato'
        ),
      ];
    }

    return acc;
  }, [neighborhood]);

  return (
    <form onSubmit={handleSubmit}>
      <p className="h-color--primary">Esse bairro está sendo utilizado em:</p>
      <ul style={{ listStylePosition: 'inside', margin: '10px 0' }}>
        {counts?.map((reason, i) => (
          <li key={`reason-${i}`}>{reason}</li>
        ))}
      </ul>
      <p className="h-color--primary" style={{ marginBottom: '15px' }}>
        Escolha para qual bairro transferi-lo:
      </p>
      <Row style={{ marginBottom: '-15px' }}>
        <NeighborhoodField
          xs={12}
          label="Transferir para"
          name="recipient_id"
          cityId={neighborhood?.city?.id}
        />
      </Row>

      <ModalFooter>
        <Button onClick={handleClose} color="white" colorText="secondary">
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button
          type="submit"
          color="success"
          disabled={isSubmitting || isPristine}
        >
          Salvar
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({
  form: 'FormNeighborhood',
  enableReinitialize: true,
  validate,
})(FormNeighborhood);
