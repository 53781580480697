export default (values) => {
  const errors = {};
  const REQUIRED_MSG = 'Campo obrigatório';

  if (!values.stage_id) {
    errors.stage_id = REQUIRED_MSG;
  }

  return errors;
};
