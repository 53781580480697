import Box from 'components/Box';
import Pagination from 'components/Pagination';
import TableCredits from 'pages/PlanHistory/components/TableCredits';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
// Modules
import { hideLoading, showLoading } from 'modules/loading';
import {
  openModalBankSlip,
  openModalBankSlipSuccess,
  openModalPayment,
  openModalPaymentSuccess,
} from 'modules/modal';
// Services
import { onContractWithBankSlip } from 'modules/plan';
import * as creditsService from 'services/financial/credits';
import * as transactionsService from 'services/financial/transactions';
import { PAYMENT_METHODS } from 'constants/constants';

class CreditsContainer extends React.Component {
  state = {
    data: [],
    isFetching: false,
    limit: 10,
    offset: 1,
    meta: {
      total: 0,
      per_page: 0,
      current_page: 0,
      total_pages: 0,
    },
  };

  componentDidMount() {
    const { limit, offset } = this.state;

    this.fetchData({
      limit,
      offset,
    });
  }

  fetchData = async (params) => {
    const { openModalPayment } = this.props;

    this.setState({ isFetching: true });

    try {
      const {
        data,
        meta: { pagination },
      } = await creditsService.getAll(params);

      if (data.length === 0) {
        openModalPayment();
        return false;
      }

      this.setState({ data, meta: { ...pagination } });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  /**
   * Lida com o evento de mudança de página
   * @param {number} selected - retorna a pagina selecionada no momento começando de 0
   */
  onPageChange = ({ selected }) => {
    const { limit } = this.state;
    this.fetchData({ limit, offset: selected + 1 });
  };

  onClickDelete = (id) => async () => {
    const { limit, offset } = this.state;
    await creditsService.remove(id);
    this.fetchData({ limit, offset });
  };

  get renderPagination() {
    const {
      meta: { total_pages },
    } = this.state;

    return (
      <Pagination pageCount={total_pages} onPageChange={this.onPageChange} />
    );
  }

  onPaySuccess = () => {
    const { limit, offset } = this.state;

    this.fetchData({
      limit,
      offset,
    });
  };

  onClickGenerateBill =
    ({ receiving_method, credit_id }) =>
    async () => {
      const {
        showLoading,
        hideLoading,
        openModalBankSlipSuccess,
        openModalBankSlip,
        onContractWithBankSlip,
      } = this.props;

      showLoading();

      try {
        const {
          data: { boleto_url },
        } = await transactionsService.contractWithBankSlip({
          receiving_method,
          credit_id,
        });

        // Abre a modal e abre a url
        openModalPaymentSuccess({
          receivingMethod: PAYMENT_METHODS.BOLETO,
          bankSlipUrl: boleto_url,
        });

        // Boleto URL
        window.open(boleto_url, '_blank');
      } catch (e) {
        openModalBankSlip({
          credit_id,
          onSubmitSuccess: (response) => {
            onContractWithBankSlip(response);
          },
        });
      } finally {
        hideLoading();
      }
    };

  render() {
    const { data, limit, isFetching } = this.state;

    return (
      <Box.item spacing={30} style={{ width: 690, paddingBottom: 0 }}>
        <h4 className="h-margin-bottom--15">Créditos</h4>
        <TableCredits
          limit={limit}
          isLoading={isFetching}
          data={data}
          onClickDelete={this.onClickDelete}
          onPaySuccess={this.onPaySuccess}
          onClickGenerateBill={this.onClickGenerateBill}
        />
        {this.renderPagination}
      </Box.item>
    );
  }
}

const mapDispatchToProps = {
  openModalBankSlipSuccess,
  showLoading,
  hideLoading,
  openModalBankSlip,
  onContractWithBankSlip,
  openModalPayment,
};

//export default withRouter(CreditsContainer);
export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(CreditsContainer);
