import styled, { css } from 'styled-components';

export const Form = styled.div`
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 311px;
  margin: 0 auto;
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
`;

export const Price = styled.strong`
  display: block;
  font-size: 24px;
  margin: 30px 0 10px;
  text-align: center;
  font-weight: 500;
`;

export const ImgPix = styled.img`
  display: block;
  margin: 0 auto;
`;

export const BoxQrcode = styled.div`
  width: 178px;
  height: 178px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #939598;
  margin: 7px auto 25px;

  p {
    font-size: 10px;
    color: #666666;
    margin-top: 15px;
    text-align: center;
  }
`;

export const QrcodeHint = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #32bcad;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  margin-top: 8px;
`;

export const Input = styled.input`
  border: 0 none;
  padding: 0 10px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  outline: none;
`;

export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  padding: 5px 25px;
  font-size: 12px;
  color: #fff;
  background: #32bcad;
  border-radius: 8px 0 0 8px;
  height: 32px;
  border: 0 none;
  flex: 1 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #289b90;
  }
`;

export const WrapBillet = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px;

  ${(p) =>
    !p.withDiscount &&
    css`
      justify-content: center;
    `}
`;

export const Link = styled.div`
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #0063c0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #005cb2;
  }
`;

export const Installments = styled.div`
  color: #939598;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;

  .head {
    margin-top: 20px;
    line-height: 24px;

    .text-installments {
      line-height: 24px;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  .text {
    max-width: 230px;
    margin: 10px auto 20px;
  }

  ${Price} {
    color: #000;
    margin: 0;
    display: inline;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  color: #666666;
`;
