import { PAYMENT_METHODS } from 'constants/constants';
import { Col } from 'react-flexbox-grid';
import { Field } from 'redux-form';
import { SectionHeader, Step } from 'containers/ModalRoot/ModalPayment/styles';
import PaymentMethod from 'containers/ModalRoot/ModalPayment/pages/Payment/components/PaymentMethod';
import IconPix from 'containers/ModalRoot/ModalPayment/pages/Payment/components/PaymentMethod/IconPix';
import {
  PaymentMethodForm,
  PaymentMethods,
} from 'containers/ModalRoot/ModalPayment/pages/Payment/styles';
import { ModalTemplate } from 'components/Modal';
import { Form } from 'features/Payments';
import useFormValue from 'hooks/useFormValue';
import { PLANS } from 'containers/ModalRoot/ModalCreditsChatGpt/pages/SelectCredits';

const Payment = ({ goTo, onSuccess }) => {
  const plan = useFormValue('plan');
  const currentPlan = PLANS[plan];

  return (
    <ModalTemplate
      title="Pagamento"
      text="Escolha a forma de pagamento"
      titleActions={
        <Step>
          Etapa
          <br />
          2/2
        </Step>
      }
    >
      <SectionHeader>Forma de pagamento</SectionHeader>

      <PaymentMethods>
        <Col xs={4} style={{ marginLeft: '-5px' }}>
          <Field
            name="receiving_method"
            label="Pix"
            component={PaymentMethod}
            icon={IconPix}
            props={{ value: PAYMENT_METHODS.PIX }}
          />
        </Col>
      </PaymentMethods>

      <PaymentMethodForm>
        <Form.Pix
          maxInstallments={1}
          totalCost={currentPlan.newPrice}
          onBack={goTo('SelectCredits')}
          onFinishPayment={goTo('PaymentPix')}
        />
        {/*<Form.Pix />*/}
        {/*<Pix goTo={goTo} onSuccess={onSuccess} />*/}
      </PaymentMethodForm>
    </ModalTemplate>
  );
};

export default Payment;
