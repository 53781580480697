import { MdInfo } from 'react-icons/md';
import * as S from 'containers/ModalRoot/ModalPerson/components/SearchProfile/components/Profiles/components/Item/styles';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import TextOverflow from 'components/TextOverflow';
import Search from 'containers/ModalRoot/ModalPerson/components/SearchProfile/components/Search';

export function Address({ data }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  return (
    <S.Box>
      <p>Localização</p>

      {data?.addresses?.map((addr) => {
        const neighborhoodsCount = addr?.neighborhoods?.length || 0;

        const neighborhoodsTooltip = addr?.neighborhoods
          ?.map((neigh) => neigh.name)
          .join('<br/>');

        return (
          <p>
            <strong>
              <TextOverflow width={235} style={{ verticalAlign: 'middle' }}>
                {addr?.city?.state?.acronym} {addr?.city?.name}{' '}
                {addr?.neighborhoods?.[0]?.name}
              </TextOverflow>
            </strong>
            {neighborhoodsCount > 1 && (
              <MdInfo
                className="h-margin-left--5"
                data-tip={neighborhoodsTooltip}
              />
            )}
            {data.status === 1 ? (
              <Search data={data} cityId={addr.city_id} />
            ) : null}
          </p>
        );
      })}
    </S.Box>
  );
}
