import { Inner, Wrapper } from './styles';
import Loading from 'components/Loading';

export function Content({ isFetching, children }) {
  return (
    <Wrapper className="main-content">
      <Loading isVisible={isFetching} isFullScreen />
      <Inner className="inner-content">{children}</Inner>
    </Wrapper>
  );
}
