import styled from 'styled-components';
import { Wrapper as WrapperLabel } from 'components/Label/styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 39px);
  padding: 10px 20px;
  background: #f5f5f6;
  margin: -20px -19px 30px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  margin-right: 10px;
`;
export const Labels = styled.div`
  display: flex;

  ${WrapperLabel} {
    margin-left: 5px;
  }
`;
export const Label = styled.div`
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  &.is-enabled {
    background: #eff6ff;
    border: 1px solid #5f6470;
  }

  &.is-disabled {
    background: #ffffff;
    border: 1px solid #d3dceb;
  }
`;
