import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
// import DealsList from 'components/Pipeline/components/DealsList';
import {
  ColLeft,
  Content,
  Header,
  HeaderInner,
  Link,
  Text,
  Title,
  Wrapper,
} from './styles';
import DealsList from '../DealsList';
import Icon from '../Icon';
import { plural } from 'lib/text';
import { loadMore } from 'modules/pipeline';
import { useDispatch } from 'react-redux';
import { useFormUserId } from 'pages/Crm/components/FormUser';
import { CollumnActions } from '../CollumnActions';

function Collumn({
  stage,
  length,
  index,
  onSortInteraction,
  onMoveAll,
  onLossAllDeals,
}) {
  const dispatch = useDispatch();
  const deals = stage.deals_list;
  const pagination = stage?.pagination;
  const hasMore = pagination?.current_page < pagination?.total_pages;
  const priceSum = stage?.meta?.price_sum;
  const userId = useFormUserId();

  const zIndex = 10 + length - index;

  return (
    <Wrapper>
      <Header style={{ zIndex: zIndex }}>
        {index > 0 && <Icon position="left" />}
        <HeaderInner>
          <ColLeft>
            <Title>{stage.name}</Title>
            <Text title={`${priceSum}`}>
              {!pagination?.total || pagination?.total === 0
                ? 'Nenhum cliente'
                : plural(pagination?.total, 'cliente', 'clientes')}{' '}
              | {priceSum}
            </Text>
          </ColLeft>
          <CollumnActions
            stage={stage}
            onSortInteraction={onSortInteraction}
            onMoveAll={onMoveAll}
            onLossAllDeals={onLossAllDeals}
          />
        </HeaderInner>

        <Icon position="right" />
      </Header>
      <Droppable droppableId={stage.id} type="PERSON">
        {(provided, snapshot) => (
          <Content
            ref={provided.innerRef}
            className={classnames('Pipeline__content', {
              'is-dragging-over': snapshot.isDraggingOver,
            })}
            {...provided.droppableProps}
          >
            <DealsList deals={deals} stageId={stage.id} />

            {provided.placeholder}
            {hasMore && (
              <Link
                href="#"
                onClick={() =>
                  dispatch(
                    loadMore(stage.id, {
                      user_id: userId,
                    })
                  )
                }
              >
                Ver mais
              </Link>
            )}
          </Content>
        )}
      </Droppable>
    </Wrapper>
  );
}

export default Collumn;
