import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
// Modules
import { fetchToggleFeatured } from 'modules/properties';
import {
  openModalCondoProperties,
  openModalProperty,
  openModalPropertyGroup,
} from 'modules/modal';
// Components
import CardCondominium from 'components/CardCondominium';
import CardProperty from 'components/CardProperty';
// Constants
import { PROPERTY_SHAPE } from 'constants/shapes';

class Card extends Component {
  static defaultProps = {
    type: 'vertical',
  };

  static propTypes = {
    property: PROPERTY_SHAPE.isRequired,
    openModalProperty: PropTypes.func.isRequired,
    toggleFeaturedProperty: PropTypes.func,
    handleDeleteProperty: PropTypes.func,
    type: PropTypes.oneOf(['vertical', 'horizontal']),
  };

  componentDidMount() {
    Tooltip.rebuild();
  }

  handleClickProperty = (property) => (e) => {
    e.preventDefault();

    if (this.props.onClick) this.props.onClick(property);

    /**
     * Só abre a modal de imóveis agrupados
     * se tiver mais de um imóvel no condomínio
     */
    if (!!property.is_grouped_condo) {
      let filter = this.props.filter || {};

      delete filter?.count;
      if (filter.by_neighborhood_or_zone_id) {
        filter.neighborhood_id = filter.by_neighborhood_or_zone_id;
        delete filter.by_neighborhood_or_zone_id;
      }

      if (property.network_type === 'Órulo') {
        filter = {
          on_network: ['orulo'],
          ...filter,
        };
      }

      this.props.openModalCondoProperties({
        condoId: property?.condominium?.id,
        filter,
        selectedKey: this.props.selectedKey,
      });
    } else {
      this.props.openModalProperty({
        property,
        onDelete: this.props.handleDeleteProperty,
      });
    }
  };

  handleClickChecked = (e) => {
    e.stopPropagation();
    const { property, toggleSelectedProperty } = this.props;
    toggleSelectedProperty(property.id);
  };

  handleClickFeatured = (e) => {
    e.stopPropagation();
    const { property, fetchToggleFeatured } = this.props;
    fetchToggleFeatured(property);
  };

  renderCard() {
    const { property, type, handleOnDelete, toggleSelectedProperty } =
      this.props;

    if (property.is_grouped_condo) {
      return (
        <CardCondominium
          type={type}
          property={property}
          handleOnDelete={handleOnDelete}
          handleClickProperty={this.handleClickProperty(property)}
        />
      );
    }

    return (
      <CardProperty
        type={type}
        property={property}
        handleClickProperty={this.handleClickProperty(property)}
        handleOnDelete={handleOnDelete}
        handleClickChecked={toggleSelectedProperty && this.handleClickChecked}
        handleClickFeatured={this.handleClickFeatured}
      />
    );
  }

  render() {
    return this.renderCard();
  }
}

const mapDispatchToProps = {
  openModalProperty,
  openModalPropertyGroup,
  openModalCondoProperties,
  fetchToggleFeatured,
};

export default connect(null, mapDispatchToProps)(Card);
