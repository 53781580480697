// import * as S from './styles'

import * as S from 'containers/ModalRoot/ModalPerson/components/SearchProfile/components/Profiles/components/Item/styles';
import {
  MdBathtub,
  MdCropFree,
  MdDirectionsCar,
  MdKingBed,
} from 'react-icons/md';
import { useMemo } from 'react';

export function Rooms({ data }) {
  const primaryArea = useMemo(() => {
    let text = '';

    if (data.primary_area) {
      const { title, maximum, minimum, measure } = data.primary_area;

      if (minimum) {
        text += maximum ? '' : 'Min ';
        text += `${minimum}${measure}`;
      }

      if (maximum) {
        text += minimum ? ' ~ ' : 'Máx ';
        text += `${maximum}${measure}`;
      }
    }

    return text;
  }, [data]);

  return (
    <S.BoxRooms>
      {data.rooms.bedroom && (
        <S.Room data-tip="Dormitórios">
          <S.Icon>
            <MdKingBed />
          </S.Icon>
          <strong>{data.rooms.bedroom.value}+</strong>
        </S.Room>
      )}
      {data.rooms.bathroom && (
        <S.Room data-tip="Banheiros">
          <S.Icon>
            <MdBathtub />
          </S.Icon>
          <strong>{data.rooms.bathroom.value}+</strong>
        </S.Room>
      )}
      {data.rooms.garage && (
        <S.Room data-tip="Garagens">
          <S.Icon>
            <MdDirectionsCar />
          </S.Icon>
          <strong>{data.rooms.garage.value}+</strong>
        </S.Room>
      )}
      {data.primary_area && (
        <S.Room data-tip={data.primary_area.title}>
          <S.Icon>
            <MdCropFree />
          </S.Icon>
          <strong>{primaryArea}</strong>
        </S.Room>
      )}
    </S.BoxRooms>
  );
}
