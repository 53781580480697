// import * as S from './styles'

import successIcon from '../../success.svg';
import { Content } from '../../styles';

export function Boleto({ contractResponse, bankSlipUrl }) {
  console.log(contractResponse);
  return (
    <Content>
      <img src={successIcon} alt="Sucesso" />

      <h2>Sucesso</h2>

      <p className="text">Obrigado pela parceria!</p>

      {contractResponse?.data?.installments > 1 ? (
        <p className="payment-method-text">
          Encaminhamos o primeiro boleto e instruções de pagamento ao seu e-mail
          e WhatsApp
        </p>
      ) : null}
      {contractResponse?.data?.gateway_name ? (
        <p className="payment-method-text">
          {contractResponse?.data?.gateway_name}
        </p>
      ) : null}
    </Content>
  );
}
