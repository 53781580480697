import { ModalContent, ModalTitle } from 'components/Modal';
import { PAYMENT_METHODS } from 'constants/constants';
import useFormValue from 'hooks/useFormValue';
import { MdCalendarViewDay, MdCreditCard } from 'react-icons/md';
import { Field } from 'redux-form';
import { SectionHeader, Step } from '../../styles';
import PaymentMethod from './components/PaymentMethod';
import IconPix from './components/PaymentMethod/IconPix';
import CreditCard from './pages/CreditCard';
import PaymentSlip from './pages/PaymentSlip';
import Pix from './pages/Pix';
import { PaymentMethodForm, PaymentMethods } from './styles';
import { useModalPayment } from 'containers/ModalRoot/ModalPayment/contexts/payment';

const Payment = () => {
  const { values } = useModalPayment();
  const receiving_method = useFormValue('receiving_method');

  function renderPaymentMethodForm() {
    switch (receiving_method) {
      case PAYMENT_METHODS.CREDIT_CARD:
        return <CreditCard />;
      case PAYMENT_METHODS.BOLETO:
        return <PaymentSlip />;
      case PAYMENT_METHODS.PIX:
        return <Pix />;
      default:
        return <CreditCard />;
    }
  }

  return (
    <>
      <ModalTitle
        text="Escolha a forma de pagamento"
        actions={
          <Step>
            Etapa
            <br />
            3/3
          </Step>
        }
      >
        Pagamento
      </ModalTitle>

      <ModalContent>
        <SectionHeader>Forma de pagamento</SectionHeader>

        <PaymentMethods>
          <Field
            name="receiving_method"
            label={`Pix ${values?.months > 1 ? `Parcelado` : ''}`}
            component={PaymentMethod}
            icon={IconPix}
            props={{ value: PAYMENT_METHODS.PIX }}
          />

          <Field
            name="receiving_method"
            label="Boleto"
            component={PaymentMethod}
            icon={MdCalendarViewDay}
            props={{ value: PAYMENT_METHODS.BOLETO }}
          />

          <Field
            name="receiving_method"
            label="Cartão Crédito"
            component={PaymentMethod}
            icon={MdCreditCard}
            props={{ value: PAYMENT_METHODS.CREDIT_CARD }}
          />
        </PaymentMethods>

        <PaymentMethodForm>{renderPaymentMethodForm()}</PaymentMethodForm>
      </ModalContent>
    </>
  );
};

export default Payment;
