import React from 'react';
import { compose } from 'redux';
// Components
import Wrapper from 'components/Wrapper';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
// Routes
import Routes from './Routes';
import { withPermission } from 'HOC/withPermission';
import { useLocation } from 'react-router';
import BoxHelp from 'components/BoxHelp';

function Domains() {
  const location = useLocation();

  const isPreRegister =
    location.pathname === '/site/config/domains/pre-register';

  return (
    <Wrapper.container>
      {!isPreRegister ? (
        <MainTitle
          title="Domínio"
          text="É essencial para melhorar sua posição no Google, além de ajudar na divulgação e memorização do site."
        >
          <Button color="secondary" to="/site/config/domains/pre-register">
            Adicionar domínio
          </Button>
        </MainTitle>
      ) : (
        <div style={{ height: '40px' }} />
      )}
      <BoxHelp
        storageKey="portals"
        topics={[
          {
            text: 'Registrar um domínio',
            videoUrl: 'https://www.youtube.com/watch?v=G9fY4WYtOFE',
            link: 'https://scribehow.com/shared/Registrar_dominio__Q4A5aEh9TlWZa0WFriiawg',
          },
          {
            text: 'Trazer um domínio existente para o Tecimob',
            videoUrl: 'https://www.youtube.com/watch?v=XJOVhQ9VVuY',
            link: 'https://scribehow.com/shared/Adicionar_dominio__HnfKZyvRQWKvVlYU-56RWQ',
          },
          {
            text: 'Configurar domínio no Registro.br',
            videoUrl: 'https://www.youtube.com/watch?v=4WfAt3vA3uA',
            link: 'https://scribehow.com/shared/Configurar_dominio_no_Registrobr__44x-yoQWTISH7cR40ltz1g',
          },
        ]}
        helpLink="https://tecimob.com.br/ajuda/dominio-2/"
      />
      <Routes />
    </Wrapper.container>
  );
}

export default compose(
  withPermission({
    rules: ['EDIT_DOMAIN'],
    isPage: true,
  })
)(Domains);
