import React, { useCallback, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { openModalAddNeighborhood } from 'modules/modal';
import { COUNTRY_ID } from 'constants/constants';
import { useDispatch } from 'react-redux';
import NeighborhoodOrZone from 'containers/NeighborhoodOrZone';
// import { Container } from './styles';

const NeighborhoodSelect = ({ name, cityId, values }) => {
  const dispatch = useDispatch();
  const [refreshFlag, setRefreshFlag] = useState(0);

  const handleOpenModalAddNeighborhood = useCallback(
    (values) => () => {
      dispatch(
        openModalAddNeighborhood({
          initialValues: {
            country_id: COUNTRY_ID,
            state_id: values.state_id,
            city_id: values.city_id,
          },
          onAddNeighborhood: async (res) => {
            setRefreshFlag((prev) => prev + 1);
          },
        })
      );
    },
    []
  );

  return (
    <>
      {!!cityId && (
        <Row>
          <NeighborhoodOrZone
            key={refreshFlag}
            xs={6}
            label={
              <>
                Bairros{' '}
                <span
                  onClick={handleOpenModalAddNeighborhood(values)}
                  className="h-link"
                >
                  (Cadastrar novo)
                </span>
              </>
            }
            cityId={cityId}
            name={`${name}.neighborhoods_id`}
          />
        </Row>
      )}
    </>
  );
};

export default NeighborhoodSelect;
