import styled from 'styled-components';

export const Wrapper = styled.div`
  .Modal__header {
    margin-bottom: 0;
  }

  .Modal__content {
    position: relative;
  }
`;

export const Content = styled.div`
  padding: 140px 0;
  text-align: center;
  margin: 0 auto;

  h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  img {
    margin: 0 auto;
  }

  p.text {
    font-size: 24px;
    font-weight: 300;
  }

  p.payment-method-text {
    margin-top: 10px;
    font-style: italic;
  }
`;
