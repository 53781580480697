/**
 * Remove a mascara de dinheiro de uma string
 * @param string - String que vai ser retirada a mascara
 * @returns {number}
 */
export const removeCurrency = (string) => {
  if (!string) return 0;

  const sanitizedString = string.replace(/[^\d-]/g, '');
  const isNegative = sanitizedString.includes('-');
  const sanitizedNumber =
    parseFloat(sanitizedString.replace('-', '').replace(',', '.')) / 100;

  return isNegative ? -sanitizedNumber : sanitizedNumber;
};

/**
 * Adiciona a máscara de dinheiro no número sem R$ e sem centavos
 * @param number - Número que vai ser formatado
 * @returns {string}
 */
export const addCurrency = (number) =>
  Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'decimal',
  }).format(number);

/**
 * Adiciona a máscara de dinheiro no número sem R$ e com centavos
 * @param number - Número que vai ser formatado
 * @returns {string}
 */
export const addCurrencyWithCents = (number) =>
  Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  }).format(number);
