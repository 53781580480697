import * as S from './styles';

export function PreviewDate({ startAt, endAt }) {
  if (startAt === endAt) {
    return <S.Wrapper>{startAt}</S.Wrapper>;
  }

  return (
    <S.Wrapper>
      {startAt} - {endAt}
    </S.Wrapper>
  );
}
