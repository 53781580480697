import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import * as crmService from 'services/settings/crm';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
// Components
import Button from 'components/Button';
import FixedBar from 'components/FixedBar';
import {
  Input,
  RadioButton,
  SelectBox,
  SelectMultiple as Select,
} from 'components/Form';
import ListOptions from 'components/ListOptions';
// Helpers
import { minMax, parseSelect } from 'lib/formHelpers';
// Validate
import validate from './validate';
import React, { useEffect, useRef, useState } from 'react';
import ContactsSection from '../ContactsSection';
import { MdInfo } from 'react-icons/md';
import GroupControl, { GroupItem } from 'components/GroupControl';
import useFormValue from 'hooks/useFormValue';

const defaultProps = {};
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const InfoOption3 = () => {
  return (
    <MdInfo
      data-tip={`O último corretor a receber contato será o responsável pelo cliente,
          <br />
          se for usar esta opção, recomendamos liberar todas as permissões
          <br />
          no menu gerenciamento de permissões, nas configurações de usuários`}
    />
  );
};

const ConfigCrmForm = ({
  has_time_max,
  handleSubmit,
  realtors,
  isFetching,
  isFetchingRealtor,
  sale_fields,
  rental_fields,
  initialize,
  initialValues,
  ...props
}) => {
  const [dataContactsRedirect, setDataContactsRedirect] = useState([]);
  const isMounted = useRef(true);
  const inputConfig = useFormValue('config');

  const emptySelect = (funnel, opcao) => () => {
    props.change(`${funnel}.${opcao}_realtor_id`, null);
  };

  const handleChangeSelectRealtors = (formSection) => () => {
    props.change(
      `${formSection}.client_realtors_id`,
      realtors.map(({ id }) => id)
    );
  };

  const toString = (value) => {
    if (typeof value === 'boolean') return value.toString();
    if (!value) return '';
    return value.toString();
  };

  const dataRequest = async () => {
    const { data } = await crmService.getRedirectContacts();
    if (isMounted.current) {
      setDataContactsRedirect(data);
    }
  };

  useEffect(() => {
    dataRequest();

    Tooltip.rebuild();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="sales">
        <ListOptions noSpacingTitle title="Imóveis com transação de VENDA">
          <ListOptions.item title="Novos clientes que fizerem contato através do site devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo imóvel contactado"
                name="site_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('sales', 'site')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="site_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                format={toString}
                onChange={emptySelect('sales', 'site')}
              />
              {sale_fields?.sales?.site_realtor_option &&
                sale_fields?.sales?.site_realtor_option.toString() === '2' && (
                  <Field
                    multi={false}
                    name="site_realtor_id"
                    component={Select}
                    options={realtors}
                    valueKey="id"
                    labelKey="name"
                    isLoading={isFetchingRealtor}
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
              <Field
                label="Executar rodízio entre TODOS os corretores"
                name="site_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('sales', 'site')}
                format={toString}
              />
              <Field
                label="Executar rodízio entre corretores SELECIONADOS"
                name="site_realtor_option"
                value="4"
                type="radio"
                component={RadioButton}
                onChange={handleChangeSelectRealtors('sales')}
                format={toString}
              />
              {sale_fields?.sales?.site_realtor_option === 4 ||
              sale_fields?.sales?.site_realtor_option === '4' ? (
                <div className="h-margin-top--10">
                  <Field
                    minOptions={1}
                    name="rotation_select_users_id"
                    component={SelectBox}
                    options={realtors}
                    isLoading={isFetchingRealtor}
                    valueKey="id"
                    labelKey="name"
                    style={{ width: 250 }}
                  />
                </div>
              ) : null}
            </div>
          </ListOptions.item>
          <ListOptions.item title="Contatos recebidos através do site, onde o cliente JÁ POSSUI corretor responsável, devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo cliente"
                name="client_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('sales', 'client')}
                format={toString}
              />
              <Field
                label={
                  <>
                    Para o corretor responsável pelo imóvel contactado{' '}
                    <InfoOption3 />
                  </>
                }
                name="client_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('sales', 'client')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="client_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('sales', 'client')}
                format={toString}
              />
              {sale_fields?.sales?.client_realtor_option &&
                sale_fields?.sales?.client_realtor_option.toString() ===
                  '2' && (
                  <Field
                    multi={false}
                    name="client_realtor_id"
                    component={Select}
                    options={realtors}
                    isLoading={isFetchingRealtor}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
            </div>
          </ListOptions.item>
        </ListOptions>
      </FormSection>

      <FormSection name="rental">
        <ListOptions
          noSpacingTitle
          title="Imóveis com transação de LOCAÇÃO"
          className="ListOptions h-margin-top--15"
        >
          <ListOptions.item title="Novos clientes que fizerem contato através do site devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo imóvel contactado"
                name="site_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'site')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="site_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                format={toString}
                onChange={emptySelect('rental', 'site')}
              />
              {rental_fields?.rental?.site_realtor_option &&
                rental_fields?.rental?.site_realtor_option?.toString() ===
                  '2' && (
                  <Field
                    multi={false}
                    name="site_realtor_id"
                    component={Select}
                    options={realtors}
                    valueKey="id"
                    labelKey="name"
                    isLoading={isFetchingRealtor}
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
              <Field
                label="Executar rodízio entre TODOS os corretores"
                name="site_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'site')}
                format={toString}
              />
              <Field
                label="Executar rodízio entre corretores SELECIONADOS"
                name="site_realtor_option"
                value="4"
                type="radio"
                component={RadioButton}
                onChange={handleChangeSelectRealtors('rental')}
                format={toString}
              />
              {rental_fields?.rental?.site_realtor_option === 4 ||
              rental_fields?.rental?.site_realtor_option === '4' ? (
                <Field
                  minOptions={1}
                  name="rotation_select_users_id"
                  component={SelectBox}
                  options={realtors}
                  isLoading={isFetchingRealtor}
                  valueKey="id"
                  labelKey="name"
                  style={{ width: 250 }}
                />
              ) : null}
            </div>
          </ListOptions.item>
          <ListOptions.item title="Contatos recebidos através do site, onde o cliente JÁ POSSUI corretor responsável, devem ser encaminhados para:">
            <div className="h-margin-top--10">
              <Field
                label="Para o corretor responsável pelo cliente"
                name="client_realtor_option"
                value="1"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'client')}
                format={toString}
              />
              <Field
                label={
                  <>
                    Para o corretor responsável pelo imóvel contactado{' '}
                    <InfoOption3 />
                  </>
                }
                name="client_realtor_option"
                value="3"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'client')}
                format={toString}
              />
              <Field
                label="Um corretor específico"
                name="client_realtor_option"
                value="2"
                type="radio"
                component={RadioButton}
                onChange={emptySelect('rental', 'client')}
                format={toString}
              />
              {rental_fields?.rental?.client_realtor_option &&
                rental_fields?.rental?.client_realtor_option.toString() ===
                  '2' && (
                  <Field
                    multi={false}
                    name="client_realtor_id"
                    component={Select}
                    options={realtors}
                    isLoading={isFetchingRealtor}
                    valueKey="id"
                    labelKey="name"
                    parse={parseSelect('id')}
                    style={{
                      width: '230px',
                      margin: '10px 0',
                    }}
                  />
                )}
            </div>
          </ListOptions.item>
        </ListOptions>
      </FormSection>

      <ListOptions
        noSpacingTitle
        title="Configurações"
        className="ListOptions h-margin-top--15"
      >
        <ListOptions.item
          title="O corretor responsável pelo cliente deve ser desassociado após um período sem interação?"
          text="Ao atingir o prazo, o corretor será automaticamente desassociado do cliente"
        >
          <div className="h-margin-top--10">
            <Field
              label="Não"
              name="config.should_disassociate_people_user"
              value="false"
              type="radio"
              component={RadioButton}
              format={toString}
            />
            <Field
              label="Sim, após"
              name="config.should_disassociate_people_user"
              value="true"
              type="radio"
              component={RadioButton}
              format={toString}
            />
            <div className="h-margin-top--15" style={{ maxWidth: 200 }}>
              <GroupControl required={false}>
                <GroupItem>
                  <Field
                    type="number"
                    disabled={
                      !inputConfig?.should_disassociate_people_user ||
                      inputConfig?.should_disassociate_people_user?.toString?.() ===
                        'false'
                    }
                    name="config.disassociate_people_user_in"
                    component={Input}
                    parse={minMax(1, 365)}
                  />
                </GroupItem>
                <GroupItem label="dias" size="small" />
              </GroupControl>
            </div>
          </div>
        </ListOptions.item>
      </ListOptions>

      <FixedBar style={{ left: 242 }}>
        <span className="h-flex__cell--grow" />
        <Button disabled={isFetching} type="submit" color="success">
          Salvar
        </Button>
      </FixedBar>

      <ContactsSection
        fetchData={dataContactsRedirect}
        onSubmit={crmService.createContactRedirect}
        onSaveForm={() => dataRequest()}
      />
    </form>
  );
};

ConfigCrmForm.defaultProps = defaultProps;
ConfigCrmForm.propTypes = propTypes;

const selector = formValueSelector('ConfigCrmFormOwn');

const mapStateToProps = (state) => ({
  has_time_max: selector(state, 'has_time_max'),
  sale_fields: selector(
    state,
    'sales.site_realtor_option',
    'sales.client_realtor_option',
    'sales.client_realtor_time'
  ),
  rental_fields: selector(
    state,
    'rental.site_realtor_option',
    'rental.client_realtor_option',
    'rental.client_realtor_time'
  ),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ConfigCrmFormOwn',
    enableReinitialize: true,
    initialValues: {
      site_realtor_option: '1',
      site_realtor_id: null,
      client_realtor_option: '1',
      client_realtor_id: null,
      is_changing_realtor: 'false',
      change_realtor_days: null,
      change_realtor_id: null,
    },
    validate,
  })(ConfigCrmForm)
);
