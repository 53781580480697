import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { contractChange } from 'services/financialv2/contracts';
import { PAYMENT_METHODS } from 'constants/constants';

export const usePix = ({ item, installments, quantity }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [code, setCode] = useState(null);
  const [contractResponse, setContractResponse] = useState(null);
  const [generatedCreditId, setGeneratedCreditId] = useState(null);

  /**
   * Contrata com pix
   * @return {Promise<void>}
   */
  const contract = async () => {
    // if (process.env.NODE_ENV === 'development') {
    //   setCode(pixMock.data.pix_qr_code);
    //   setGeneratedCreditId(pixMock.data.credit_id);
    //   return;
    // }

    try {
      setIsLoading(true);

      const { response } = await contractChange({
        item,
        receiving_method: PAYMENT_METHODS.PIX,
        installments,
        quantity,
      });
      const credit_id = response.data.credit_id;
      const pix_qr_code = response.data.pix_qr_code;

      setGeneratedCreditId(credit_id);
      setCode(pix_qr_code);
      setContractResponse(response);
    } catch (err) {
      console.error('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      QRCode.toDataURL(code, { width: 140, margin: 0 }, function (_, url) {
        setImageUrl(url);
      });
    }
  }, [code]);

  return {
    isLoading,
    contractResponse,
    code,
    imageUrl,
    contract,
    creditId: generatedCreditId,
  };
};
