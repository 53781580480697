// import * as S from './styles'

import { useEffect } from 'react';
import useReduxForm from 'hooks/useReduxForm';
import moment, { format } from 'lib/moment';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import { Field } from 'redux-form';
import { Checkbox, Input, InputDate } from 'components/Form';
import { Row } from 'react-flexbox-grid';
import { max } from 'containers/ModalRoot/ModalPayment/pages/Payment/pages/PaymentSlip';
import useFormValue from 'hooks/useFormValue';
import { useDispatch } from 'react-redux';

const maxConsultantInstallments = (value) =>
  value && value > 6 ? 'Número máximo de parcelas é 6' : undefined;

export function ConsultantForm() {
  const dispatch = useDispatch();
  const { change } = useReduxForm();
  const valuesModal = useFormValue(['type_plan', 'months']);

  console.log('valuesModal', valuesModal);

  const consultantId = localStorage.getItem('@tecimob/consultant_id');

  useEffect(() => {
    dispatch(change('initial_date', moment().format(format.date)));
    dispatch(change('consultant_installments', null));
  }, []);

  if (!consultantId) return null;

  return (
    <>
      <SectionHeader
        style={{
          background: 'red',
          color: '#fff',
          marginBottom: '10px',
          marginTop: '15px',
        }}
      >
        Área do consultor
      </SectionHeader>
      <Field
        type="checkbox"
        name="consultant_should_not_sent"
        label="Não enviar dados da compra nem boleto para o cliente (Será editado manualmente)"
        component={Checkbox}
      />
      <Row>
        <Field
          xs={4}
          name="consultant_due_date"
          label="Data inicial do pagamento"
          component={InputDate}
        />
        <Field
          disabled={
            valuesModal.months === 1 ||
            valuesModal.type_plan === 'monthly' ||
            valuesModal.type_plan === 'monthly_with_deployment'
          }
          xs={3}
          type="number"
          name="consultant_installments"
          label="Número de parcelas"
          component={Input}
          validate={[maxConsultantInstallments]}
          normalize={max(6)}
        />
      </Row>
    </>
  );
}
