import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import { SectionHeader } from 'containers/ModalRoot/ModalPayment/styles';
import useReduxForm from 'hooks/useReduxForm';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { Field, stopSubmit } from 'redux-form';
import { Input } from 'components/Form';
import { Form } from 'containers/ModalRoot/ModalPayment/pages/Payment/styles';
import { normalizeCnpj, normalizeCpf, normalizeNumbers } from 'lib/formHelpers';
import { useRequest } from 'hooks/useRequest';
import * as bankSlipService from 'services/financial/bankSlip';
import FieldInstallments from './components/FieldInstallments';
import { removeCurrency } from 'lib/money-helpers';
import { usePixValues } from '../../hooks/usePixValues';
import { Form as FormPayment } from 'features/Payments';
import { useDispatch } from 'react-redux';

const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Campo obrigatório';

const FormPix = ({
  hasConsultantArea = false,
  maxInstallments: _maxInstallments,
  totalCost,
  values,
  plan,
  prices,
  onBack,
  onFinishPayment,
}) => {
  const { form } = useReduxForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { data: bankSlip } = useRequest({
    request: bankSlipService.getOne,
  });

  const { name, cpf_cnpj } = usePixValues();

  function handleChangeCpfCnpj(value) {
    const normalizedValue = normalizeNumbers(value);
    return normalizedValue.length > 11
      ? normalizeCnpj(normalizedValue)
      : normalizeCpf(normalizedValue);
  }

  const maxInstallments = useMemo(() => {
    if (_maxInstallments) return _maxInstallments;

    if (values.months === 3) {
      return prices.bs_max_installments_3m;
    }

    if (values.months === 6) {
      return prices.bs_max_installments_6m;
    }

    if (values.months === 12) {
      return prices.bs_max_installments_12m;
    }

    return false;
  }, [prices, values]);

  const { initialize, getValues } = useReduxForm();

  const handleFinishPayment = async () => {
    try {
      setIsLoading(true);

      const response = await bankSlipService.create({
        name,
        cpf_cnpj,
      });

      if (onFinishPayment) onFinishPayment(response);
    } catch (error) {
      dispatch(stopSubmit(form, error.errors));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const values = getValues();

    const init = function () {
      initialize({
        ...values,
        ...bankSlip,
        installments: maxInstallments ? maxInstallments : 1,
      });
    };

    init();
  }, [maxInstallments, bankSlip]);

  return (
    <>
      <SectionHeader>Dados do Pix</SectionHeader>

      <Form>
        <Row>
          <Field
            xs={8}
            label="Nome do pagador"
            name="name"
            component={Input}
            validate={[required]}
          />
          <Field
            xs={4}
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            component={Input}
            validate={[required]}
            normalize={handleChangeCpfCnpj}
          />
        </Row>
        <Row>
          <FieldInstallments
            typeInstallment="billet"
            totalCost={removeCurrency(totalCost)}
            maxInstallments={_maxInstallments}
            plan={plan}
            values={values || {}}
            xs={12}
          />
        </Row>
        {maxInstallments && maxInstallments > 1 ? (
          <>
            <p>
              <strong>Como funciona o PIX parcelado?</strong>
            </p>
            <p>
              Na próxima tela, você poderá pagar a 1ª parcela. Para as demais,
              será enviado um link de pagamento por WhatsApp 5 dias antes do
              vencimento.
            </p>
          </>
        ) : null}
        {hasConsultantArea ? <FormPayment.Consultant /> : null}
      </Form>

      <ModalFooter>
        <Button color="white" colorText="primary" onClick={onBack}>
          Voltar
        </Button>
        <div className="h-flex__cell--grow" />
        <Button
          disabled={isLoading}
          type="button"
          onClick={handleFinishPayment}
          color="success"
        >
          Finalizar Pagamento
        </Button>
      </ModalFooter>
    </>
  );
};

export default FormPix;
