/**
 * todo Negocios
 */
import { responseMessage } from '../lib/service-helpers';
import api from './index';
import Alert from 'react-s-alert';
import axios from 'axios';
import _ from 'lodash';

export const STATUS = {
  OPENED: 0,
  GAIN: 1,
  LOSS: 2,
};

export const getStageById = (id, params) =>
  api.getOne(`crm/stages`, id, params);

/**
 * Pega todos os funil que existem para imobiliária
 * @param params
 */
export const getFunnels = (params) =>
  api.getList('crm/funnels', {
    sort: '',
    ...params,
  });

/**
 * Pega um funil especifico
 * @param funnelId
 * @param params
 */
export const getFunnel = (funnelId, params) =>
  api.getOne('crm/funnels', funnelId, params);

/**
 * Cria um novo funil para imobiliária
 * @param funnel
 */
export const createFunnel = (funnel) =>
  api
    .create('crm/funnels', funnel)
    .then(responseMessage(`Funil ${funnel.name} adicionado`))
    .catch(responseMessage('Erro ao criar funil'));

/**
 * Altera um funil
 * @param values
 */
export const updateFunnel = (values) =>
  api
    .update('crm/funnels', values)
    .then(responseMessage('Funil alterado'))
    .catch(responseMessage('Erro ao alterar o funil'));

/**
 * Deleta um funil
 * @param funnelId
 */
export const deleteFunnel = (funnelId) =>
  api
    .delete(`crm/funnels/${funnelId}`)
    .then(responseMessage('Funil removido'))
    .catch(responseMessage('Erro ao remover funil'));

// Crie um token de cancelamento para cada solicitação
let cancelTokenSource = axios.CancelToken.source();

/**
 * Pega todos os estágios de um funil especifico
 * @param funnelId - id do funil
 * @param params - outros parametros como include, filter ou sort
 * @param config
 */

export const getStages = (funnelId, params, config) => {
  try {
    // Cancelar a solicitação anterior (se existir)
    cancelTokenSource.cancel('Solicitação anterior cancelada');

    // Criar um novo token de cancelamento para a nova solicitação
    const novoCancelTokenSource = axios.CancelToken.source();
    cancelTokenSource = novoCancelTokenSource;

    return api.getList(
      `crm/stages`,
      {
        sort: '',
        ...params,
        filter: {
          funnel_id: funnelId,
          ...params?.filter,
        },
      },
      {
        cancelToken: novoCancelTokenSource.token,
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Solicitação cancelada:', err.message);
    } else {
      console.error('Erro na solicitação:', err.message);
    }
  }
};

/**
 * Adiciona uma etapa no funil
 * @param values
 */
export const createStage = (values) => api.create(`crm/stages`, values);

/**
 * Atualiza um estágio
 * @param values
 */
export const updateStage = (values) => api.update(`crm/stages`, values);

/**
 * Atualiza a posicao de um negocio
 * @param {string} funnelId
 * @param {string} stageId
 * @param {string} order
 * @param order
 */
export const updatePositionStage = (funnelId, stageId, order) =>
  updateStage({ id: stageId, order: order }).then(
    responseMessage('Posição da etapa alterada')
  );

/**
 * Remove uma etapa
 * @param stageId
 */
export const deleteStage = (stageId) => api.delete(`crm/stages/${stageId}`);

/**
 * Retorna a lista de negócios já com o property incluido
 * @param funnelId
 */
export const getDeals = (params) => api.getList(`crm/deals`, params);

/**
 * Atualiza um negócio
 * @param stageId
 * @param dealId
 * @param values
 */
export const updateDeal = (stageId, dealId, values) =>
  api.update(`/crm/deals/${dealId}`, values);

/**
 * Transfere um negócio para outra etapa
 * @param dealId
 * @param toStageId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const transferDeal = (dealId, toStageId) =>
  updateDeal(null, dealId, {
    stage_id: toStageId,
  });

/**
 * Ganha o negócio
 * @param stageId
 * @param dealId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const gainDeal = (stageId, dealId) =>
  updateDeal(stageId, dealId, { status: STATUS.GAIN }).then(
    responseMessage('Negócio ganho')
  );

/**
 * Remove um negocio do funil
 * @param {String} dealId
 */
export const removeDeal = (dealId) =>
  api.delete(`crm/deals/${dealId}`).then(responseMessage('Negócio excluído'));

/**
 * Atualiza a posicao de um negocio
 * @param stageId
 * @param destinyStageId
 * @param dealId
 * @param order
 * @param person
 */
export const updatePositionDeal = (
  stageId,
  destinyStageId,
  dealId,
  order,
  person
) => {
  // Pega todos os deals_id
  const deals_id = person?.deals?.map((deal) => deal.id);

  if (deals_id.length > 1) {
    return massUpdate({
      deals_id,
      stage_id: stageId,
      people_id: person.id,
      order: order + 1,
    });
  }

  return updateDeal(stageId, dealId, {
    stage_id: destinyStageId,
    order: order + 1,
  });
};

/**
 * Move os negócios de uma etapa pra outra
 * @param currentStageId
 * @param destinationStageId
 */
export const moveDeals = (currentStageId, destinationStageId) => {
  return api.create(`crm/stages/${currentStageId}/move`, {
    stage_id: destinationStageId,
  });
};

export const removeStage = async (currentStageId, destinationId, params) => {
  try {
    // Move primeiro depois apaga
    await moveDeals(currentStageId, destinationId);

    return api.delete(`crm/stages`, currentStageId, { params });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Marca um negócio como ganho
 * @param values
 */
export const dealEarn = (values) => {
  if (values.id) {
    return api
      .update(`crm/deals/earneds`, values)
      .then(responseMessage('Negócio Atualizado'));
  }

  return api
    .create(`crm/deals/earneds`, values)
    .then(responseMessage('Negócio ganho'));
};

/**
 * Marca um negócio como ganho
 * @param dealId
 * @param values
 */
export const dealLoss = (dealId, values) => {
  if (_.isArray(dealId)) {
    return api
      .create(`crm/deals/mass-losses`, {
        deals: dealId,
        ...values,
      })
      .then(responseMessage(`${dealId.length} negócios perdidos`));
  }
  return api
    .create(`crm/deals/${dealId}/losses`, values)
    .then(responseMessage('Negócio perdido'));
};

/**
 * Pega os motivos
 */
export const getLossReasons = (params) =>
  api.getList('crm/loss-reasons', params);

/**
 * Pega os motivos
 */
export const getLossReasonById = (id, params) =>
  api.getOne('crm/loss-reasons', id, params);

/**
 * Pega todos os motívos de perca que são cadastrados pelo cliente
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getLossReasonsCustom = () =>
  getLossReasons({ with_defaults: false, count: 'lost_deals' });

/**
 * Remove um motívo de perca
 * @param id
 */
export const removeLossReasonById = (id) =>
  api
    .delete('crm/loss-reasons', id)
    .then(responseMessage('Motivo da perda removido'));

export const transferLossReason = (fromId, toId) =>
  api.create(`crm/loss-reasons/${fromId}/transfers/${toId}`);

export const transferAndRemove = async (fromId, toId) => {
  try {
    // Transfere o que tem no fromId para o toId
    await transferLossReason(fromId, toId);

    // Depois deleta o motivo
    await removeLossReasonById(fromId);
  } catch (e) {
    Alert.success('Erro ao remover motívo de perda');
  }
};

/**
 * Atualiza um motívo de perda
 * @param reason
 */
export const updateLossReason = (reason) =>
  api
    .update('crm/loss-reasons', reason)
    .then(responseMessage('Motivo da perda atualizado'));

/**
 * Busca por um negócio específico
 * @param stageId
 * @param dealId
 * @param params
 */
export const getOne = (stageId, dealId, params) =>
  api.getOne(`crm/stages/${stageId}/deals/${dealId}`, params);

/**
 * Pega todos os eventos de um negócio
 * @param dealId
 */
export const getDealEvents = (dealId, params) =>
  api.getList(`crm/deals/${dealId}/calendars`, params);

/**
 * Cria um novo evento para o negócio
 * @param dealId
 * @param values
 */
export const createDealEvent = (dealId, values) =>
  api.create(`crm/deals/${dealId}/calendars`, values);

/**
 * Busca por dados de um negócio ganho
 * @param dealId
 * @param params
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getDealEarned = (dealId, params) =>
  api.getOne('crm/deals/earneds', dealId, params);

/**
 Lista os negocios ganhos do usuario
 @param params
 */
export const getDealsOfUser = (params) =>
  api.getList('crm/deals/earneds', params);

/**
 * Atualiza um negócio ganho
 * @param params - os valores que devem ser atualizados
 * @return {*}
 */
export const updateDealEarned = (values) =>
  api.update('crm/deals/earneds', values);

/**
 * Remover um negócio ganho
 * @param dealId
 * @return {Promise<AxiosResponse<any>>}
 */
export const removeDealEarned = (dealId) =>
  api.delete(`crm/deals/earneds`, dealId);

/**
 * Filtro de contratos de locacao vencendo
 * @param params
 * @return {*}
 */
export const getDealsByExpiringRentalContracts = (params) =>
  getDealsOfUser({
    ...params,
    include: 'property',
    filter: { by_expiring_rental_contracts: true },
  });

/**
 * Cadastra/Remove e Atualiza um Negócio do imóvel
 * @param params
 * @param {String} params.stage_id - se vir nulo remove o negocio do crm
 * @param {String} params.deal_id - id do negócio
 * @param {String} params.property_id - id do imóvel
 * @param {String} params.people_id - id do cliente
 * @returns {Promise}
 */
export const storeOrUpdateDeals = (params) =>
  api.create('crm/deals/store-update-delete', params);

/**
 * Busca o histórico de um negócio
 * @param {String} dealId - id do negócio
 * @param {Object} params
 * @returns {*|Promise<AxiosResponse<T>>}
 */
export const getDealAudits = (dealId, params = {}) =>
  api.getList(`crm/deals/${dealId}/audits`, {
    sort: 'created_at',
    filter: {
      event: ['deal_stage_updated', 'created'],
      ...params?.filter,
    },
    ...params,
  });

/**
 * Descarta um matched da lista
 * @param {String} matchedId
 * @returns { Promise }
 */
export const discardFromInteresteds = (matchedId) =>
  api
    .delete(`people/matcheds/${matchedId}/discard-from-interesteds`, '')
    .then(responseMessage('Imóvel removido do CRM'))
    .catch(responseMessage('Erro ao remover do CRM'));

/**
 * Pega todos os negócios
 * @param params
 */
export const getAllDeals = (params, config) => {
  // Remove a referência das variaveis
  const _params = JSON.parse(JSON.stringify(params));

  // Verifica se tem alguma ordenação vinda do filtro
  // se não tiver ordena por data de atualização
  const sort = _params?.filter?.sort || 'created_at';

  delete _params?.filter?.sort;

  // Verifica se precisa filtrar por calendario
  const hasCalendars = _params?.filter?.has_calendars;
  delete _params?.filter?.has_calendars;

  // Clausula having
  let having = ['stage'];

  if (hasCalendars) {
    having = [...having, 'calendars'];
  }

  return api.getList(
    'crm/deals',
    {
      ..._params,
      filter: {
        ..._params?.filter,
        having,
      },
      with_prices_sum: true,
      count: 'calendars',
      include: 'property,stage',
      sort,
    },
    config
  );
};

export const massUpdate = ({ deals_id, stage_id, people_id, order = 1 }) => {
  return api.update('crm/deals/mass-update', {
    deals_id,
    stage_id,
    people_id,
    order,
  });
};

export const getStagesByUserId = ({ user_id, ...params }) =>
  api.get('crm/stages', {
    ...params,
    filter: {
      ...params.filter,
      by_user_id: user_id,
    },
  });

export const getStagesByFunnelId = ({ funnel_id, ...params }) =>
  api.get('crm/stages', {
    ...params,
    filter: {
      ...params.filter,
      funnel_id: funnel_id,
    },
    count: 'crms,default_properties',
  });

export const transferDealsStage = ({ stage_origin, stage_destination }) => {
  return api
    .create(`crm/stages/${stage_origin}/move`, {
      stage_id: stage_destination,
    })
    .then(responseMessage('Negócios transferidos'));
};

export const getAllStages = () => {
  return getFunnels({
    include: 'stages',
  }).then((res) => {
    res.data = res.data.map((group) => ({
      name: group.name,
      options: group.stages.map((stage) => ({
        stage_id: stage.id,
        name: stage.name,
        group: group,
      })),
    }));

    return res;
  });
};

export const defaultStage = () => {
  return api.get('settings/crm/default-stage');
};

export const updateDefaultStage = ({ rental, sales }) => {
  return api.update('settings/crm/default-stage', {
    rental: {
      default_stage_id: rental,
    },
    sales: {
      default_stage_id: sales,
    },
  });
};

export const stageLost = ({ loss_reason_id, stage_id, user_id, ...values }) => {
  return api.create('crm/deals/stage-loss', {
    loss_reason_id: loss_reason_id,
    stage_id,
    user_id,
    ...values,
  });
};
