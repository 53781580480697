import React from 'react';
import Layout from 'pages/Crm/components/Layout';
import Pipeline from 'components/Pipeline';
import { useDeals } from './hooks/useDeals';
import Loading from 'components/Loading';
import { useLayout } from 'pages/PortalsOptionSelect/hooks/useLayout';
import { useSelector } from 'react-redux';
import { countsSelector } from 'pages/Crm/modules/asideCrm';
import { plural } from 'lib/text';
import Filter from './components/Filter';
import { useCount } from './hooks/useCount';

function Deals() {
  const { countPeople, countProperties } = useCount();

  const { data } = useSelector(countsSelector);
  const { layout, setLayout } = useLayout('fixed', '@tecimob/layout-pipeline');

  const {
    stages,
    isFetching,
    handleClickAddDeal,
    fetchStagesByUserId,
    handleDragEnd,
    handleSortStage,
    handleMoveAll,
    handleLossAllDeals,
  } = useDeals();

  return (
    <Layout
      title={
        <>
          <strong>Negócios em andamento</strong> |{' '}
          <strong>Visão em etapas</strong> |{' '}
          {plural(countProperties.count, 'imóvel', 'imóveis')} |{' '}
          {plural(countPeople.count, 'cliente', 'clientes')}
        </>
      }
      renderActions={() => (
        <Filter
          layout={layout}
          setLayout={setLayout}
          handleClickAddDeal={handleClickAddDeal}
        />
      )}
      renderContent={() => (
        <>
          <Pipeline
            layout={layout}
            stages={stages}
            handleDragEnd={handleDragEnd}
            onAfterClosePerson={fetchStagesByUserId}
            onLossAllDeals={handleLossAllDeals}
            onMoveAll={handleMoveAll}
            onSortInteraction={handleSortStage}
          />
          <Loading isVisible={isFetching} isFullScreen />
        </>
      )}
    />
  );
}

export default Deals;
