import styled, { css } from 'styled-components';
import { em } from 'polished';

export const Center = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 285px;
  padding-top: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: -10px;
`;
export const Box = styled.div`
  display: inline-block;
  background: #ffffff;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  color: #737886;
  border-radius: 6px;
  margin-bottom: 5px;
`;

export const Inner = styled.div`
  position: relative;
  padding: 15px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 1.3em;
`;

export const Close = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  top: ${em(-10, 14)};
  right: ${em(-10, 14)};
  width: ${em(20, 14)};
  height: ${em(20, 14)};
  font-size: ${(p) => p.size};
  background-color: #fff;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: 50%;

  svg {
    font-size: inherit;
  }
`;
export const WhatsappIcon = styled.div`
  position: relative;
  margin-top: 5px;
  color: #fff;
  border-radius: 50%;
  background: #31d084;
  display: grid;
  place-items: center;
  width: ${em(54, 18)};
  height: ${em(54, 18)};
  font-size: ${(p) => p.size};

  ${(p) =>
    p.image &&
    css`
      background-image: url('${p.image}');
      background-size: cover;
      background-position: center;

      svg {
        display: none;
      }
    `}

  svg {
    width: 25px;
    height: 25px;
  }
`;

export const Notification = styled.div`
  display: grid;
  place-items: center;
  font-size: 10px;
  font-weight: 600;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #f84343;
  outline: 3px solid rgba(248, 67, 67, 0.3);
  position: absolute;
  left: 70%;
  bottom: 75%;
`;
