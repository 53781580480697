import Modal from 'react-modal';
import { ModalTemplate } from 'components/Modal';
import { useForm } from './hooks/useForm';
import { ModalProvider } from './hooks/useModal';
import { duplicateProperties } from 'services/properties';
import Alert from 'react-s-alert';

export default function ModalPropertyDuplicateMulti({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  property,
  onSubmitSuccess,
}) {
  const { Form, initialValues } = useForm({ property });

  const _handleClose = (res) => {
    handleClose();
    if (onSubmitSuccess) onSubmitSuccess(res);
  };

  const onSubmit = (values) => {
    try {
      return duplicateProperties({
        property_id: property.id,
        properties: values.properties,
      });
    } catch (err) {
      throw err;
    }
  };

  // Verifica se pode mostrar a modal
  if (!Form || !initialValues) return null;

  return (
    <ModalProvider property={property}>
      <Modal
        {...modalConfig}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={_handleClose}
      >
        <ModalTemplate title="Duplicar imóvel">
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            onSubmitSuccess={(res) => {
              Alert.success('Imóveis duplicados!');

              _handleClose(res);
            }}
            handleClose={_handleClose}
          />
        </ModalTemplate>
      </Modal>
    </ModalProvider>
  );
}
