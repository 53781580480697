import React from 'react';
import Button from 'components/Button';
import iconCalendar from './icon-popup.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 40px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 57px;
  display: flex;
  justify-content: space-between;

  h4 {
    margin-bottom: 10px;
  }

  button {
    margin-top: 15px;
  }
`;

const Text = styled.div`
  width: 355px;
`;

export function Reception({ onClick }) {
  return (
    <Wrapper className="h-flex">
      <Text>
        <h4>Pop-ups</h4>
        <p>
          Ative e configure pop-ups para exibir mensagens importantes, promoções
          ou chamadas para ação no momento certo.
          <br />
          <br />
          Com apenas alguns cliques, você pode criar pop-ups eficientes que
          capturam a atenção e geram resultados.
        </p>
        <Button type="button" color="success" onClick={onClick}>
          Ativar janela pop-up
        </Button>
      </Text>
      <div>
        <img src={iconCalendar} alt="" />
      </div>
    </Wrapper>
  );
}
