import Pagination from 'components/Pagination';
import PropertiesTable from 'components/PropertiesTable';
import { openModalProperty, openModalShareProperty } from 'modules/modal';
import { clearSearch, typeListSelector } from 'modules/propertySearch';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Header from '../Header';
import PropertiesList from '../PropertiesList';
import { Actions as SelectedsActions } from 'modules/selecteds';
import { addFeaturedListsProperties } from 'services/sites/featuredLists';
import Alert from 'react-s-alert';
import { useEffect, useMemo } from 'react';

function Properties({
  handleSearch,
  data,
  meta,
  params,
  fixedBar: FixedBar,
  barProps,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const typeList = useSelector(typeListSelector);
  const selectedProperties = useSelector(
    (state) => state.selecteds.properties_search2
  );

  const handleClearSearch = () => {
    dispatch(clearSearch(history));
  };

  const unsetSelected = (propertyId) => {
    dispatch(SelectedsActions.unsetSelected('properties_search2', propertyId));
  };

  const resetSelecteds = () => {
    dispatch(SelectedsActions.resetSelecteds('properties_search2'));
  };

  const handlePageChange = ({ selected }) => {
    let currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set('offset', selected + 1);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  const handleClickProperty = (property) => {
    dispatch(openModalProperty({ property }));
  };

  const toggleSelectedProperty = (id) => {
    dispatch(SelectedsActions.toggleSelected('properties_search2', id));
  };

  const handleAddProperties = async () => {
    await addFeaturedListsProperties({ id, property_ids: selectedProperties });
    Alert.success('Imóveis adicionados');
    history.push(`/site/content-site/featured-lists/${id}/properties/`);
    handleClearSearch();
  };

  const fixedBarProps = {
    params: params,
    unsetSelected: unsetSelected,
    resetSelecteds: resetSelecteds,
    properties: selectedProperties,
    handleSearch: handleSearch,
    handleClickRemoveSelected: (property) => {
      unsetSelected(property.id);
    },
    handleUncheckAll: () => {
      resetSelecteds();
    },
    handleClickSendProperties: (type, title, props) => () => {
      dispatch(
        openModalShareProperty({
          title,
          type,
          ...props,
          properties: selectedProperties,
        })
      );
    },
    ...barProps,
  };

  const getAllPropertiesIds = (properties) => {
    return properties.map((property) => property.id);
  };

  const isAllChecked = useMemo(() => {
    const ids = getAllPropertiesIds(data);
    return ids.every((id) => selectedProperties?.includes(id));
  }, [data, selectedProperties]);

  const handleClickCheckAll = () => {
    const ids = getAllPropertiesIds(data);

    if (isAllChecked) {
      dispatch(SelectedsActions.removeAll('properties_search2', ids));
    } else {
      dispatch(SelectedsActions.addAll('properties_search2', ids));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(SelectedsActions.resetSelecteds('properties_search2'));
    };
  }, [dispatch]);

  return (
    <>
      <Header params={params} meta={meta} />
      {typeList === 'card' ? (
        <PropertiesList
          type="vertical"
          properties={data}
          filter={params?.filter}
          onClickProperty={handleClickProperty}
          selectedProperties={selectedProperties}
          toggleSelectedProperty={toggleSelectedProperty}
          rowClassName="propertyList"
          selectedKey="properties_search2"
        />
      ) : (
        <PropertiesTable
          hasCheckbox
          properties={data}
          onClickProperty={handleClickProperty}
          selectedProperties={selectedProperties}
          toggleSelectedProperty={toggleSelectedProperty}
          handleClickCheckAll={handleClickCheckAll}
          selectedKey="properties_search2"
        />
      )}
      {params?.offset && (
        <Pagination
          forcePage={params?.offset - 1}
          pageCount={meta?.pagination?.total_pages}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
        />
      )}
      <FixedBar onAddProperties={handleAddProperties} {...fixedBarProps} />
    </>
  );
}

export default Properties;
