import { MdDragIndicator } from 'react-icons/md';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dedede;

  .nestable-list .nestable-list .nestable-item & {
    border-left: 1px solid #dedede;
  }
`;

export const NoImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: #e5e6ea;

  svg {
    color: #fff;
    font-size: 14px;
  }
`;

export const DragIcon = styled(MdDragIndicator)`
  margin-right: 5px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 15px;
  }
`;

export const TableData = styled.div`
  flex: 1 1 0;
  padding: 10px 20px;
`;

export const ChangeHours = styled.div`
  padding-bottom: 10px;
`;
