import Button from 'components/Button';
import { ModalFooter } from 'components/Modal';
import { Field, reduxForm } from 'redux-form';
// Services
import { Checkbox } from 'components/Form';
import Loading from 'components/Loading';

// import { Container } from './styles';

const Form = ({
  handleSubmit,
  data,
  texts,
  onCancel,
  handleClose,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {submitting && <Loading isVisible isFullScreen />}
      <p>{texts.text}</p>

      <div className="h-margin-top--15">
        <Field
          label="Remover os imóveis compatíveis com esse perfil"
          type="checkbox"
          name="can_remove_properties"
          component={Checkbox}
        />
      </div>

      <ModalFooter>
        <Button
          color="white"
          colorText="secondary"
          type="button"
          onClick={() => {
            handleClose();
            if (onCancel) onCancel();
          }}
        >
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button disabled={submitting} type="submit" color="danger">
          {texts.submitText}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default reduxForm({
  form: 'ModalProfileRemove',
  onSubmitSuccess: (response, dispatch, props) => {
    props.handleClose();

    // Verifica se tem a propriedade onSuccess
    if (props?.onSuccess) props?.onSuccess(response);
  },
})(Form);
