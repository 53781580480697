import Table from 'components/Table';
import { useSelector } from 'react-redux';
import PropertyItem from './components/PropertyItem';

function PropertiesTable({
  properties,
  handleOpenProperty,
  canSelect,
  handleSelectProperty,
  selectedKey,
}) {
  const selecteds = useSelector((state) => {
    return state?.selecteds?.[selectedKey];
  });

  return (
    <div>
      <Table
        data={properties}
        isFetching={false}
        renderTitle={() => (
          <tr>
            {canSelect && <th />}
            <th>Número</th>
            <th>Ref</th>
            <th>Dormitórios</th>
            <th>Garagens</th>
            <th>Área Construída</th>
            <th>Área Privativa</th>
            <th>Área Total</th>
            <th>Transação</th>
            <th colSpan={2}></th>
          </tr>
        )}
        renderItem={(property) => (
          <PropertyItem
            key={property.id}
            handleOpenProperty={handleOpenProperty}
            property={property}
            canSelect={canSelect}
            selecteds={selecteds}
            handleSelectProperty={handleSelectProperty}
          />
        )}
      />
    </div>
  );
}

export default PropertiesTable;
