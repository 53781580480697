// import * as S from './styles'

import successIcon from '../../success.svg';
import { Content } from '../../styles';

export function CreditCard() {
  return (
    <Content>
      <img src={successIcon} alt="Sucesso" />

      <h2>Sucesso</h2>

      <p className="text">Obrigado pela parceria!</p>
    </Content>
  );
}
