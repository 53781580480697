export function forceHex6Digits(hex) {
  try {
    // Remove todos os caracteres que não sejam letras (a-f) ou números (0-9)
    hex = hex.replace(/[^a-fA-F0-9]/g, '');

    // Se o hexadecimal tiver 3 dígitos, expande para 6 dígitos
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (char) {
          return char + char;
        })
        .join('');
    }

    // Garante que o hexadecimal tenha exatamente 6 dígitos
    if (hex.length !== 6) {
      throw new Error('Formato de hexadecimal inválido');
    }

    // Retorna o hexadecimal com o caractere '#'
    return '#' + hex.toLowerCase(); // Converte para minúsculas para padronizar
  } catch {
    return '#0063c0';
  }
}
