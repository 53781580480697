import { addParam } from 'lib/url-helpers';
import { fetchPrices } from 'modules/prices';
import { createModule } from '../lib/reducer-helpers';
import { changePreview1, ITEM_TYPES } from 'services/financialv2/contracts';

const createAction = createModule('modal');

// Modal Types
// const MODAL_CLIENT_ADD = 'ModalPeople';
export const MODALS = {
  ModalAddDomain: 'ModalAddDomain',
  ModalAddEmailAccount: 'ModalAddEmailAccount',
  ModalCharacteristics: 'ModalCharacteristics',
  ModalCreatePage: 'ModalCreatePage',
  ModalDeal: 'ModalDeal',
  ModalDealDelete: 'ModalDealDelete',
  ModalDealSuccess: 'ModalDealSuccess',
  ModalExamplePropertyMail: 'ModalExamplePropertyMail',
  ModalFinancial: 'ModalFinancial',
  ModalFinancialDelete: 'ModalFinancialDelete',
  ModalGallery: 'ModalGallery',
  ModalIndexHistory: 'ModalIndexHistory',
  ModalLocationAdd: 'ModalLocationAdd',
  ModalMenuLink: 'ModalMenuLink',
  ModalOpenDeal: 'ModalOpenDeal',
  ModalIssue: 'ModalIssue',
  ModalPerson: 'ModalPerson',
  ModalPersonAdd: 'ModalPersonAdd',
  ModalPortal: 'ModalPortal',
  ModalProfile: 'ModalProfile',
  ModalProperty: 'ModalProperty',
  ModalPropertyDelete: 'ModalPropertyDelete',
  ModalPropertyDuplicate: 'ModalPropertyDuplicate',
  ModalPropertyExcluded: 'ModalPropertyExcluded',
  ModalPropertyGroup: 'ModalPropertyGroup',
  ModalPropertyPrint: 'ModalPropertyPrint',
  ModalPropertyPublication: 'ModalPropertyPublication',
  ModalPropertyRent: 'ModalPropertyRent',
  ModalPropertySendMail: 'ModalPropertySendMail',
  ModalRegisterDomain: 'ModalRegisterDomain',
  ModalSendMail: 'ModalSendMail',
  ModalShareProperty: 'ModalShareProperty',
  ModalUser: 'ModalUser',
  ModalUserDelete: 'ModalUserDelete',
  ModalUserPassword: 'ModalUserPassword',
  ModalUnityStore: 'ModalUnityStore',
  ModalUserPropertyTransfer: 'ModalUserPropertyTransfer',
  ModalEvent: 'ModalEvent',
  ModalCreci: 'ModalCreci',
  ModalInteraction: 'ModalInteraction',
  ModalDealLoss: 'ModalDealLoss',
  ModalPropertySell: 'ModalPropertySell',
  ModalEmailAlias: 'ModalEmailAlias',
  ModalEmailBoxRedirected: 'ModalEmailBoxRedirected',
  ModalEmailPassword: 'ModalEmailPassword',
  ModalHowToAddDeal: 'ModalHowToAddDeal',
  ModalSupport: 'ModalSupport',
  ModalCreditCard: 'ModalCreditCard',
  ModalCreditCardSuccess: 'ModalCreditCardSuccess',
  ModalBankSlip: 'ModalBankSlip',
  ModalBankSlipSuccess: 'ModalBankSlipSuccess',
  ModalDebitsMonth: 'ModalDebitsMonth',
  ModalDebitsByDay: 'ModalDebitsByDay',
  ModalOrulo: 'ModalOrulo',
  ModalVideo: 'ModalVideo',
  ModalPortalAdd: 'ModalPortalAdd',
  ModalEstablishment: 'ModalEstablishment',
  ModalContract: 'ModalContract',
  ModalExtract: 'ModalExtract',
  ModalDailyDebits: 'ModalDailyDebits',
  ModalHasPayment: 'ModalHasPayment',
  ModalOruloDisconnect: 'ModalOruloDisconnect',
  ModalItinerary: 'ModalItinerary',
  ModalReceptionSource: 'ModalReceptionSource',
  ModalGroup: 'ModalGroup',
  ModalLossReason: 'ModalLossReason',
  ModalPropertyMultipleExcluded: 'ModalPropertyMultipleExcluded',
  ModalPayInstallment: 'ModalPayInstallment',
  ModalLossReasonRemove: 'ModalLossReasonRemove',
  ModalReceptionSourceRemove: 'ModalReceptionSourceRemove',
  ModalPortalProperty: 'ModalPortalProperty',
  ModalPostOptions: 'ModalPostOptions',
  ModalPostLinkStore: 'ModalPostLinkStore',
  ModalNeighborhoodStore: 'ModalNeighborhoodStore',
  ModalNeighborhoodDelete: 'ModalNeighborhoodDelete',
  ModalZoneDelete: 'ModalZoneDelete',
  ModalPostLayout: 'ModalPostLayout',
  ModalRd: 'ModalRd',
  ModalRdConfig: 'ModalRdConfig',
  ModalBankStore: 'ModalBankStore',
  ModalServiceEvaluation: 'ModalServiceEvaluation',
  ModalSuccess: 'ModalSuccess',
  ModalVcard: 'ModalVcard',
  ModalPeopleImport: 'ModalPeopleImport',
  ModalNewFeature: 'ModalNewFeature',
  ModalPropertyCompare: 'ModalPropertyCompare',
  ModalSatisfaction: 'ModalSatisfaction',
  ModalRemoveAccount: 'ModalRemoveAccount',
  ModalAddCity: 'ModalAddCity',
  ModalAddNeighborhood: 'ModalAddNeighborhood',
  ModalAddZone: 'ModalAddZone',
  ModalWatermarkRemove: 'ModalWatermarkRemove',
  ModalPersonRemove: 'ModalPersonRemove',
  ModalPromotionPortal: 'ModalPromotionPortal',
  ModalAddDeal: 'ModalAddDeal',
  ModalGroupRemove: 'ModalGroupRemove',
  ModalAuthorizationSell: 'ModalAuthorizationSell',
  ModalAuthorizationRent: 'ModalAuthorizationRent',
  ModalLogoConfig: 'ModalLogoConfig',
  ModalVisit: 'ModalVisit',
  ModalPayPix: 'ModalPayPix',
  ModalInstallmentOpen: 'ModalInstallmentOpen',
  ModalPropertyBook: 'ModalPropertyBook',
  ModalCrmRedirectContact: 'ModalCrmRedirectContact',
  ModalRecommendation: 'ModalRecommendation',
  ModalAddUserOption: 'ModalAddUserOption',
  ModalInviteUserReception: 'ModalInviteUserReception',
  ModalInviteUser: 'ModalInviteUser',
  ModalCondoDelete: 'ModalCondoDelete',
  ModalCondoProperties: 'ModalCondoProperties',
  ModalBoolean: 'ModalBoolean',
  ModalRecommendationTerms: 'ModalRecommendationTerms',
  ModalZoneStore: 'ModalZoneStore',
  ModalPayment: 'ModalPayment',
  ModalPaymentSuccess: 'ModalPaymentSuccess',
  ModalOruloTerms: 'ModalOruloTerms',
  ModalDwvTerms: 'ModalDwvTerms',
};

// Actions
const OPEN_MODAL = createAction('OPEN');
const CLOSE_MODAL = createAction('CLOSE');

// Initial State
const initialState = {
  modalType: null,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        ...action.payload,
        modalProps: {
          ...action.payload.modalProps,
          isOpen: true,
        },
      };
    }

    case CLOSE_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
}

// Action Creators
export const openModal = ({
  type,
  size = 'medium',
  position = 'top',
  props,
  ...rest
}) => ({
  type: OPEN_MODAL,
  payload: {
    modalType: type,
    modalSize: size,
    modalPosition: position,
    modalProps: { ...props, ...rest },
  },
});

export const closeModal = (handleClose) => ({
  type: CLOSE_MODAL,
  handleClose,
});

// side effects, only as applicable
// e.g. thunks, epics, etc
export const openModalProperty = (props) => (dispatch) => {
  addParam('_openModalProperty', props.property.id);
  return dispatch(openModal({ type: MODALS.ModalProperty, props }));
};

export const openModalPersonAdd =
  (person, callBack, config = { isSimple: false, checkGroupOwner: false }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPersonAdd,
        size: 675,
        props: {
          formName: 'personForm',
          person,
          callBack,
          config,
        },
      })
    );

export const openModalPerson = (person, params) => (dispatch) => {
  addParam('_openModalPerson', person.id);

  return dispatch(
    openModal({ type: MODALS.ModalPerson, props: { person, ...params } })
  );
};

/**
 * Abre a modal de debitos diarios
 * @param isRemoving
 * @param text
 * @param onConfirm
 * @param onCancel
 * @returns {function(...[*]=)}
 */
export const openModalDailyDebits =
  ({ request, isRemoving, text, onConfirm, onCancel }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: MODALS.ModalDailyDebits,
        size: 378,
        props: {
          request,
          isRemoving,
          text,
          onConfirm,
          onCancel,
        },
      })
    );
  };

export const openModalUser = (id, callBack) => (dispatch) => {
  dispatch(
    openModal({
      type: MODALS.ModalUser,
      props: { id, callBack },
    })
  );
};

export const openModalAddUserOption = (callBack) => (dispatch) => {
  dispatch(
    openModal({
      type: MODALS.ModalAddUserOption,
      props: { callBack },
      size: 610,
      position: 'center',
    })
  );
};

export const openModalInviteUserReception = (callBack) => (dispatch) => {
  dispatch(
    openModal({
      type: MODALS.ModalInviteUserReception,
      props: { callBack },
      size: 720,
    })
  );
};

export const openModalInviteUser = (callBack) => (dispatch) => {
  dispatch(
    openModal({
      type: MODALS.ModalInviteUser,
      props: { callBack },
      size: 720,
    })
  );
};

export const openModalUserPassword = (id, callBack) => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalUserPassword, props: { id, callBack } })
  );

export const openModalIndexHistory = (id) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalIndexHistory, props: { id } }));

export const openModalFinancial = (id, callBack) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalFinancial, props: { id, callBack } }));

export const openModalCharacteristics =
  ({ typeId, characteristicId, onSubmitSuccess, onSubmitFail, afterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalCharacteristics,
        props: {
          typeId,
          characteristicId,
          onSubmitSuccess,
          onSubmitFail,
          afterClose,
        },
      })
    );

export const openModalCondominium = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalCondominium, props }));

export const openModalDeal = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalDeal, props }));

export const openModalDealDelete = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalDealDelete, props }));

export const openModalOpenDeal = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalOpenDeal,
      position: 'center',
      size: 350,
      props,
    })
  );

export const openModalPropertyDelete = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalPropertyDelete, props }));

export const openModalPropertyDuplicate = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertyDuplicate,
      position: 'center',
      size: 350,
      props,
    })
  );

export const openModalPropertyPrint = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertyPrint,
      position: 'center',
      size: 520,
      props,
    })
  );

export const openModalPropertySendMail = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertySendMail,
      position: 'center',
      size: 520,
      props,
    })
  );

export const openModalProfile = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalProfile, props }));

export const openModalAddDomain = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalAddDomain,
      position: 'center',
      size: 520,
      props,
    })
  );

export const openModalRegisterDomain = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalRegisterDomain,
      position: 'center',
      size: 458,
      props,
    })
  );

export const openModalPropertyExcluded = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertyExcluded,
      position: 'center',
      size: 370,
      props,
    })
  );

export const openModalExamplePropertyMail = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalExamplePropertyMail,
      position: 'center',
      size: 600,
      props,
    })
  );

const fetchChangePreview = async (item) => {
  const { data } = await changePreview1({ item });
  return data;
};

export const openModalAddEmailAccount = (props) => async (dispatch) => {
  const preview = await fetchChangePreview(ITEM_TYPES.EMAIL);

  const onSuccess = () => {
    dispatch(
      openModal({
        type: MODALS.ModalAddEmailAccount,
        position: 'center',
        size: 520,
        props,
      })
    );
  };

  if (preview?.need_change_contract) {
    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.EMAIL,
        preview,
        onSuccess,
      })
    );
    return false;
  }

  onSuccess();
};

export const openModalSendMail = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalSendMail,
      position: 'center',
      size: 520,
      props,
    })
  );

/**
 * Abre a modal para alugar um imóvel
 * @param {String} props.personId
 * @param {String} props.userId
 * @param {Object} props.property
 * @param {String} props.property.id
 * @param {String} props.property.calculated_price
 * @param {Function} props.onSubmitSuccess
 * @param {Function} props.onSubmitFail
 * @returns {function(*): *}
 */
export const openModalPropertyRent = (props) => (dispatch) =>
  dispatch(openModal({ type: MODALS.ModalPropertyRent, size: 987, props }));

/**
 * Abre a modal para vender um imóvel
 * @param {String} props.property.id
 * @param {String} props.property.calculated_price
 * @returns {function(*): *}
 */
export const openModalPropertySell = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertySell,
      position: 'center',
      size: 987,
      props,
    })
  );

export const openModalDealSuccess = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalDealSuccess,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalPropertyGroup = (props) => (dispatch) =>
  dispatch(openModal({ size: 1200, type: MODALS.ModalPropertyGroup, props }));

export const openModalPropertyPublication = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertyPublication,
      position: 'center',
      size: 800,
      props,
    })
  );

export const openModalCreatePage = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalCreatePage,
      position: 'center',
      size: 610,
      props,
    })
  );

export const openModalMenuLink = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalMenuLink,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalNegotiationPageConfig = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalNegotiationPageConfig',
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalLocationAdd = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalLocationAdd,
      position: 'center',
      size: 460,
      props,
    })
  );

export const openModalPortal = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPortal,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalUserDelete = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalUserDelete,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalUserPropertyTransfer = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalUserPropertyTransfer,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalFinancialDelete = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalFinancialDelete,
      position: 'center',
      size: 516,
      props,
    })
  );

/**
 *
 * @param { "generate-link", "share", "email", "add-person" } type
 * @param props
 * @returns {function(*): *}
 */
export const openModalShareProperty =
  ({ type, ...props }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalShareProperty,
        position: 'center',
        size: type === 'add-person' || type === 'generate-link' ? 516 : 987,
        props: { ...props, type },
      })
    );

/**
 * Abrir a modal de evento
 * @param start
 * @param end
 * @param event
 * @param initialValues
 * @param onSubmitSuccess
 * @param onSubmitFail
 */
export const openModalEvent =
  ({ start, end, event, initialValues, onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalEvent,
        position: 'center',
        size: 516,
        props: {
          start,
          end,
          event,
          initialValues,
          onSubmitSuccess,
          onSubmitFail,
        },
      })
    );

export const openModalCreci = (props) => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalCreci, position: 'center', size: 516, props })
  );

export const openModalGallery = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalGallery,
      position: 'center',
      size: 1032,
      props,
    })
  );

export const openModalIssue = (props) => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalIssue, position: 'center', size: 516, props })
  );

export const openModalInteraction = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalInteraction,
      position: 'center',
      size: 516,
      props,
    })
  );

export const openModalDealLoss = (dealId, props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalDealLoss,
      position: 'center',
      size: 490,
      props: { dealId, ...props },
    })
  );

export const openModalEmailAlias = (email, props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalEmailAlias,
      position: 'center',
      size: 510,
      props: {
        ...props,
        email,
      },
    })
  );

/**
 * Abre modal para cadastrar uma caixa de email redirecionada
 * @param props
 * @returns {function(*): *}
 */
export const openModalEmailBoxRedirected =
  ({ username, onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalEmailBoxRedirected,
        position: 'center',
        size: 510,
        props: {
          username,
          onSubmitSuccess,
          onSubmitFail,
        },
      })
    );

/**
 * Abre a modal para mudar a senha de uma conta e e-mail
 * @param {String} username
 * @param props
 * @returns {function(*): *}
 */
export const openModalEmailPassword = (username, props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalEmailPassword,
      position: 'center',
      size: 510,
      props: {
        ...props,
        username,
      },
    })
  );

export const openModalHowToAddDeal = () => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalHowToAddDeal, position: 'center', size: 490 })
  );

export const openModalSupport = () => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalSupport, position: 'center', size: 490 })
  );

/**
 * Abre a modal de pagamento com cartão
 * @param {string} credit_id
 * @param {number} users
 * @param {number} months
 * @param {function} onSubmitSuccess
 * @param {function} onSubmitFail
 * @returns {function(*): *}
 */
export const openModalCreditCard =
  ({ credit_id, users, months, onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalCreditCard,
        position: 'center',
        size: 795,
        props: { credit_id, users, months, onSubmitSuccess, onSubmitFail },
      })
    );

/**
 * Abre a modal quando deu certo a contratação de um plano
 * via pagamento cartão
 * @return {function(*): *}
 */
export const openModalCreditCardSuccess = () => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalCreditCardSuccess,
      position: 'center',
      size: 375,
    })
  );

/**
 * Abre a modal de
 * @param bank_slip_id
 * @param {string} credit_id
 * @param {number} users
 * @param {number} months
 * @param onSubmitSuccess
 * @param onSubmitFail
 * @return {function(*): *}
 */
export const openModalBankSlip =
  ({ bank_slip_id, credit_id, users, months, onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalBankSlip,
        position: 'center',
        size: 376,
        props: {
          bank_slip_id,
          credit_id,
          users,
          months,
          onSubmitSuccess,
          onSubmitFail,
        },
      })
    );

/**
 * Abre a modal quando deu certo a geração de um boleto
 * via pagamento cartão
 * @param {string} bankSlipUrl
 * @return {function(*): *}
 */
export const openModalBankSlipSuccess =
  ({ bankSlipUrl }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalBankSlipSuccess,
        position: 'center',
        size: 330,
        props: { bankSlipUrl },
      })
    );

/**
 * Abre a modal de debitos de um mês específico
 * @param monthName
 * @param month
 * @param year
 * @return {function(*): *}
 */
export const openModalDebitsMonth =
  ({ monthName, month, year }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalDebitsMonth,
        position: 'center',
        size: 395,
        props: { monthName, month, year },
      })
    );

/**
 * Abre a modal de debitos de um mês específico
 * @param debitId
 * @param date
 * @param onAfterClose
 * @return {function(*): *}
 */
export const openModalDebitsByDay =
  ({ debitId, date, onAfterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalDebitsByDay,
        position: 'center',
        size: 395,
        props: { debitId, date, onAfterClose },
      })
    );

/**
 * Abre a modal para fazer a integração com órulo
 * @param onSubmitSuccess
 * @param onSubmitFail
 * @return {function(*): *}
 */
export const openModalOrulo =
  ({ onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalOrulo,
        position: 'center',
        size: 395,
        props: { onSubmitSuccess, onSubmitFail },
      })
    );

export const openModalOruloTerms =
  ({ onAccept }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalOruloTerms,
        size: 850,
        position: 'center',
        props: { onAccept },
      })
    );

/**
 * Abre a modal de vídeo
 * @param {string} title
 * @param {string} videoUrl
 * @param {string} link
 * @param {object} playerVars
 * @param {function} renderAction
 * @param {function} onEnd
 * @param {function} afterClose
 * @return {function(*): *}
 */
export const openModalVideo =
  ({ title, videoUrl, playerVars, link, renderAction, onEnd, afterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalVideo,
        position: 'center',
        size: 900,
        props: {
          title,
          videoUrl,
          playerVars,
          renderAction,
          onEnd,
          link,
          afterClose,
        },
      })
    );

/**
 * Abre a modal de cadastro de portal
 * @param onSubmitSuccess
 * @param onSubmitFail
 */
export const openModalPortalAdd =
  ({ onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPortalAdd,
        position: 'center',
        size: 516,
        props: { onSubmitSuccess, onSubmitFail },
      })
    );

/**
 * Abre a modal de cadastro de estabelecimento
 * @param establishmentId
 * @param onSubmitSuccess
 * @param onSubmitFail
 * @returns {function(*): *}
 */
export const openModalEstablishment =
  ({ establishmentId, onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalEstablishment,
        position: 'center',
        size: 516,
        props: { establishmentId, onSubmitSuccess, onSubmitFail },
      })
    );

export const openModalContract =
  ({ schedule = false } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalContract,
        position: 'center',
        size: 370,
        props: { schedule },
      })
    );

/**
 * Abre a modal com o extrato de debitos dele
 * @returns {function(*=): *}
 */
export const openModalExtract = () => (dispatch) =>
  dispatch(
    openModal({ type: MODALS.ModalExtract, position: 'center', size: 600 })
  );

export const openModalHasPayment =
  ({ onConfirm }) =>
  (dispatch) =>
    dispatch(openModal({ type: MODALS.ModalHasPayment, props: { onConfirm } }));

export const openModalOruloDisconnect =
  ({ should_change_contract, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalOruloDisconnect,
        position: 'center',
        size: 280,
        props: { should_change_contract, onSubmitSuccess },
      })
    );

export const openModalItinerary =
  ({ propertiesIds }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalItinerary,
        position: 'center',
        size: 900,
        props: { propertiesIds },
      })
    );

export const openModalReceptionSource =
  ({ id, onSubmitSuccess, onSubmitFail, afterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalReceptionSource,
        props: {
          id,
          onSubmitSuccess,
          onSubmitFail,
          afterClose,
        },
      })
    );

export const openModalGroup =
  ({ id, onSubmitSuccess, onSubmitFail, afterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalGroup,
        props: {
          id,
          onSubmitSuccess,
          onSubmitFail,
          afterClose,
        },
      })
    );

export const openModalLossReason =
  ({ id, onSubmitSuccess, onSubmitFail, afterClose }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalLossReason,
        props: {
          id,
          onSubmitSuccess,
          onSubmitFail,
          afterClose,
        },
      })
    );

/**
 * Abre uma modal para excluir multiplos imóveis
 * @param propertiesId
 * @param onSubmitSuccess
 * @returns {function(*): *}
 */
export const openModalPropertyMultipleExcluded =
  ({ propertiesId, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPropertyMultipleExcluded,
        position: 'center',
        size: 400,
        props: {
          propertiesId,
          onSubmitSuccess,
        },
      })
    );

export const openModalPayInstallment =
  ({ receivingMethod, creditId, onSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPayInstallment,
        position: 'center',
        size: 400,
        props: {
          receivingMethod,
          creditId,
          onSuccess,
        },
      })
    );

export const openModalPortalProperty =
  ({
    pagination,
    portalRealEstateId,
    portalName,
    optionName,
    optionId,
    propertiesId,
    filter,
    onSuccess,
  }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPortalProperty,
        position: 'center',
        size: 500,
        props: {
          pagination,
          portalRealEstateId,
          portalName,
          optionName,
          optionId,
          propertiesId,
          filter,
          onSuccess,
        },
      })
    );

export const openModalLossReasonRemove =
  ({ id, lostDealsCount, onSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalLossReasonRemove,
        position: 'center',
        size: 368,
        props: {
          id,
          lostDealsCount,
          onSuccess,
        },
      })
    );

export const openModalReceptionSourceRemove =
  ({ receptionSource, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalReceptionSourceRemove',
        position: 'center',
        size: 368,
        props: {
          receptionSource,
          onSubmitSuccess,
        },
      })
    );

export const openModalPostOptions = () => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPostOptions,
      position: 'center',
      size: 450,
    })
  );

export const openModalPostLinkStore =
  ({ id, onSubmitSuccess, onSubmitFail } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPostLinkStore,
        position: 'center',
        size: 900,
        props: {
          id,
          onSubmitSuccess,
          onSubmitFail,
        },
      })
    );

export const openModalPostLayout = () => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPostLayout,
      position: 'center',
      size: 390,
    })
  );

export const openModalNeighborhoodStore =
  ({ id, initialValues, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalNeighborhoodStore,
        position: 'center',
        size: 390,
        props: {
          id,
          onSubmitSuccess,
          initialValues,
        },
      })
    );

export const openModalZoneStore =
  ({ id, initialValues, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalZoneStore,
        position: 'center',
        size: 390,
        props: {
          id,
          onSubmitSuccess,
          initialValues,
        },
      })
    );

export const openModalCityStore =
  ({ id, state_id, initialValues, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalCityStore',
        position: 'center',
        size: 390,
        props: {
          id,
          state_id,
          initialValues,
          onSubmitSuccess,
        },
      })
    );

export const openModalNeighborhoodDelete =
  ({ id, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalNeighborhoodDelete,
        position: 'center',
        size: 390,
        props: {
          id,
          onSubmitSuccess,
        },
      })
    );

export const openModalZoneDelete =
  ({ id, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalZoneDelete,
        position: 'center',
        size: 390,
        props: {
          id,
          onSubmitSuccess,
        },
      })
    );

export const openModalCityDelete =
  ({ id, state_id, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalCityDelete',
        position: 'center',
        size: 390,
        props: {
          id,
          state_id,
          onSubmitSuccess,
        },
      })
    );

export const openModalBankStore =
  ({ id, onSubmitSuccess } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalBankStore,
        position: 'center',
        size: 516,
        props: {
          id,
          onSubmitSuccess,
        },
      })
    );

export const openModalRd =
  ({ onSubmitSuccess } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalRd,
        position: 'center',
        size: 395,
        props: {
          onSubmitSuccess,
        },
      })
    );

export const openModalRdConfig =
  ({ onSubmitSuccess } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalRdConfig,
        position: 'center',
        size: 425,
        props: {
          onSubmitSuccess,
        },
      })
    );

export const openModalServiceEvaluation =
  ({ ticketId, onSuccess, onFinally } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalServiceEvaluation,
        position: 'center',
        size: 480,
        props: {
          ticketId,
          onSuccess,
          onFinally,
        },
      })
    );

export const openModalPeopleImport =
  ({ onSuccess, onFinally } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPeopleImport,
        position: 'center',
        size: 480,
        props: {
          onSuccess,
          onFinally,
        },
      })
    );

export const openModalVcard =
  ({ user, onFinally } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalVcard,
        position: 'center',
        size: 840,
        props: {
          user,
          onFinally,
        },
      })
    );

export const openModalSuccess =
  ({ title, text }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalSuccess,
        position: 'center',
        size: 320,
        props: {
          title,
          text,
        },
      })
    );

export const openModalNewFeature = () => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalNewFeature,
      position: 'center',
      size: 850,
      props: {},
    })
  );

export const openModalPropertyCompare =
  ({ propertiesIds, handleRemove }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPropertyCompare,
        position: 'center',
        size: 'auto',
        props: {
          propertiesIds,
          handleRemove,
        },
      })
    );

export const openModalPromotionPortal =
  ({
    portalName = null,
    title = 'Pacote Exclusívo',
    image = null,
    text = 'Preencha seus dados abaixo e um consultor do portal irá entrar em contato com você:',
  }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPromotionPortal,
        position: 'center',
        size: 420,
        props: {
          portalName,
          title,
          image,
          text,
        },
      })
    );

export const openModalSatisfaction =
  ({ satisfaction }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalSatisfaction,
        position: 'center',
        size: 420,
        props: {
          satisfaction,
        },
      })
    );

export const openModalRemoveAccount = () => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalRemoveAccount,
      position: 'center',
      size: 800,
    })
  );

export const openModalAddCity =
  ({ initialValues, onClose, onAddCity = null } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalAddCity,
        position: 'center',
        size: 480,
        props: {
          initialValues,
          onClose,
          onAddCity,
        },
      })
    );

export const openModalAddNeighborhood =
  ({ initialValues, onClose, onAddNeighborhood = null } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalAddNeighborhood,
        position: 'center',
        size: 480,
        props: {
          initialValues,
          onClose,
          onAddNeighborhood,
        },
      })
    );

export const openModalAddZone =
  ({ initialValues, onClose, onAddZone = null } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalAddZone,
        position: 'center',
        size: 480,
        props: {
          initialValues,
          onClose,
          onAddZone,
        },
      })
    );

export const openModalWatermarkRemove =
  ({ onSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalWatermarkRemove,
        position: 'center',
        size: 425,
        props: { onSuccess },
      })
    );

export const openModalPersonRemove =
  ({ id, onCancel, onFinally, onSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPersonRemove,
        position: 'center',
        size: 450,
        props: { id, onSuccess, onCancel, onFinally },
      })
    );

export const openModalAddDeal =
  ({ initialValues, onSuccess, userId, funnelId }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalAddDeal,
        position: 'center',
        size: 510,
        props: {
          initialValues,
          onSuccess,
          userId,
          funnelId,
        },
      })
    );

export const openModalVisit = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalVisit,
      position: 'center',
      size: 340,
      props,
    })
  );

export const openModalAuthorizationSell = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalAuthorizationSell,
      position: 'center',
      size: 920,
      props,
    })
  );

export const openModalAuthorizationRent = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalAuthorizationRent,
      position: 'center',
      size: 920,
      props,
    })
  );

export const openModalGroupRemove = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalGroupRemove,
      position: 'center',
      size: 340,
      props,
    })
  );

export const openModalGroupUserRemove = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalGroupUserRemove',
      position: 'center',
      size: 340,
      props,
    })
  );

export const openModalLogoConfig = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalLogoConfig,
      position: 'center',
      size: 455,
      props,
    })
  );

export const openModalPayment = (props) => async (dispatch) => {
  await fetchPrices()(dispatch);

  dispatch(
    openModal({
      type: MODALS.ModalPayment,
      position: 'center',
      size: 630,
      props,
    })
  );
};

export const openModalPaymentSuccess = (props) => async (dispatch) => {
  dispatch(
    openModal({
      type: MODALS.ModalPaymentSuccess,
      size: 630,
      props,
    })
  );
};

export const openModalPayPix =
  ({ credit_id, ...props }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: MODALS.ModalPayPix,
        position: 'center',
        size: 455,
        props: {
          ...props,
          credit_id,
        },
      })
    );

export const openModalInstallmentOpen = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalInstallmentOpen,
      position: 'center',
      size: 455,
      props,
    })
  );

export const openModalPropertyBook = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalPropertyBook,
      position: 'center',
      size: 455,
      props,
    })
  );

export const openModalPropertyEditOrulo = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPropertyEditOrulo',
      position: 'center',
      size: 480,
      props,
    })
  );

export const openModalFacebookLeads = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalFacebookLeads',
      position: 'center',
      size: 720,
      props,
    })
  );

export const openModalFacebookStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalFacebookStore',
      position: 'center',
      size: 720,
      props,
    })
  );

export const openModalPreviewImageUser = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPreviewImageUser',
      position: 'center',
      props,
    })
  );

export const openModalCharacteristicRemove =
  ({ characteristic, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalCharacteristicRemove',
        position: 'center',
        size: 480,
        props: {
          characteristic,
          onSubmitSuccess,
        },
      })
    );

export const openModalEstablishmentRemove =
  ({ establishment, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalEstablishmentRemove',
        position: 'center',
        size: 480,
        props: {
          establishment,
          onSubmitSuccess,
        },
      })
    );

export const openModalTestimonialsStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalTestimonialsStore',
      position: 'center',
      size: 750,
      props,
    })
  );

export const openModalSuperhighlightStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSuperhighlightStore',
      position: 'center',
      size: 750,
      props,
    })
  );

export const openModalSMTPConfig = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSMTPConfig',
      position: 'center',
      size: 510,
      props,
    })
  );

export const openModalSMTPAlert = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSMTPAlert',
      position: 'center',
      size: 510,
      props,
    })
  );

export const openModalCondoDelete = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCondoDelete',
      props,
    })
  );

export const openModalCondoProperties = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCondoProperties',
      props,
    })
  );

export const openModalExtension = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalExtension',
      position: 'center',
      size: 770,
      props,
    })
  );

export const openModalProfileRemove = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalProfileRemove',
      position: 'center',
      size: 510,
      props,
    })
  );

export const openModalWhatsappUserStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalWhatsappUserStore',
      position: 'center',
      size: 510,
      props,
    })
  );

export const openModalCrmRedirectContact = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCrmRedirectContact',
      position: 'center',
      size: 561,
      props,
    })
  );

export const openModalReservationCreate = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalReservationCreate',
      position: 'center',
      size: 561,
      props,
    })
  );

export const openModalReservationClose = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalReservationClose',
      position: 'center',
      size: 561,
      props,
    })
  );

export const openModalReservationUpdate = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalReservationUpdate',
      position: 'center',
      size: 561,
      props,
    })
  );

export const openModalReservationRemove = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalReservationRemove',
      position: 'center',
      size: 330,
      props,
    })
  );

export const openModalRecommendation = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalRecommendation,
      position: 'center',
      size: 830,
      props,
    })
  );

export const openModalRecommendationTerms = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: MODALS.ModalRecommendationTerms,
      props,
    })
  );

export const openModalPriceAdjustment = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPriceAdjustment',
      position: 'center',
      size: 568,
      props,
    })
  );

export const openModalPleno = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPleno',
      position: 'center',
      size: 568,
      props,
    })
  );

export const openModalPropertySearch = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPropertySearch',
      position: 'center',
      size: 1100,
      props,
    })
  );

export const openModalBoolean = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalBoolean',
      position: 'center',
      size: 510,
      props,
    })
  );

export const openModalPortalPropertiesSelect = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPortalPropertiesSelect',
      position: 'center',
      size: 560,
      props,
    })
  );

export const openModalPartnersStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPartnersStore',
      position: 'center',
      size: 750,
      props,
    })
  );

/**
 * Abre a modal para fazer a integração com órulo
 * @param onSubmitSuccess
 * @param onSubmitFail
 * @return {function(*): *}
 */
export const openModalDwv =
  ({ onSubmitSuccess, onSubmitFail }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalDwv',
        position: 'center',
        size: 395,
        props: { onSubmitSuccess, onSubmitFail },
      })
    );

export const openModalDwvTerms =
  ({ onAccept }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalDwvTerms',
        size: 850,
        position: 'center',
        props: { onAccept },
      })
    );

export const openModalDwvDisconnect =
  ({ should_change_contract, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalDwvDisconnect',
        position: 'center',
        size: 280,
        props: { should_change_contract, onSubmitSuccess },
      })
    );

export const openModalAnalyticsInfo = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalAnalyticsInfo',
      position: 'center',
      size: 850,
      props,
    })
  );

export const openModalDealsMoveProperty =
  ({ person, sourceStage, destinationStage, destIndex, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalDealsMoveProperty',
        position: 'center',
        size: 425,
        props: {
          person,
          sourceStage,
          destinationStage,
          destIndex,
          onSubmitSuccess,
        },
      })
    );

export const openModalShare =
  ({ person, properties_id, ...props } = {}) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalShare',
        position: 'center',
        size: 985,
        props: {
          properties_id,
          person,
          ...props,
        },
      })
    );

export const openModalSendPeopleEmail = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSendPeopleEmail',
      position: 'center',
      size: 500,
      props,
    })
  );

export const openModalStageStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalStageStore',
      position: 'center',
      size: 350,
      props,
    })
  );

export const openModalCrmReception = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCrmReception',
      position: 'center',
      size: 990,
      props,
    })
  );

export const openModalSuperlogica = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSuperlogica',
      position: 'center',
      size: 568,
      props,
    })
  );

export const openModalCancelInstallment = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCancelInstallment',
      position: 'center',
      size: props.size ? props.size : 580,
      props,
    })
  );

export const openModalUpdateContractDate = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalUpdateContractDate',
      position: 'center',
      size: 430,
      props,
    })
  );

export const openModalContractExtra = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalContractExtra',
      position: 'center',
      size: 530,
      props,
    })
  );

export const openModalContractRemove = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalContractRemove',
      position: 'center',
      size: 560,
      props,
    })
  );

export const openModalCharacteristicRemoveCondo =
  ({ characteristic, onSubmitSuccess }) =>
  (dispatch) =>
    dispatch(
      openModal({
        type: 'ModalCharacteristicRemoveCondo',
        position: 'center',
        size: 480,
        props: {
          characteristic,
          onSubmitSuccess,
        },
      })
    );

export const openModalChatGpt = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalChatGpt',
      position: 'center',
      size: 585,
      props,
    })
  );

export const openModalCreditsChatGpt = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalCreditsChatGpt',
      position: 'center',
      size: 590,
      props,
    })
  );

export const openModalChatGptNoCredits = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalChatGptNoCredits',
      position: 'center',
      size: 590,
      props,
    })
  );

export const openModalChatGptExtract = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalChatGptExtract',
      position: 'center',
      size: 700,
      props,
    })
  );

export const openModalImport = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalImport',
      position: 'center',
      size: 450,
      props,
    })
  );

export const openModalFunnelStore = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalFunnelStore',
      position: 'center',
      size: 520,
      props,
    })
  );

export const openModalDealTransfer = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalDealTransfer',
      position: 'center',
      size: 450,
      props,
    })
  );

export const openModalSiteTemplate = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalSiteTemplate',
      position: 'center',
      size: 970,
      props,
    })
  );

export const openModalGeneratePost = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalGeneratePost',
      position: 'center',
      size: 945,
      props,
    })
  );

export const openModalPropertiesChangeRealtor = (props) => (dispatch) =>
  dispatch(
    openModal({
      type: 'ModalPropertiesChangeRealtor',
      position: 'center',
      size: 'small',
      props,
    })
  );

export const openModalPropertyDuplicateMulti =
  ({ property, onSubmitSuccess }) =>
  (dispatch) => {
    const type = property.type.title;
    const isOther = type !== 'Apartamento' && type !== 'Casa';
    const sizeModal = isOther ? 612 : 783;

    dispatch(
      openModal({
        type: 'ModalPropertyDuplicateMulti',
        position: 'center',
        size: sizeModal,
        props: {
          property,
          onSubmitSuccess,
        },
      })
    );
  };

export const openModalFeaturedGallery =
  ({ id, onSuccess } = {}) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalFeaturedGallery',
        position: 'center',
        size: 483,
        props: {
          id,
          onSuccess,
        },
      })
    );
  };

export const openModalAlerta = () => (dispatch) => {
  dispatch(
    openModal({
      type: 'ModalAlerta',
      position: 'center',
      size: 465,
    })
  );
};

export const openModalLeadfy =
  ({ onSubmitSuccess }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalLeadfy',
        position: 'center',
        size: 527,
        onSubmitSuccess,
      })
    );
  };

export const openModalNotes =
  ({ title, description }) =>
  (dispatch) => {
    dispatch(
      openModal({
        type: 'ModalNotes',
        position: 'center',
        size: 485,
        props: {
          title,
          description,
        },
      })
    );
  };

export const openModalMoveDeals = (props) => (dispatch) => {
  dispatch(
    openModal({
      type: 'ModalMoveDeals',
      position: 'center',
      size: 485,
      props,
    })
  );
};
