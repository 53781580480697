import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid #e5eff9;
  border-radius: 4px;
  display: flex;
`;
export const BoxLeft = styled.div`
  width: 505px;
  align-self: flex-start;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
`;

export const BoxRooms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-left: 1px solid #e5eff9;
  padding-left: 15px;
  margin-left: 10px;
`;

export const Room = styled.div`
  display: flex;
  align-items: center;

  strong {
    margin-left: 5px;
  }
`;

export const Icon = styled.div`
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border-left: 1px solid #e5eff9;
  background-color: #e5eff8;
  color: #0063c0;
  font-size: 14px;
`;

export const Box = styled.div`
  p {
    font-size: 14px;
    color: #8e8e8e;
    white-space: nowrap;

    + p {
      margin-top: 5px;
    }
  }

  p strong {
    font-size: 14px;
    color: #1c0c1e;
  }
`;

export const Spacing = styled.div`
  flex: 1 0;
`;

export const BoxActions = styled.div`
  align-self: stretch;
  justify-self: flex-end;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Buttons = styled.div``;
