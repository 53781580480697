import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @media print {
    .MainHeader,
    .NavAside,
    .js-header,
    .js-recomende,
    .js-support-chat,
    .js-selected-properties {
      display: none !important;
    }

    .Table {
      filter: none !important;
      border: 0 none !important;
    }
  }
`;
