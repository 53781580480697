// import styles from './styles'
import PropTypes from 'prop-types';
import * as S from './styles';
import { MdChevronLeft } from 'react-icons/all';
import { MdChevronRight } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import moment from 'lib/moment';
import TextLoading from 'components/TextLoading';

function DateSelector({
  isFetching = false,
  type = 'year',
  initialValue,
  onChange,
  onNext,
  onPrev,
  className,
}) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (initialValue) {
      setValue(
        type === 'year'
          ? moment(initialValue, 'YYYY').format('YYYY-MM-DD')
          : moment(initialValue, 'MM/YYYY').format('YYYY-MM-DD')
      );
    }
  }, [initialValue]);

  const handleTransformValue = (value, type) => {
    if (type === 'year') {
      return `Ano ${moment(value).format('YYYY')}`;
    }

    return moment(value).format('MMMM/YYYY');
  };

  const transformedValue = useMemo(() => {
    return handleTransformValue(value, type);
  }, [value, type]);

  const formatValue = (value) => {
    let _value = moment(value);

    if (type === 'year') {
      return _value.format('YYYY');
    }

    return _value.format('MM/YYYY');
  };

  const handlePrev = () => {
    if (onPrev) return onPrev();

    const newValue =
      type === 'year'
        ? moment(value).subtract(1, 'year').format('YYYY-MM-DD')
        : moment(value).subtract(1, 'month').format('YYYY-MM-DD');

    setValue(newValue);
    if (onChange) {
      onChange(newValue, formatValue(newValue));
    }
  };

  const handleNext = () => {
    if (onNext) return onNext();

    const newValue =
      type === 'year'
        ? moment(value).add(1, 'year').format('YYYY-MM-DD')
        : moment(value).add(1, 'month').format('YYYY-MM-DD');
    setValue(newValue);
    if (onChange) {
      onChange(newValue, formatValue(newValue));
    }
  };

  return (
    <S.Wrapper className={className}>
      <S.Content>
        <S.Button className="h-hide-print" onClick={handlePrev}>
          <MdChevronLeft />
        </S.Button>
        <S.Text>
          {isFetching ? (
            <TextLoading width={50} height={18} />
          ) : (
            transformedValue
          )}
        </S.Text>
        <S.Button className="h-hide-print" onClick={handleNext}>
          <MdChevronRight />
        </S.Button>
      </S.Content>
    </S.Wrapper>
  );
}

DateSelector.propTypes = {
  initialValue: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['year', 'month']),
  onChange: PropTypes.func,
};

export { DateSelector };
